import React, { forwardRef } from 'react';
import MaterialTable, { Icons } from 'material-table';
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
} from '@material-ui/icons';

import { TableProps } from './interfaces';
import { localization } from './constants';
import './Table.css';

const defaultTableOptions = {
    sorting: true,
    pageSize: 5,
    pageSizeOptions: [5, 10, 15],
    headerStyle: { fontWeight: 'bold', zIndex: 1 } as React.CSSProperties,
};

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref as any} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref as any} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref as any} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref as any} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref as any} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref as any} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref as any} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref as any} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref as any} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref as any} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref as any} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref as any} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref as any} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref as any} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref as any} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref as any} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref as any} />),
};

export function Table<T extends object>(props: TableProps<T>) {
    const {
        columns,
        title,
        data,
        fetchData,
        options,
        onSelectionChange,
        detailPanels,
        actions,
        onRowClick,
        ...functions
    } = props;
    const editable = { ...functions };

    return (
        <MaterialTable
            icons={tableIcons}
            title={title}
            data={data ? data : (query) => fetchData?.(query) ?? ([] as any)}
            columns={columns}
            options={{ ...defaultTableOptions, ...options }}
            editable={editable}
            localization={localization}
            onRowClick={onRowClick ?? undefined}
            detailPanel={detailPanels}
            actions={actions}
            onSelectionChange={onSelectionChange}
        />
    );
}
