import { useEffect, useState } from 'react';
import { ApiDescription, DATA_TYPE, HttpResponse, PARAM_LOCATION, STATUS, StringObject } from './interfaces';
import {
    createQueryUrl,
    extractParameters,
    interpolate,
    addErrorDetails,
    getFileFromBlob,
    documentContentTypes,
} from './utils';

type Function = (...args: any) => any;

export async function asyncFetch(
    apiDescription: ApiDescription,
    params: any,
    setStatus?: Function,
    setData?: Function,
    setMessage?: Function
): Promise<{ status: STATUS; data: object; error: string }> {
    const url = apiDescription.url;

    const pathParams = extractParameters(apiDescription, params, PARAM_LOCATION.PATH);
    const queryParams = extractParameters(apiDescription, params, PARAM_LOCATION.QUERY);
    const headerParams = extractParameters(apiDescription, params, PARAM_LOCATION.HEADER);
    const bodyParams = {} as any;

    if (apiDescription?.requestBody) {
        const { content } = apiDescription.requestBody;

        if (DATA_TYPE.JSON in content) {
            headerParams['Content-Type'] = DATA_TYPE.JSON;
            bodyParams['body'] = JSON.stringify(params.body || {});
        } else if (DATA_TYPE.FORM_DATA in content) {
            const formData = new FormData();
            const files = params.body.files as { [key: string]: any };
            Object.keys(files).forEach((name) => {
                formData.append(name, files[name], files[name].name);
            });
            bodyParams['body'] = formData;
        }
    }

    const inputUrl = interpolate(url, pathParams) + createQueryUrl(queryParams);

    let res = await fetch(inputUrl, {
        method: apiDescription.method.toUpperCase(),
        headers: headerParams,
        ...bodyParams,
    });

    const code = res.status;
    let data = {};
    let message: string;
    let status = STATUS.SUCCESS;
    let dataInitialised = false;

    if (code in apiDescription.responses) {
        const responseDesc = apiDescription.responses[code] as HttpResponse;
        const contentType = res.headers.get('content-type');
        const contentDisposition = res.headers.get('content-disposition');

        if (contentType === DATA_TYPE.JSON) {
            res = await res.json();
        } else if (contentType && documentContentTypes.indexOf(contentType) !== -1) {
            const blob = await res.blob();
            data = getFileFromBlob(blob, contentDisposition);
            dataInitialised = true;
        }

        message = responseDesc.message;

        if (responseDesc.success) {
            if (!dataInitialised) data = res;
        } else {
            status = STATUS.FAILED;
            if (contentType === DATA_TYPE.JSON) message = addErrorDetails(responseDesc.message, res);
        }
    } else {
        console.error(`An unexpected error occured: Status code ${code} not handled`);
        message = 'Une erreur est survenue';
        status = STATUS.FAILED;
    }

    setData && setData(data);
    setMessage && setMessage(message);
    setStatus && setStatus(status);

    return { status, data, error: message };
}

const useFetch = (apiDescription: ApiDescription): [STATUS, object, string, any] => {
    const [status, setStatus] = useState(STATUS.INIT);
    const [data, setData] = useState({});
    const [message, setMessage] = useState('');
    const [params, setParams] = useState({} as StringObject);

    useEffect(() => {
        if (status === STATUS.PENDING) {
            asyncFetch(apiDescription, params, setStatus, setData, setMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const fetchData = (newParams: object) => {
        Object.entries(apiDescription.parameters).forEach(([paramName, paramdescription]) => {
            if (paramdescription.required && !(paramName in newParams)) {
                console.error(
                    `Malformed http request!\nMissing required parameter '${paramName}' in ${paramdescription.in}`
                );
                return;
            }
        });

        setParams(newParams as StringObject);
        setStatus(STATUS.PENDING);
    };

    return [status, data, message, fetchData];
};

export default useFetch;
