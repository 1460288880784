import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const companyListDesc = {
    url: '/api/company/',
    method: HTTP_METHODS.GET,
    parameters: {
        search: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
        ordering: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Entreprises recuperées',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;
