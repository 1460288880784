import { Participant } from "../../../Models/Participant";
import { Exercice } from "../../../Models/Exercice";
import { Observateur } from "../../../Models/Observateur";

export interface UserExerciceSelectorProps {
    selectorType: SelectorType;
    title: string;
    handleSelectionChange: (selected: (Observateur | Participant | Exercice)[]) => void;
}

export enum SelectorType {
    PARTICIPANT,
    OBSERVATEUR,
    EXERCICE,
}