import React from 'react';
import { Column } from 'material-table';
import { Row } from './interfaces';
import { FormControl, Select, Input, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { Company } from '../../../Models/Company';


export const columns: Column<Row>[] = [
    { field: 'first_name', title: 'Prénom' },
    { field: 'last_name', title: 'Nom' },
    { field: 'email', title: 'Adresse mail' },
    { field: 'role', title: 'Role', lookup: { 1: 'Super-Administrateur', 2: 'Administrateur', 3: 'Observateur', 4: 'Participant' }, sorting: false, editable: "never" },
    {
        field: 'companies', title: 'Entreprises',
        render: (row) => (
            <p>{row.companies.map((c) => c.name).join(', ')}</p>
        ),
        editComponent: (props) => (
            <CompanyCell row={props.rowData} onChange={props.onChange} />
        )
    }
];

const CompanyCell = (
    props: {
        row: Row,
        onChange: (modifiedCompanies: Company[]) => void
    }
) => {
    const [selectedCompanies, setCompanies] = React.useState<number[]>([
        ...props.row.companies?.map(c => c.id)??[]
    ]);
    const companyMap = new Map<number, Company>(
        props.row.userCompanies?.map(c => ([c.id, c]))??[]
    );
    const fullCompanyMap = new Map<number, Company>(
        [...props.row.userCompanies??[], ...props.row.companies??[]].map(c => ([c.id, c]))
    );

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newCompanyIds = event.target.value as number[]
        setCompanies(newCompanyIds);
        const newCompanies = newCompanyIds
            .map(id => (fullCompanyMap.get(id)))
            .filter(c => c)
        props.onChange(newCompanies as Company[]);
    };

    return (
        <FormControl>
            <Select
                labelId={props.row.id?.toString()}
                id={props.row.id?.toString()}
                multiple
                value={selectedCompanies}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => makeCompanyString(selected as number[], companyMap)}
            >
                {makeMenuOptions({ companyMap: companyMap, selectedCompanies })}
            </Select>
        </FormControl>
    )
}

const makeCompanyString = (selected: number[], companyMap: Map<number, Company>) => {
    return selected.map(id => companyMap.get(id)?.name)
        .filter(name => name)
        .join(', ');
}

const makeMenuOptions = (props: { companyMap: Map<number, Company>, selectedCompanies: number[] }) => {
    const menuOptions: React.ReactNode[] = [];
    props.companyMap.forEach(c => {
        menuOptions.push(
            <MenuItem key={c.id} value={c.id}>
                <Checkbox checked={props.selectedCompanies.indexOf(c.id) > -1} />
                <ListItemText primary={c.name} />
            </MenuItem>
        )
    });
    return menuOptions;
}

export const getUserRoleNumber = (user: {
    is_superuser: boolean, is_admin: boolean, is_observateur: boolean, is_participant: boolean
}): number => {
    let role = 0;
    if (user.is_superuser) role = 1;
    else if (user.is_admin) role = 2;
    else if (user.is_observateur) role = 3;
    else if (user.is_participant) role = 4;
    return role;
}