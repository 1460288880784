export interface ReceivedData {
    id: number;
    name: string;
    description: string;
    full_description: string;
    score: number;
    standard_sentences?: any[];
    questions?: any[];
    multipleValues?: boolean;
    evaluatedItems_competences: any[];
    evaluatedItems_comportements: any[];
    evaluatedItems_situations: any[];
    personalities: any[];
}

export interface Row extends ReceivedData {
    editable: boolean;
    parent?: number;
    ss_id?: number;
    update: (value: number) => void;
}

export enum EXERCICE_TYPE {
    IN_BASKET = 'In Basket',
    EGOGRAMME = 'egogramme',
    DEFAULT = 'default',
    AUTO_EVAL = 'auto-evaluation',
}
