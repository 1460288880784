import React from 'react';
import { Table } from '../../../Table/Table';
import { Row, Col } from 'react-bootstrap';
import SeanceStartTime from '../SeanceStartTime';
import { SeanceExercice } from '../../../../Models/SeanceExercice';
import SeanceReport from '../../SeanceReport/SeanceReport';
import { canAccessReport } from '../../SeanceReport/functions';
import * as interfaces from './interfaces';
import * as functions from './functions';
import './SeanceExerciceView.css';


const SeanceExerciceView = (props: interfaces.SeanceExerciceViewProps) => {
    return (
        <>
            {
                canAccessReport(props.currentUser, props.seance) ?
                    <Row><Col>
                        <SeanceReport
                            seance={props.seance} currentUser={props.currentUser}
                        />
                    </Col></Row>
                    :
                    null
            }
            <Row>
                <Col>
                    <p>{'Début de la séance : '}</p>
                </Col>
                <Col>
                    <SeanceStartTime
                        isEditable={props.isEditable} seance={props.seance}
                        updateSeance={props.updateSeance} show={true}
                    />
                </Col>
            </Row>
            <Row><Col id="seance-exercice-container">
                <Table<SeanceExercice>
                    columns={
                        functions.getSeanceExoColumns(
                            props.seance, props.currentUser,
                            props.isParticipating, props.isEditable, props.evaluate,
                            props.updateSeance
                        )
                    }
                    title={""}
                    data={props.seance.seance_exercices}
                    options={{
                        search: false, sorting: true, paging: false,
                        showTitle: false, toolbar: false
                    }}
                    detailPanels={functions.getSeanceExoDetailPanels(props.seance)}
                />
            </Col></Row>
        </>
    );
};
export default SeanceExerciceView;
