import { Authentification } from '../../Redux/interfaces';

const AUTH_LOCATION = 'auth';

export function persistAuthData(auth: Authentification) {
    sessionStorage.setItem(AUTH_LOCATION, JSON.stringify(auth));
}

export function getAuthFromStorage(): Authentification | undefined {
    const auth = sessionStorage.getItem(AUTH_LOCATION);
    if (auth) return JSON.parse(auth);
}

export function clearAuthData() {
    sessionStorage.removeItem(AUTH_LOCATION);
}
