import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const getExerciceDesc: ApiDescription = {
    url: '/api/exercice/${id}',
    method: HTTP_METHODS.GET,
    parameters: {
        id: {
            in: PARAM_LOCATION.PATH,
            type: "number",
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    responses: {
        200: {
            success: true,
            message: 'Exercice fetched',
            content: DATA_TYPE.JSON,
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Exercice non trouvé',
        },
    },
};

export const patchExerciceDesc: ApiDescription = {
    url: '/api/exercice/${id}',
    method: HTTP_METHODS.PATCH,
    parameters: {
        id: {
            in: PARAM_LOCATION.PATH,
            type: "number",
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Exercice modifié',
        },
        400: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Mauvaise requête',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Exercice non trouvé',
        },
    },
};

export const deleteExerciceDesc: ApiDescription = {
    url: '/api/exercice/${id}',
    method: HTTP_METHODS.DELETE,
    parameters: {
        id: {
            in: PARAM_LOCATION.PATH,
            type: "number",
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    responses: {
        204: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Exercice supprimé',
        },
        400: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Mauvaise requête',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Exercice non trouvé',
        },
    },
};
