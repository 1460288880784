import { Seance } from '../../../Models/Seance';
import { EvaluationInterface } from '../../../Models/Evaluation';
import { Participant } from '../../../Models/Participant';
import { SeanceObservateur } from '../../../Models/SeanceObservateur';

export interface SeancePlanningProps {
    seance: Seance;
    editable?: boolean;
    updateEval: (evaluation: ExtendedEvaluationInterface) => void;
}

export interface EvaluationBoxProps {
    editable?: boolean;
    observateurs: SeanceObservateur[];
    evaluation?: ExtendedEvaluationInterface;
    closeBox: () => any;
    updateEval: (evaluation: ExtendedEvaluationInterface) => void;
    // onPositionChange: (position: string, evalId: number | string, userId: string | number) => any;
}

export interface DropdownAreaProps {
    className?: string;
    disabled?: boolean;
    items: { id: string | number; name: string }[];
    selected: string;
    onItemSelected: (id: string | number) => any;
}

export interface ExerciceColors {
    [exerciceName: string]: string;
}

export interface ExtendedEvaluationInterface extends EvaluationInterface {
    participant: Participant;
}

export interface InterfaceWithId<T> {
    [id: number]: T;
    [id: string]: T;
}

export interface EvaluationsById extends InterfaceWithId<ExtendedEvaluationInterface> {}

export enum Position {
    ASSESOR = 'assessor',
    ASSISTANT = 'assistant',
}

export enum EvaluationTime {
    STARTTIME = 'starttime',
    ENDTIME = 'endtime',
}
