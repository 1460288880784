// the number arrays need to be changed to the correct format

export interface ExerciceInterface {
    id: number;
    name: string;
    description: string;
    latest: boolean;
    company: number;
    is_autonomous: boolean;
    uses_assistant: boolean;
    uses_document: boolean;
    version: number;
    evaluatedItems_comportements?: number[];
    evaluatedItems_situations?: number[];
    personalities?: number[];
    preparation_time: number;
    action_time: number;
}

export class Exercice implements ExerciceInterface {
    id: number;
    name: string;
    description: string;
    latest: boolean;
    company: number;
    is_autonomous: boolean;
    uses_assistant: boolean;
    uses_document: boolean;
    version: number;
    evaluatedItems_comportements?: number[];
    evaluatedItems_situations?: number[];
    personalities?: number[];
    preparation_time: number;
    action_time: number;

    constructor(exercice: ExerciceInterface) {
        this.id = exercice.id;
        this.name = exercice.name[0].toUpperCase() + exercice.name.slice(1);
        this.description = exercice.description;
        this.latest = exercice.latest;
        this.company = exercice.company;
        this.is_autonomous = exercice.is_autonomous;
        this.uses_assistant = exercice.uses_assistant;
        this.uses_document = exercice.uses_document;
        this.version = exercice.version;
        this.evaluatedItems_comportements = exercice.evaluatedItems_comportements;
        this.evaluatedItems_situations = exercice.evaluatedItems_situations;
        this.personalities = exercice.personalities;
        this.preparation_time = exercice.preparation_time;
        this.action_time = exercice.action_time;
    }

    toString() {
        return this.name;
    }

    equals(exercice: ExerciceInterface) {
        return exercice.id === this.id;
    }

    toUpdate() {
        return {
            name: this.name,
            description: this.description,
            is_autonomous: this.is_autonomous,
            uses_assistant: this.uses_assistant,
            preparation_time: this.preparation_time,
            action_time: this.action_time,
        }
    }

    setDescription(description: string) {
        const exercice = new Exercice(this);
        exercice.description = description;
        return exercice;
    }

    static fromJSON(data: ExerciceInterface): Exercice {
        return new this({ ...data });
    }
}
