import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { TimelineProps, RessourceEvents } from './interface';
import { getAllPositions, sortEvents } from './functions';
import { DraggableTimeline } from './DraggableTimeline';

export const Timeline = (props: TimelineProps) => {
    const { events, resources, dayLength, dayLengthMin } = props;
    const { viewPortWidth, originTime, headerHeight, eventHeight, onEventClick } = props;
    const draggableProps = { viewPortWidth, originTime, headerHeight, eventHeight, onEventClick };
    const [reset, setReset] = useState(false);
    const [zoom, setZoom] = useState(1);

    const [totalHeight, setTotalHeight] = useState(50);
    const [resourcesEvents, setResourcesEvent] = useState<RessourceEvents[]>([]);

    useEffect(() => {
        const newResourcesEvents = resources.map((resource) => {
            const resourceEvents = sortEvents(events.filter((evt) => resource.id === evt.resource));
            const positions = getAllPositions(resourceEvents);

            return { height: Math.max(...positions, 0), resourceEvents, positions, resource };
        });

        setResourcesEvent(
            newResourcesEvents.reduce((acc, curResEvent, i) => {
                const prvResEvt = acc[i - 1];
                const yPosition = prvResEvt ? prvResEvt.yPosition + (prvResEvt.height + 1) * eventHeight : headerHeight;

                return [
                    ...acc,
                    {
                        ...curResEvent,
                        yPosition,
                    },
                ];
            }, [] as RessourceEvents[])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources, events]);

    useEffect(() => {
        const lastResEvents = resourcesEvents.slice(-1)[0];
        if (lastResEvents) setTotalHeight(lastResEvents.yPosition + (lastResEvents.height + 1) * eventHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourcesEvents]);

    const onReset = () => {
        setZoom(1);
        setReset(false);
    };

    const scaleTimeline = (plus: boolean) => {
        if (plus || (dayLengthMin ?? 0) < dayLength * zoom)
            setZoom((prevZoom) => (plus ? 1.1 * prevZoom : 0.9 * prevZoom));
    };

    return (
        <div className="Timeline">
            <div className="TimelineButtons">
                <div className="btn" onClick={() => setReset(true)}>
                    Début
                </div>
                <RemoveCircleOutlineIcon className="icon" onClick={() => scaleTimeline(false)} />
                <AddCircleOutlineIcon className="icon" onClick={() => scaleTimeline(true)} />
            </div>
            <svg viewBox={`-15 0 100 ${totalHeight}`} xmlns="http://www.w3.org/2000/svg">
                <DraggableTimeline
                    resourceEvents={resourcesEvents}
                    reset={reset}
                    onReset={onReset}
                    dayLength={Math.max(dayLength * zoom, dayLengthMin ?? 0)}
                    {...draggableProps}
                />
                <rect x="-15" y="0" width="15" height="100" fill="white" />
                <line x1="0" y1={headerHeight} x2="0" y2="100" strokeWidth=".15" stroke="black" />
                {resourcesEvents.map(({ yPosition: y, height, resource: { id, title } }, i) => {
                    const yText = y + ((height + 1) * eventHeight) / 2;
                    return (
                        <React.Fragment key={id}>
                            <line x1="-15" y1={y} x2={i ? '100%' : '0'} y2={y} strokeWidth=".15" stroke="black" />
                            <text x="-7.5" y={yText} fontSize={1.5}>
                                {title}
                            </text>
                        </React.Fragment>
                    );
                })}
            </svg>
        </div>
    );
};
