import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Tooltip, IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

import { SET_CURRENT_EVALUATION } from '../../../Redux/actionsTypes';
import { globalStore } from '../../../Redux/interfaces';
import SeanceParticipantView from './SeanceParticipantView/SeanceParticipantView';
import SeanceExerciceView from './SeanceExerciceView/SeanceExerciceView';
import { Evaluation, EvaluationInterface } from '../../../Models/Evaluation';
import { Exercice } from '../../../Models/Exercice';
import { Participant } from '../../../Models/Participant';
import { Observateur } from '../../../Models/Observateur';
import { SeanceParticipant } from '../../../Models/SeanceParticipant';
import { Seance, SeanceStatus } from '../../../Models/Seance';
import { UserRole } from '../../../Models/User';
import { useRepository } from '../../../Repository/useRepository';
import { seanceCreateDesc, seanceDesc, seanceDescPatch } from '../../../Repository/seanceDesc';
import { getInitialViewState } from './functions';
import { STATUS } from '../../Fetch/interfaces';
import UserExerciceSelector from '../UserExerciceSelector/UserExerciceSelector';
import { SelectorType } from '../UserExerciceSelector/interfaces';
import { Loader } from '../../../App';
import { SeancePlanning } from '../SeancePlanning/SeancePlanning';
import { ExtendedEvaluationInterface } from '../SeancePlanning/interface';
import * as interfaces from './interfaces';
import { SeanceCreationNavigation, VState } from './interfaces';
import * as functions from './functions';
import './SeanceView.css';
import { PageSkeleton } from '../../PageSkeleton/PageSkeleton';

const SeanceView = (props: interfaces.SeanceViewProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { seanceId } = useParams();
    const [seanceLoaded, setSeanceLoaded] = useState(false);
    const [creationStep, setCreationStep] = useState(0);
    const [seanceViewState, setSeanceViewState] = useState<VState>(getInitialViewState(props.participate, props.isNew));
    const user = useSelector((state: globalStore) => state.authentification);
    const [seanceEditionState, setSeanceEditionState] = useState(
        functions.initSeanceEditionState(props.participate, props.isNew)
    );
    const [seanceState, setSeanceState] = useState(functions.initSeanceState());
    const [, , , submitSeance] = useRepository(seanceCreateDesc, () => {
        history.push('/main/seance');
    });
    const [updatedSeanceStatus, updatedSeanceData, , updateSeance] = useRepository(seanceDescPatch, () => {
        if (updatedSeanceStatus === STATUS.SUCCESS && updatedSeanceData !== {}) {
            let seance = Seance.fromJSON(updatedSeanceData);
            setSeanceState({ ...seanceState, seance });
            if (seance.status === SeanceStatus.ONGOING) {
                //if new status is 'En cours' then move to 'participation' view
                setSeanceEditionState(functions.initSeanceEditionState(true, props.isNew));
                history.push(`/main/seance/${seance.id}/participate`);
            }
        }
    });
    const [status, seanceData, , fetchSeance] = useRepository(seanceDesc, () => {
        if (seanceData !== {}) {
            let seance = Seance.fromJSON(seanceData);
            if (user.current_role === UserRole.PARTICIPANT) {
                // If the user is a participant, remove other users' data from seance
                seance = seance.filterSeanceParticipantsForParticipant(user.id);
            }
            setSeanceState({ ...seanceState, seance });
            setSeanceLoaded(true);
        }
    });

    useEffect(() => {
        // Reinit seanceEditionState on url change
        setSeanceViewState(getInitialViewState(props.participate, props.isNew));
        refreshSeance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location]);

    useEffect(() => {
        // Fetch seance using seanceId at component load
        refreshSeance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, seanceId]);

    const refreshSeance = () => {
        if (user.logged_in && !props.isNew && seanceId) {
            fetchSeance({ seanceId: seanceId });
        } else {
            setSeanceState(functions.initSeanceState());
        }
    };

    const evaluate = (evaluation: EvaluationInterface, seance_participant?: SeanceParticipant): void => {
        if (!seance_participant) {
            seance_participant = seanceState.seance.getSeanceParticipantfromParticipantId(user.id) ?? undefined;
        }
        if (seance_participant) {
            dispatch({
                type: SET_CURRENT_EVALUATION,
                payload: { ...evaluation, seance_participant },
            });
            history.push(`/main/evaluation/${evaluation.id}/evaluate`);
        }
    };

    const choices: JSX.Element = (
        <>
            <Row>
                <div>Veuillez sélectionner au moins un participant, un observateur et un exercice</div>
            </Row>
            <Row>
                <UserExerciceSelector
                    selectorType={SelectorType.PARTICIPANT}
                    title={'Sélectionner participants'}
                    handleSelectionChange={(selected) => {
                        setSeanceState((previous) => ({
                            seance: previous.seance.setParticipants(selected as Participant[]),
                        }));
                    }}
                />
                <UserExerciceSelector
                    selectorType={SelectorType.OBSERVATEUR}
                    title={'Sélectionner observateurs'}
                    handleSelectionChange={(selected) => {
                        setSeanceState((previous) => ({
                            seance: previous.seance.setObservateurs(selected as Observateur[]),
                        }));
                    }}
                />
            </Row>
            <Row>
                <UserExerciceSelector
                    selectorType={SelectorType.EXERCICE}
                    title={'Sélectionner exercices'}
                    handleSelectionChange={(selected) => {
                        setSeanceState((previous) => ({
                            seance: previous.seance.setExercices(selected as Exercice[]),
                        }));
                    }}
                />
            </Row>
        </>
    );

    const startTerminateBtn = (): JSX.Element | null => {
        const currentStatus = seanceState.seance.status;
        const handleClick = () => {
            updateSeance({
                seanceId: seanceState.seance.id,
                body: { status: currentStatus + 1 },
            });
        };
        if (
            (seanceEditionState.viewState === interfaces.ViewState.PARTICIPATE ||
                seanceEditionState.viewState === interfaces.ViewState.VIEWING) &&
            (currentStatus === SeanceStatus.READY ||
                (currentStatus === SeanceStatus.ONGOING && seanceState.seance.is_complete))
        ) {
            return (
                <Button onClick={handleClick}>{currentStatus === SeanceStatus.READY ? 'Lancer' : 'Clôturer'}</Button>
            );
        }
        return null;
    };

    const renderSeanceParticipantView = (isEditable: boolean, isParticipating: boolean): JSX.Element | null => {
        const seanceParticipantViewProps = {
            isEditable,
            isParticipating,
            seance: seanceState.seance,
            currentUser: user,
            evaluate,
            updateSeance: (seance: Seance) => {
                setSeanceState({ ...seance, seance: seance });
            },
        };
        return <SeanceParticipantView {...seanceParticipantViewProps} />;
    };

    const renderSeanceExerciceView = (isEditable: boolean, isParticipating: boolean): JSX.Element => {
        const seanceExerciceViewProps = {
            isEditable,
            isParticipating,
            seance: seanceState.seance,
            currentUser: user,
            evaluate,
            updateSeance: (seance: Seance) => {
                setSeanceState({ ...seance, seance: seance });
            },
        };
        return <SeanceExerciceView {...seanceExerciceViewProps} />;
    };

    const buttonBar = (): JSX.Element => {
        const handleProgress = (forward: boolean) => {
            if (seanceViewState === VState.CHOICE) {
                setSeanceState({
                    ...seanceState,
                    seance: seanceState.seance.generateInitialPlan(),
                });
            }
            if (forward && creationStep === SeanceCreationNavigation.length - 1) {
                submitSeance({
                    body: seanceState.seance.toCreate(),
                });
            } else {
                const newStep = forward ? creationStep + 1 : creationStep - 1;
                setCreationStep(newStep);
                setSeanceViewState(SeanceCreationNavigation[newStep]);
            }
        };

        const { participants, observateurs, exercices } = seanceState.seance;
        const isEverythingSelected = props.isNew && participants.length && observateurs.length && exercices.length;

        return (
            <div className="ButtonContainer">
                {props.isNew && creationStep !== 0 && <Button onClick={() => handleProgress(false)}>Précédent</Button>}
                {!!isEverythingSelected && (
                    <Button onClick={() => handleProgress(true)}>
                        {creationStep === SeanceCreationNavigation.length - 1 ? 'Valider' : 'Suivant'}
                    </Button>
                )}
            </div>
        );
    };

    const onUpdateEval = (evaluation: ExtendedEvaluationInterface) => {
        const updatedSeance = seanceState.seance.updateParticipantEvaluation(
            new Evaluation(evaluation),
            evaluation.participant
        );
        if (updatedSeance) {
            setSeanceState((previous) => ({ ...previous, seance: updatedSeance }));
        }
    };

    const viewBody = (): JSX.Element | null => {
        switch (seanceViewState) {
            case VState.CHOICE:
                return choices;
            case VState.PLANNING_PAR:
                return renderSeanceParticipantView(true, false);
            case VState.PLANNING_EXO:
                return renderSeanceExerciceView(true, false);
            case VState.PLANNNING_SUMMARY:
                return <SeancePlanning seance={seanceState.seance} editable={true} updateEval={onUpdateEval} />;
            case VState.VIEWING:
                return (
                    <>
                        {renderSeanceParticipantView(false, false)}
                        {renderSeanceExerciceView(false, false)}
                        <SeancePlanning seance={seanceState.seance} updateEval={onUpdateEval} />
                    </>
                );
            case VState.PARTICIPATE:
                return (
                    <>
                        {renderSeanceParticipantView(false, true)}
                        {renderSeanceExerciceView(false, true)}
                        <SeancePlanning seance={seanceState.seance} updateEval={onUpdateEval} />
                    </>
                );
            default:
                return null;
        }
    };

    if (status === STATUS.FAILED) history.goBack();
    if (!seanceLoaded && !props.isNew) return <Loader />;

    const showConfirmElement = props.isNew && (creationStep > 0 || seanceState.seance.isNotDefault());

    return (
        <PageSkeleton
            confirmBeforeRedirect={showConfirmElement}
            confirmMessage="Les informations sur la séance seront perdues"
        >
            <Container id="SeanceView">
                <Row className="items-are-center-aligned content-is-center-justified">
                    <Col className="d-flex content-is-flex-end-justified">
                        {user.is_observateur && startTerminateBtn()}
                        {seanceState.seance.status !== SeanceStatus.CREATION && (
                            <Tooltip title="Rafraichir la séance">
                                <IconButton
                                    aria-label="rafraichir"
                                    onClick={() => {
                                        fetchSeance({ seanceId: seanceState.seance.id });
                                    }}
                                >
                                    <Refresh color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{props.isNew ? 'Nouvelle séance' : `Séance n° ${seanceState.seance.id}`}</h3>
                    </Col>
                </Row>

                {viewBody()}
                {buttonBar()}
            </Container>
        </PageSkeleton>
    );
};

export default SeanceView;
