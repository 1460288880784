import React, { useState } from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import './DataConsent.css';

const DataConsent = () => {
    const [accepted, setAccepted] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails((previous) => !previous);
    };

    const accept = () => {
        setAccepted(true);
    };

    return (
        <React.Fragment>
            {accepted ? null : (
                <Container id="dataConsent">
                    <div>
                        Cette application nécessite le stockage et le traitement de vos données pour fonctionner.
                        <a className="dataPolicyLink" onClick={toggleDetails}>
                            Politique des données.
                        </a>
                    </div>
                    <Button variant="success" onClick={accept}>
                        Accepter
                    </Button>
                </Container>
            )}
            <Modal id="dataPolicyModal" show={showDetails} onHide={toggleDetails}>
                <Modal.Header closeButton>
                    <Modal.Title>Politique des données</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Comment puis-je gérer ou supprimer mes données ?</h3>
                    <p>
                        Vous pouvez gérer vos informations personnelles en accédant à la page «Profil» de l'application.
                        Vous avez la possibilité de modifier / supprimer toutes les informations.
                        <br />
                        Cependant, il est obligatoire que votre prénom, nom et adresse e-mail soient enregistrés sur
                        l'application pour que vous puissiez l'utiliser.
                        <br />
                        Si vous souhaitez supprimer ces informations (et donc votre compte), merci de contacter
                        l'administrateur de l'application par email :
                        <a href = "mailto: assetyourselfonline@gmail.com"> assetyourselfonline@gmail.com</a>
                    </p>
                    <h3>Comment mes données sont-elles utilisées ?</h3>
                    <p>
                        Votre adresse e-mail et votre nom sont utilisés pour vous identifier auprès de l'application.
                        <br />
                        De plus, votre nom est utilisé pour générer vos rapports après une session. Notez que ces
                        rapports ne sont accessibles qu'à vous-même et à l'observateur désigné pour compléter le
                        rapport.
                        <br />
                        Des informations facultatives supplémentaires, notamment le sexe, l'emploi, la catégorie d'age
                        et la catégorie socio-professionnelle, pourraient être utilisées pour calculer des statistiques
                        anonymisées. Si vous ne souhaitez pas participer à ces statistiques, merci de contacter
                        l'administrateur de l'application.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={toggleDetails}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default DataConsent;
