import React, { useState, useEffect } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { STATUS } from '../../Fetch/interfaces';
import { useRepository } from '../../../Repository/useRepository';
import { uploadDesc } from '../../../Repository/referentialDesc';
import { uploadFilesDesc } from './constants';
import { UploadFileType } from './functions';
import './ReferentialUpload.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';

interface uploadStatusDesc {
    [key: string]: {
        status: string;
        percentage: number;
    };
}

const ReferentialUpload = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = window.location.host;
    const url = `${protocol}//${host}/ws/upload_status/`;
    const [files, setFiles] = useState<{ [key: string]: any }>({});
    const [overwrite, setOverwrite] = useState(false);
    const [askOverwriteConfirmation, setOverwriteConfirmation] = useState(false);
    const [uploadInfo, setUploadInfo] = useState({} as uploadStatusDesc);
    const [websocket, setWebsocket] = useState(null as WebSocket | null);
    const [status, , , uploadFiles] = useRepository(uploadDesc, () => completeProgress(), {
        notify: true,
        notifyOnSuccess: true,
    });

    useEffect(() => {
        if (!websocket || websocket.readyState === 3) {
            const newWebSocket = new WebSocket(url);
            newWebSocket.onmessage = (event) => {
                const parsedData = JSON.parse(event.data);
                setUploadInfo((prevStatus) => {
                    return { ...prevStatus, ...parsedData };
                });
            };
            setWebsocket(newWebSocket);
        }

        return () => websocket?.close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const sendDocuments = () => {
        uploadFiles({
            override: overwrite,
            body: {
                files: files,
            },
        });
    };

    const handleFileChange = (event: any, name: string) => {
        const files = event.target.files;
        if (files.length > 0)
            setFiles((prevFiles) => {
                return {
                    ...prevFiles,
                    [name]: files[0],
                };
            });
    };

    const removeFile = (name: string) => {
        setFiles((prevFiles) => {
            const newFiles = Object.assign({}, prevFiles);
            delete newFiles[name];
            return newFiles;
        });
    };

    const completeProgress = () => {
        const completedState: uploadStatusDesc = {};
        for (const key in UploadFileType) {
            if (uploadInfo[UploadFileType[key]]?.status === STATUS.PENDING) {
                completedState[UploadFileType[key]] = { percentage: 100, status: STATUS.SUCCESS };
            }
        }
        setUploadInfo((prevStatus) => ({ ...prevStatus, ...completedState }));
    };

    const renderUploadControls = uploadFilesDesc.map(({ name, description, info }) => {
        const percentage = uploadInfo[name]?.percentage ?? 0;
        const status = uploadInfo[name]?.status;
        const showProgress = name in uploadInfo && uploadInfo[name]?.status !== STATUS.INIT;
        const variant = ({
            [STATUS.SUCCESS]: 'success',
            [STATUS.FAILED]: 'danger',
        } as any)[status];

        const fileName = files[name]?.name || '';

        return (
            <React.Fragment key={name}>
                <div className="help-area">
                    <HelpOutlineIcon />
                    <div className="info">{info}</div>
                </div>
                <div className="UploadControlDesc">{description}</div>
                <input id={`input_${name}`} type="file" onChange={(event) => handleFileChange(event, name)} />
                <Button variant="outline-primary" className="FileInputButton">
                    <label htmlFor={`input_${name}`}>Choisir</label>
                </Button>
                <span className="FileName">
                    {fileName && (
                        <>
                            {fileName}
                            <ClearIcon className="ClearIcon" onClick={() => removeFile(name)} />
                        </>
                    )}
                </span>
                {showProgress ? <ProgressBar now={percentage} label={`${percentage}%`} variant={variant} /> : <div />}
            </React.Fragment>
        );
    });

    const CheckboxArea = () => {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#005EB8',
                    light: '#007bff',
                },
            },
        });

        const onOverrideChange = () => {
            if (!overwrite) setOverwriteConfirmation(true);
            else setOverwrite(false);
        };

        return (
            <div className="UploadOvewrite">
                <ThemeProvider theme={theme}>
                    <FormControlLabel
                        control={<Checkbox checked={overwrite} onChange={onOverrideChange} color="primary" />}
                        label="Ecraser les données existantes"
                    />
                </ThemeProvider>
            </div>
        );
    };

    const onConfirmationSelected = (confirmation: boolean) => {
        setOverwrite(confirmation);
        setOverwriteConfirmation(false);
    };

    const isPending = status === STATUS.PENDING;

    return (
        <Container className="ReferentialUploadContainer">
            <Form>
                <h3 className="UploadTitle">Charger un fichier</h3>
                <div className="UploadInfo">Il vous est possible de ne pas uploader tous les fichiers</div>
                <div className="UploadControlsContainer">{renderUploadControls}</div>

                {/*{renderUploadingSpinner()}*/}
                <CheckboxArea />

                {!!Object.keys(files).length && (
                    <Button className="UploadButton" variant="primary" onClick={sendDocuments} disabled={isPending}>
                        {isPending ? 'Envoi...' : 'Envoyer'}
                    </Button>
                )}
            </Form>
            <Modal show={askOverwriteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Etes-vous sûr de vouloir supprimer les données existantes ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Cela supprimera tous les exercices ainsi que toutes les séances</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onConfirmationSelected(false)}>
                        Annuler
                    </Button>
                    <Button onClick={() => onConfirmationSelected(true)}>Confirmer</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ReferentialUpload;
