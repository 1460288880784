import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import { Seance } from '../../../Models/Seance';


interface SeanceStartTimeProps {
    show?: boolean;
    seance: Seance;
    isEditable?: boolean;
    updateSeance?: (seance: Seance) => void;
}

const SeanceStartTime = ({
    show = true, seance, isEditable = true, updateSeance
}: SeanceStartTimeProps) => {
    if (show) {
        return (
            <DateTimePicker
                ampm={false}
                disabled={!isEditable}
                value={seance.start_time}
                onChange={(date) => {
                    if (updateSeance) updateSeance(seance.setStartTime(date as Moment));
                }}
            />
        )
    }
    return null;
};

export default SeanceStartTime;