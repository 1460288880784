import { SeanceParticipant, SeanceParticipantInterface } from './SeanceParticipant';
import { SeanceObservateur, SeanceObservateurInterface } from './SeanceObservateur';
import { SeanceExercice, SeanceExerciceInterface } from './SeanceExercice';
import { Observateur, ObservateurInterface } from './Observateur';
import { Exercice, ExerciceInterface } from './Exercice';
import { Participant, ParticipantInterface } from './Participant';
import { Evaluation } from './Evaluation';
import moment, { Moment } from 'moment';

export enum SeanceStatus {
    CREATION = -1,
    READY,
    ONGOING,
    FINISHED,
}

export interface SeanceInterface {
    id: number;
    creator?: Observateur;
    seance_exercices: SeanceExercice[];
    seance_observateurs: SeanceObservateur[];
    seance_participants: SeanceParticipant[];
    exercices?: Exercice[];
    observateurs?: Observateur[];
    participants?: Participant[];
    company: number;
    status: SeanceStatus;
    start_time: Moment;
    created: string;
}

export class Seance implements SeanceInterface {
    id: number;
    creator?: Observateur;
    seance_exercices: SeanceExercice[];
    seance_observateurs: SeanceObservateur[];
    seance_participants: SeanceParticipant[];
    exercices: Exercice[];
    observateurs: Observateur[];
    participants: Participant[];
    company: number;
    status: SeanceStatus;
    start_time: Moment;
    created: string;
    is_complete: boolean;

    constructor(seance: SeanceInterface) {
        this.id = seance.id;
        this.creator = seance.creator;
        this.seance_exercices = seance.seance_exercices;
        this.seance_observateurs = seance.seance_observateurs;
        this.seance_participants = seance.seance_participants;
        this.exercices = seance.exercices ?? [];
        this.observateurs = seance.observateurs ?? [];
        this.participants = seance.participants ?? [];
        this.company = seance.company;
        this.status = seance.status;
        this.start_time = seance.start_time;
        this.created = seance.created;
        this.is_complete = Seance.countNbEvaluations(seance.seance_participants, true) === 0;
    }

    isNotDefault(): boolean {
        return this.exercices.length !== 0 || this.observateurs.length !== 0 || this.participants.length !== 0;
    }

    static makeBlankSeance(): Seance {
        return new this({
            id: 1,
            seance_exercices: [],
            seance_observateurs: [],
            seance_participants: [],
            company: 0,
            status: SeanceStatus.CREATION,
            start_time: moment(),
            created: new Date().toDateString(),
        });
    }

    static fromJSON(data: any): Seance {
        const seance_exercices = data.seance_exercices.map((element: SeanceExerciceInterface) =>
            SeanceExercice.fromJSON(element)
        );
        const seance_observateurs = data.seance_observateurs.map((element: SeanceObservateurInterface) =>
            SeanceObservateur.fromJSON(element)
        );
        const seance_participants = data.seance_participants.map((element: SeanceParticipantInterface) =>
            SeanceParticipant.fromJSON(element)
        );
        const creator = data.creator ? new Observateur(data.creator) : undefined;
        const start_time = seance_exercices.legnth ? seance_exercices[0].start_time : moment();
        return new this({ ...data, seance_exercices, seance_observateurs, seance_participants, creator, start_time });
    }

    static countNbEvaluations(seanceParticipants: SeanceParticipant[], editable: boolean): number {
        let count = 0;
        seanceParticipants.forEach((sp) => {
            sp.evaluations.forEach((evaluation) => {
                if (evaluation.editable === editable) ++count;
            });
        });
        return count;
    }

    equals(seance: SeanceInterface) {
        return seance.id === this.id;
    }

    /* Methods for Participants */

    findIndexParticipant(participant: ParticipantInterface): number {
        for (let index = 0; index < this.participants.length; index++) {
            if (this.participants[index].equals(participant)) return index;
        }
        return -1;
    }

    existsParticipant(participant: ParticipantInterface): boolean {
        return this.findIndexParticipant(participant) > -1;
    }

    addParticipant(participant: Participant) {
        if (!this.existsParticipant(participant)) {
            const seance = new Seance(this);
            seance.participants.push(participant);
            return seance;
        }
        return this;
    }

    removeParticipant(participant: Participant) {
        if (this.existsParticipant(participant)) {
            const seance = new Seance(this);
            seance.participants.splice(this.findIndexParticipant(participant), 1);
            return seance;
        }
        return this;
    }

    setParticipants(participants: Participant[]) {
        // const seance = new Seance(this);
        // seance.participants = [...participants];
        // return seance;
        this.participants = [...participants];
        return this;
    }

    /* End methods for Participants */

    /* Methods for Observateurs */

    findIndexObservateur(observateur: ObservateurInterface): number {
        for (let index = 0; index < this.observateurs.length; index++) {
            if (this.observateurs[index].equals(observateur)) return index;
        }
        return -1;
    }

    existsObservateur(observateur: ObservateurInterface): boolean {
        return this.findIndexObservateur(observateur) > -1;
    }

    addObservateur(observateur: Observateur) {
        if (!this.existsObservateur(observateur)) {
            const seance = new Seance(this);
            seance.observateurs.push(observateur);
            return seance;
        }
        return this;
    }

    removeObservateur(observateur: Observateur) {
        if (this.existsObservateur(observateur)) {
            const seance = new Seance(this);
            seance.observateurs.splice(this.findIndexObservateur(observateur), 1);
            return seance;
        }
        return this;
    }

    setObservateurs(observateurs: Observateur[]) {
        // const seance = new Seance(this);
        // seance.observateurs = [...observateurs];
        // return seance;
        this.observateurs = [...observateurs];
        return this;
    }

    /* End methods for Observateurs */

    /* Methods for Exercices */

    findIndexExercice(exercice: ExerciceInterface): number {
        for (let index = 0; index < this.exercices.length; index++) {
            if (this.exercices[index].equals(exercice)) return index;
        }
        return -1;
    }

    existsExercice(exercice: ExerciceInterface): boolean {
        return this.findIndexExercice(exercice) > -1;
    }

    addExercice(exercice: Exercice) {
        if (!this.existsExercice(exercice)) {
            const seance = new Seance(this);
            seance.exercices.push(exercice);
            return seance;
        }
        return this;
    }

    removeExercice(exercice: Exercice) {
        if (this.existsExercice(exercice)) {
            const seance = new Seance(this);
            seance.exercices.splice(this.findIndexExercice(exercice), 1);
            return seance;
        }
        return this;
    }

    setExercices(exercices: Exercice[]) {
        // const seance = new Seance(this);
        // seance.exercices = [...exercices];
        // return seance;
        this.exercices = [...exercices];
        return this;
    }

    moveAutonomousExercicesToEnd(): void {
        const autonomousExercices: Exercice[] = [];
        const nonAutonomousExercices: Exercice[] = [];
        this.exercices.forEach((exercice) => {
            if (exercice.is_autonomous) {
                autonomousExercices.push(exercice);
            } else {
                nonAutonomousExercices.push(exercice);
            }
        });
        this.setExercices([...nonAutonomousExercices, ...autonomousExercices]);
    }

    /* End methods for Exercices */

    /* Start methods for planning */

    generateInitialPlan() {
        const seance = new Seance(this);

        // Rearrange Exercices
        seance.moveAutonomousExercicesToEnd();

        // Make seance_observateurs
        seance.seance_observateurs = [];
        seance.observateurs.forEach((observateur) => {
            seance.seance_observateurs.push(new SeanceObservateur({ id: observateur.id, seance: 0, observateur }));
        });

        // Make seance_exercices
        seance.seance_exercices = [];
        const length = seance.seance_observateurs.length;
        seance.exercices.forEach((exercice, index) => {
            let observateur_incharge = undefined;
            if (!exercice.is_autonomous) observateur_incharge = seance.seance_observateurs[index % length];
            const seance_exercice = new SeanceExercice({
                id: exercice.id,
                seance: 0,
                exercice,
                observateur_incharge,
                available_report: false,
            });
            seance.seance_exercices.push(seance_exercice);
        });

        // Make seance_participants
        seance.seance_participants = [];
        const editable = true;
        let evaluationIndex = 0;
        let start_time = moment(seance.start_time);
        let end_time = moment(seance.start_time);
        seance.participants.forEach((participant, outerIndex) => {
            const observateur_principal = seance.seance_observateurs[outerIndex % length];
            const evaluations: Evaluation[] = [];
            start_time = moment(seance.start_time);
            end_time = moment(seance.start_time);
            // const filtered = seance.seance_exercices.filter((elem) => !!!elem.exercice.is_autonomous)
            seance.seance_exercices.forEach((seance_exercice, innerIndex) => {
                let assessor = undefined;
                let assistant = undefined;
                const rotationIndex = outerIndex + innerIndex;
                const length = seance.seance_observateurs.length;
                if (length && !seance_exercice.exercice.is_autonomous) {
                    assessor = seance.seance_observateurs[rotationIndex % length];
                    if (seance_exercice.exercice.uses_assistant)
                        assistant = seance.seance_observateurs[(rotationIndex + 1) % length];
                }
                end_time = moment(start_time);
                end_time.add(seance_exercice.exercice.preparation_time + seance_exercice.exercice.action_time, 'm');
                evaluations.push(
                    new Evaluation({
                        id: evaluationIndex++,
                        seance_exercice,
                        assessor,
                        assistant,
                        editable,
                        start_time,
                        end_time,
                        comments: '',
                    })
                );
                start_time = moment(end_time);
            });
            const seance_participant = new SeanceParticipant({
                id: outerIndex,
                seance: 0,
                participant,
                observateur_principal,
                evaluations,
            });
            seance.seance_participants.push(seance_participant);
        });
        return seance;
    }
    /* End of methods for planning */

    /* Methods for Seance Participants */

    findIndexSeanceParticipant(seanceParticipant: SeanceParticipantInterface): number {
        for (let index = 0; index < this.seance_participants.length; index++) {
            if (this.seance_participants[index].equals(seanceParticipant)) return index;
        }
        return -1;
    }

    existsSeanceParticipant(seanceParticipant: SeanceParticipantInterface): boolean {
        return this.findIndexSeanceParticipant(seanceParticipant) > -1;
    }

    updateSeanceParticipant(seanceParticipant: SeanceParticipant) {
        const seance = new Seance(this);
        if (this.existsSeanceParticipant(seanceParticipant)) {
            seance.seance_participants[this.findIndexSeanceParticipant(seanceParticipant)] = seanceParticipant;
        }
        return seance;
    }

    /* End methods for Seance Participants */

    /* Methods for Seance Exercices */

    findIndexSeanceExercice(seanceExercice: SeanceExerciceInterface): number {
        for (let index = 0; index < this.seance_exercices.length; index++) {
            if (this.seance_exercices[index].equals(seanceExercice)) return index;
        }
        return -1;
    }

    existsSeanceExercice(seanceExercice: SeanceExerciceInterface): boolean {
        return this.findIndexSeanceExercice(seanceExercice) > -1;
    }

    updateSeanceExercice(seanceExercice: SeanceExercice) {
        const seance = new Seance(this);
        if (this.existsSeanceExercice(seanceExercice)) {
            seance.seance_exercices[this.findIndexSeanceExercice(seanceExercice)] = seanceExercice;
        }
        return seance;
    }

    // Gets the common start and end times of a given exercice
    // Should only be used before editing the times per evaluation !!
    getSeanceExerciceCommonTimes(seanceExercice: SeanceExerciceInterface) {
        let times = { startTime: moment(), endTime: moment() };
        const seanceParticipant = this.seance_participants[0];
        if (!seanceParticipant) return times;
        const evaluation = seanceParticipant.evaluations.find((value) => value.seance_exercice.equals(seanceExercice));
        if (!evaluation) return times;
        times = { startTime: evaluation.start_time, endTime: evaluation.end_time };
        return times;
    }

    setSeanceExerciceCommonTimes(
        seanceExercice: SeanceExerciceInterface,
        startTime: Moment | null,
        endTime: Moment | null
    ) {
        const seance_participants_updated = [...this.seance_participants];
        this.seance_participants.forEach((sp, spIndex) => {
            let evaluation = sp.evaluations.find((value) => value.seance_exercice.equals(seanceExercice));
            if (evaluation) {
                if (endTime) {
                    evaluation = evaluation.setEndTime(endTime);
                }
                if (startTime) {
                    evaluation = evaluation.setStartTime(startTime);
                    const exo = evaluation.seance_exercice.exercice;
                    let newEndTime = moment(startTime);
                    newEndTime.add(exo.action_time + exo.preparation_time, 'minutes');
                    evaluation = evaluation.setEndTime(newEndTime);
                }
                seance_participants_updated[spIndex] = sp.updateEvaluation(evaluation);
            }
        });
        const seance = new Seance(this);
        seance.seance_participants = seance_participants_updated;
        return seance;
    }

    /* End methods for Seance Exercices */

    /* Start API methods */

    toCreate() {
        return {
            seance_observateurs: this.seance_observateurs.map((element) => element.toCreate()),
            seance_exercices: this.seance_exercices.map((element) => element.toCreate()),
            seance_participants: this.seance_participants.map((element) => element.toCreate()),
        };
    }

    /* End API methods */

    /* Other methods */

    setStartTime(start_time: Moment) {
        const seance = new Seance(this);
        const time_diff = moment.duration(start_time.diff(this.start_time));
        seance.start_time = start_time;
        // Update exercise times
        const updated_seance_participants: SeanceParticipant[] = [];
        this.seance_participants.forEach((sp) => {
            const updated_evaluations: Evaluation[] = [];
            sp.evaluations.forEach((ev) => {
                const newStart = moment(ev.start_time).add(time_diff);
                const newEnd = moment(ev.end_time).add(time_diff);
                updated_evaluations.push(ev.setTimes(newStart, newEnd));
            });
            updated_seance_participants.push(sp.setEvaluations(updated_evaluations));
        });
        seance.seance_participants = updated_seance_participants;
        return seance;
    }

    getSeanceParticipantfromParticipantId(id: number) {
        for (let index = 0; index < this.seance_participants.length; index++) {
            const seanceParticipant = this.seance_participants[index];
            if (seanceParticipant.participant.id === id) return seanceParticipant;
        }
        return null;
    }

    getSeanceObservateurfromObservateurId(id: number) {
        for (let index = 0; index < this.seance_observateurs.length; index++) {
            const seanceObservateur = this.seance_observateurs[index];
            if (seanceObservateur.observateur.id === id) return seanceObservateur;
        }
        return null;
    }

    filterSeanceParticipantsForParticipant(id: number) {
        const seanceParticipant = this.getSeanceParticipantfromParticipantId(id);
        if (seanceParticipant) {
            const seance = new Seance(this);
            seance.seance_participants = [];
            seance.seance_participants.push(seanceParticipant);
            return seance;
        }
        return this;
    }

    filterEvaluations(editable: boolean) {
        const seance = new Seance(this);
        for (let index = 0; index < seance.seance_participants.length; index++) {
            let seance_participant = seance.seance_participants[index];
            const evaluations = [...seance_participant.evaluations];
            const filteredEvaluations = evaluations.filter((evaluation) => {
                return evaluation.editable === editable;
            });
            if (evaluations.length !== filteredEvaluations.length) {
                seance_participant = new SeanceParticipant(seance_participant);
                seance_participant.evaluations = filteredEvaluations;
                seance.seance_participants[index] = seance_participant;
            }
        }
        return seance;
    }

    formatEvaluations(editable?: boolean) {
        let seance_participants = this.seance_participants;
        if (editable !== undefined) {
            seance_participants = this.filterEvaluations(editable).seance_participants;
        }
        return seance_participants.reduce((sp_display, current_sp, index_sp) => {
            sp_display += `${current_sp.toString()} : `;
            sp_display += current_sp.evaluations.reduce((e_display, current_e, index_e) => {
                e_display += `${current_e.seance_exercice.toString()}`;
                if (index_e !== current_sp.evaluations.length - 1) e_display += ', ';
                return e_display;
            }, '');
            if (index_sp !== seance_participants.length - 1) sp_display += '\n';
            return sp_display;
        }, '');
    }

    updateParticipantEvaluation(newEval: Evaluation, participant: Participant) {
        const seanceParticipant = this.seance_participants.find((value) => participant.id === value.participant.id);
        if (seanceParticipant) {
            const newSeanceParticipant = seanceParticipant.updateEvaluation(newEval);
            return this.updateSeanceParticipant(newSeanceParticipant);
        }

        return undefined;
    }
    /* End Other methods */
}
