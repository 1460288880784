import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';
import { number } from 'prop-types';

export const deleteUserDesc = {
    url: '/api/genericuser/${id}',
    method: HTTP_METHODS.DELETE,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        id: {
            in: PARAM_LOCATION.PATH,
            type: number,
            required: true,
        },
    },
    responses: {
        204: {
            success: true,
            message: "L'utilisateur a bien été supprimé ",
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Erreur lors du chargement',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: "Mauvaises informations d'identification",
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;

export const updateUserDesc = {
    url: '/api/genericuser/${id}',
    method: HTTP_METHODS.PUT,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        id: {
            in: PARAM_LOCATION.PATH,
            type: number,
            required: true,
        },
    },
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Les informations ont été mises à jour',
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Erreur lors du chargement',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;
