import {
    LOG_OUT,
    PUSH_NOTIFICATION,
    REMOVE_ALL_NOTIFICATIONS,
    REMOVE_NOTIFICATION,
    SET_CURRENT_EVALUATION,
} from './actionsTypes';
import { UserNotification } from './interfaces';
import { EvaluationExtended } from '../Models/Evaluation';

export const logOut = () => ({
    type: LOG_OUT,
});

export const pushNotification = (notification: UserNotification) => ({
    type: PUSH_NOTIFICATION,
    payload: notification,
});

export const removeNotification = (index: number) => ({
    type: REMOVE_NOTIFICATION,
    payload: {
        index: index,
    },
});

export const removeAllNotifications = () => ({
    type: REMOVE_ALL_NOTIFICATIONS,
    payload: null,
});

export const setCurrentEvaluation = (evaluation: EvaluationExtended) => ({
    type: SET_CURRENT_EVALUATION,
    payload: evaluation,
});
