export const localization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        labelRowsPerPage: 'Elements par pages:',
        labelRowsSelect: 'éléments',
        firstTooltip: 'Première Page',
        previousTooltip: 'Page précédente',
        nextTooltip: 'Page suivante',
        lastTooltip: 'Dernière Page',
    },
    body: {
        emptyDataSourceMessage: 'Aucun élément trouvé',
        editRow: {
            saveTooltip: 'Sauvegarder',
            cancelTooltip: 'Annuler',
            deleteText: 'Etes-vous sûr de vouloir supprimer cet élément ?',
        },
        addTooltip: 'Ajouter',
        deleteTooltip: 'Supprimer',
        editTooltip: 'Modifier',
    },
    toolbar: {
        searchPlaceholder: 'Rechercher',
        searchTooltip: 'Rechercher',
        nRowsSelected: '{0} élément(s) sélectionné',
    },
};
