import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const postExerciceEvaluation = {
    url: '/api/evaluation/${evaluationId}/score',
    method: HTTP_METHODS.POST,
    parameters: {
        evaluationId: {
            in: PARAM_LOCATION.PATH,
            type: 'number',
            required: true,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        submission: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
    },
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: "Enregistrement de l'évaluation avec succès",
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        423: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Une évaluation a déjà été soumis',
        },
    },
} as ApiDescription;
