import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CreateIcon from '@material-ui/icons/Create';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import * as interfaces from './interfaces';
import * as functions from './functions';
import { useRepository } from '../../../Repository/useRepository';
import { downloadFinalReportDesc, generateReportDesc, uploadFinalReportDesc } from '../../../Repository/reportDesc';
import { UserRole } from '../../../Models/User';
import { STATUS } from '../../Fetch/interfaces';

const SeanceReport = (props: interfaces.SeanceReportProps) => {
    const [generateStatus, generatedData, , generateReport] = useRepository(generateReportDesc, () => {
        functions.downloadFile(generatedData, generateStatus);
    });
    const [downloadStatus, downloadedData, , downloadReport] = useRepository(downloadFinalReportDesc, () => {
        functions.downloadFile(downloadedData, downloadStatus);
    });
    const [uploadStatus, , , uploadReport] = useRepository(
        uploadFinalReportDesc,
        () => {
            if (uploadStatus === STATUS.SUCCESS) {
                setUploadState(true);
            }
        },
        { notify: true, notifyOnSuccess: true }
    );
    const [uploadState, setUploadState] = useState(false);

    const handleFileSelect = (event: any) => {
        const files = event.target.files;
        if (files && files.length > 0 && props.seanceParticipant) {
            uploadReport({
                seanceParticipantId: props.seanceParticipant.id,
                body: {
                    files: { report: files[0] },
                },
            });
        }
    };

    return (
        <Container className="border">
            <Row>
                <Col>
                    <p>
                        <strong>Rapport : </strong>
                    </p>
                </Col>
                {props.currentUser.current_role === UserRole.OBSERVATEUR ? (
                    <Col>
                        <Button
                            variant="light"
                            className="d-flex"
                            onClick={() => {
                                generateReport(
                                    functions.getDownloadParams(
                                        props.seance,
                                        props.currentUser,
                                        props.seanceParticipant
                                    )
                                );
                            }}
                        >
                            <CreateIcon />
                            <label className={'auto-margin'}>Générer Rapport</label>
                        </Button>
                    </Col>
                ) : null}
                {functions.canUploadReport(props.currentUser, props.seance, props.seanceParticipant) ? (
                    <Col>
                        <Button variant="light" className="d-flex">
                            <PublishIcon />
                            <label htmlFor="input_report_upload" className={'auto-margin'}>
                                Charger Rapport Final
                            </label>
                        </Button>
                        <input
                            id={'input_report_upload'}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => handleFileSelect(event)}
                        />
                    </Col>
                ) : null}
                {uploadState ||
                functions.canDownloadReport(props.currentUser, props.seance, props.seanceParticipant) ? (
                    <Col>
                        <Button
                            variant="light"
                            className="d-flex"
                            onClick={() => {
                                downloadReport(
                                    functions.getDownloadParams(
                                        props.seance,
                                        props.currentUser,
                                        props.seanceParticipant
                                    )
                                );
                            }}
                        >
                            <GetAppIcon />
                            <label className={'auto-margin'}>Télécharger Rapport Final</label>
                        </Button>
                    </Col>
                ) : null}
            </Row>
        </Container>
    );
};

export default SeanceReport;
