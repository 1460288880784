import React from 'react';
import { Row } from './interfaces';

export interface NotationProps {
    value: number;
    selected: number;
    unique?: boolean;
    disabled: boolean;
    update: (value: number) => void;
}

interface ShowRowProps {
    row: Row;
    disabled: boolean;
}

const ShowRow = ({ row, disabled }: ShowRowProps) => {
    const checkboxesValues = row.multipleValues ? [0, 1, 2, 3] : [1];

    return (
        <>
            {checkboxesValues.map((element: number) => (
                <td key={element}>
                    <NotationMemo
                        value={element}
                        selected={row.score}
                        update={row.update}
                        unique={!row.multipleValues}
                        disabled={disabled}
                    />
                </td>
            ))}
        </>
    );
};

export const ShowMemoRow = React.memo(ShowRow);

const Notation = (props: NotationProps) => {
    const { value, selected, unique, disabled } = props;
    // manages unique and multiple checkboxes
    const newValue = unique ? (selected ? 0 : 1) : value;

    const onChange = (event: any) => {
        event.preventDefault();
        props.update(newValue);
    };

    return <input type="checkbox" checked={value === selected} disabled={disabled} onChange={onChange} />;
    // return <MemoCheckbox checked={value === selected} disabled={disabled} onChange={onChange} color="primary" />;
};

export const NotationMemo = React.memo(Notation);
