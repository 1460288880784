import { Observateur } from './Observateur';

export interface SeanceObservateurInterface {
    id: number;
    seance: number;
    observateur: Observateur;
}

export class SeanceObservateur implements SeanceObservateurInterface {
    id: number;
    seance: number;
    observateur: Observateur;

    constructor(seanceObservateur: SeanceObservateurInterface) {
        this.id = seanceObservateur.id;
        this.observateur = seanceObservateur.observateur;
        this.seance = seanceObservateur.seance;
    }

    static fromJSON(data: SeanceObservateurInterface): SeanceObservateur {
        return new this({
            ...data,
            observateur: Observateur.fromJSON(data.observateur),
        });
    }

    equals(seanceObservateur: SeanceObservateurInterface) {
        return (
            seanceObservateur.id === this.id &&
            seanceObservateur.seance === this.seance &&
            seanceObservateur.observateur.user.id === this.observateur.user.id
        );
    }

    toString() {
        return this.observateur.toString();
    }

    toCreate() {
        return { observateur: this.observateur.id };
    }
}
