import { Exercice } from "../../../Models/Exercice";

export const handleFileSelect = (event: any, exercice: Exercice, uploadTemplate: Function) => {
    const files = event.target.files;
    if (files && files.length > 0) {
        uploadTemplate({
            exerciceId: exercice.id,
            body: {
                files: { template: files[0] },
            },
        });
    }
};

export const selectFile = (exercice: Exercice, uploadTemplate: Function) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = e => {
        handleFileSelect(e, exercice, uploadTemplate);
    }
    input.click();
};