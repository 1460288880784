import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalStore, NotificationType } from '../Redux/interfaces';
import useFetch from '../Components/Fetch/useFetch';
import { ApiDescription, STATUS } from '../Components/Fetch/interfaces';
import { RepositoryOptions } from './interfaces';
import { pushNotification } from '../Redux/actions';
import { getAuthFromStorage } from '../Components/Login/functions';

export const useRepository = (
    apiDescription: ApiDescription,
    onSuccess: (_: any) => any = () => {},
    { token, notify, notifyOnSuccess }: RepositoryOptions = { notify: true, notifyOnSuccess: false }
): [STATUS, any, string, any] => {
    const dispatch = useDispatch();
    const [status, data, message, fetchData] = useFetch(apiDescription);
    const reduxAuthState = useSelector((state: globalStore) => state.authentification);
    const authState = getAuthFromStorage() ?? reduxAuthState;

    const getData = (params: any) => {
        fetchData({
            ...params,
            Authorization: `Token ${token || authState?.token}`,
            'X-Company': authState?.currentCompany.id,
        });
    };

    useEffect(() => {
        switch (status) {
            case STATUS.FAILED:
                if (notify)
                    dispatch(
                        pushNotification({
                            isActive: true,
                            type: NotificationType.ERROR,
                            message: message,
                        })
                    );
                break;
            case STATUS.SUCCESS:
                if (notifyOnSuccess) {
                    dispatch(
                        pushNotification({
                            isActive: true,
                            type: NotificationType.NOTIFICATION,
                            message: message,
                        })
                    );
                }
                onSuccess(data);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return [status, data, message, getData];
};
