import { createStore, combineReducers, Store, AnyAction } from 'redux';
import reducers from './reducers';
import { globalStore } from './interfaces';

const allReducers = combineReducers({
    authentification: reducers.authentificationReducer,
    notification: reducers.notificationReducer,
    currentEvaluation: reducers.currentEvaluationReducer,
});

const store: Store<globalStore, AnyAction> = createStore(allReducers);

export default store;
