import {
    Add, Description, People, Search, Upload, Group_work, School
} from '../../ImagesTSX';

export interface SidebarItem {
    label: string;
    icon?: () => JSX.Element;
    children?: SidebarItem[];
    target?: string;
}

const adminOptions: SidebarItem[] = [
    {
        label: 'Comptes',
        icon: People,
        children: [
            {
                label: 'Rechercher',
                icon: Search,
                target: '/main/user',
            },
            {
                label: 'Nouveau',
                icon: Add,
                target: '/main/user/new',
            },
        ],
    },
    {
        label: 'Référentiel',
        icon: Description,
        children: [
            {
                label: 'Charger des fichiers',
                target: '/main/referential/upload',
                icon: Upload,
            },
        ],
    },
    {
        label: 'Exercices',
        icon: School,
        children: [
            {
                label: 'Rechercher',
                target: '/main/exercice',
                icon: Search,
            },
        ],
    },
];

const observateurOptions: SidebarItem[] = [
    {
        label: 'Comptes',
        icon: People,
        children: [
            {
                label: 'Rechercher',
                icon: Search,
                target: '/main/user',
            },
            {
                label: 'Nouveau',
                icon: Add,
                target: '/main/user/new',
            },
        ],
    },
    {
        label: 'Seances',
        icon: Group_work,
        children: [
            {
                label: 'Rechercher',
                icon: Search,
                target: '/main/seance',
            },
            {
                label: 'Nouveau',
                icon: Add,
                target: '/main/seance/new',
            },
        ],
    },
];

const participantOptions: SidebarItem[] = [
    {
        label: 'Seances',
        icon: Group_work,
        children: [
            {
                label: 'Rechercher',
                icon: Search,
                target: '/main/seance',
            },
        ],
    },
];

export const menuOptions = {
    admin: adminOptions,
    observateur: observateurOptions,
    participant: participantOptions,
};
