import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const seanceListDesc = {
    url: '/api/seance/',
    method: HTTP_METHODS.GET,
    parameters: {
        page_size: {
            in: PARAM_LOCATION.QUERY,
            type: 'number',
            required: false,
        },
        page: {
            in: PARAM_LOCATION.QUERY,
            type: 'number',
            required: false,
        },
        search: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
        ordering: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Séance retrouvée',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;

export const seanceDesc = {
    url: '/api/seance/${seanceId}',
    method: HTTP_METHODS.GET,
    parameters: {
        seanceId: {
            in: 'path',
            type: 'string',
            required: true,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Séance retrouvé',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Séance non trouvée',
        },
    },
} as ApiDescription;

export const seanceDeleteDesc = {
    url: '/api/seance/${seanceId}',
    method: HTTP_METHODS.DELETE,
    parameters: {
        seanceId: {
            in: 'path',
            type: 'string',
            required: true,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    responses: {
        204: {
            content: DATA_TYPE.TEXT,
            success: true,
            message: 'Séance supprimée',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Séance non trouvée',
        },
    },
} as ApiDescription;

export const seanceDescPatch = {
    url: '/api/seance/${seanceId}',
    method: HTTP_METHODS.PATCH,
    parameters: {
        seanceId: {
            in: 'path',
            type: 'string',
            required: true,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Séance mise à jour',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        400: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Mauvaise requête',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Séance non trouvée',
        },
        412: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Mauvaise reqûete',
        },
    },
} as ApiDescription;

export const seanceCreateDesc = {
    url: '/api/seance/',
    method: HTTP_METHODS.POST,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        201: {
            content: DATA_TYPE.TEXT,
            success: true,
            message: 'Successful session creation',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;
