export const genderList = ['Homme', 'Femme', 'Autre'];

export const ageCategoriesList = [
    '- de 20 ans',
    '21 - 30 ans',
    '31 - 40 ans',
    '41 - 50 ans',
    '51 - 60 ans',
    '61 - 70 ans',
    '+ de 70 ans',
];

export const jobCategoriesList = [
    'Agriculteurs exploitants',
    "Artisans, commerçants, chefs d'entreprise",
    'Cadres et professions intellectuelles supérieures',
    'Professions intermédiaires',
    'Employés',
    'Ouvriers',
    'Retraités',
];
