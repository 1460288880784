import { User, UserType as UserTypeInterface, EmptyUser, UserRole, UserUpdate } from '../../../Models/User';
import { UserProfileState } from './interfaces';
import { Authentification, UserType, NotificationType } from '../../../Redux/interfaces';
import { PUSH_NOTIFICATION } from '../../../Redux/actionsTypes';
import { FormType } from '../../Form/type';
import { Company } from '../../../Models/Company';
import { FormDescription, OptionPair } from '../../Form/interfaces';
import { ageCategoriesList, genderList, jobCategoriesList } from './constants';

export const profileFormDescription = (
    isNew: boolean,
    showPassword: boolean,
    userTypes: UserTypeInterface[],
    showCompanies?: boolean,
    companies?: Company[]
): FormDescription => {
    let formDescription: FormDescription = {
        first_name: {
            label: 'Prénom',
            type: 'text',
            required: true,
            validator: FormType.ANY,
        },
        last_name: {
            label: 'Nom',
            type: 'text',
            required: true,
            validator: FormType.ANY,
        },
        email: {
            label: 'Adresse mail',
            type: 'text',
            required: true,
            validator: FormType.EMAIL,
        },
        gender: {
            label: 'Sexe',
            type: 'select',
            required: false,
            validator: FormType.ANY,
            options: createOptionsFromList(genderList, true),
        },
        job_title: {
            label: 'Emploi',
            type: 'text',
            required: false,
            validator: FormType.ANY,
        },
        job_category: {
            label: 'Catégorie socio-professionnelle',
            type: 'select',
            required: false,
            validator: FormType.ANY,
            options: createOptionsFromList(jobCategoriesList, true),
        },
        age_category: {
            label: "Catégorie d'age",
            type: 'select',
            required: false,
            validator: FormType.ANY,
            options: createOptionsFromList(ageCategoriesList, true),
        },
    };
    if (isNew) {
        formDescription = {
            ...formDescription,
            user_type: {
                label: "Type d'utilisateur",
                type: 'select',
                required: true,
                validator: FormType.ANY,
                options: userTypes,
            },
        };
    }
    if (showCompanies) {
        formDescription = {
            ...formDescription,
            companies: {
                label: 'Entreprises',
                type: 'select',
                multiple: true,
                required: false,
                validator: FormType.ANY,
                options: companies ?? [],
            },
        };
    }
    if (showPassword) {
        formDescription = {
            ...formDescription,
            password: {
                label: 'Mot de passe',
                type: 'password',
                required: true,
                validator: FormType.PASSWORD,
                lengthMin: 8,
            },
            passwordConfirmation: {
                label: 'Confirmation mot de passe',
                type: 'password',
                required: true,
                validator: FormType.PASSWORD,
                lengthMin: 8,
            },
        };
    }
    return formDescription;
};

export const initialValuesProfile = (
    isNew: boolean,
    showPassword: boolean,
    user: User,
    showCompanies?: boolean
): Object => {
    let initialValues: Object = {
        email: isNew ? '' : user.email,
        first_name: isNew ? '' : user.first_name,
        last_name: isNew ? '' : user.last_name,
        gender: isNew ? 0 : user.gender,
        job_title: isNew ? '' : user.job_title,
        job_category: isNew ? 0 : user.job_category,
        age_category: isNew ? 0 : user.age_category,
    };
    if (isNew) {
        initialValues = {
            ...initialValues,
            user_type: UserType.PARTICIPANT,
        };
    }
    if (showCompanies) {
        initialValues = {
            ...initialValues,
            companies: isNew ? [] : user.companies.map((c) => c.id),
        };
    }
    if (showPassword) {
        initialValues = {
            ...initialValues,
            password: '',
            passwordConfirmation: '',
        };
    }
    return initialValues;
};

export const initUserProfileState = (authentification: Authentification) => {
    let userTypes: UserTypeInterface[] = [];
    if (authentification.current_role && authentification.current_role === UserRole.OBSERVATEUR) {
        userTypes.push({ id: UserType.PARTICIPANT, label: 'Participant' });
    }
    if (authentification.current_role && authentification.current_role === UserRole.ADMIN) {
        userTypes.push({ id: UserType.PARTICIPANT, label: 'Participant' });
        userTypes.push({ id: UserType.OBSERVATEUR, label: 'Observateur' });
    }
    const user = {
        ...EmptyUser,
        ...authentification,
    };
    return {
        showPassword: false,
        userTypes,
        user,
    } as UserProfileState;
};

export const notifications = {
    mismatchedPasswords: {
        type: PUSH_NOTIFICATION,
        payload: {
            isActive: true,
            type: NotificationType.ERROR,
            message: 'Les mot de passe ne correspondent pas',
        },
    },
    successfulCreation: {
        type: PUSH_NOTIFICATION,
        payload: {
            isActive: true,
            type: NotificationType.NOTIFICATION,
            message: "L'utilisateur a été crée",
        },
    },
    successfulUpdate: {
        type: PUSH_NOTIFICATION,
        payload: {
            isActive: true,
            type: NotificationType.NOTIFICATION,
            message: 'La mise à jour a été réalisée',
        },
    },
};

export const transformUserToAuth = (user: UserUpdate, currentAuth: Authentification, companies: Company[]) => {
    return {
        ...currentAuth,
        ...user,
        companies: user.companies.map((uc) => {
            let company = companies.find((c) => c.id === uc);
            return company ?? { id: uc, name: 'NA' };
        }),
    };
};

function createOptionsFromList(options: string[], mayBeEmpty: boolean): OptionPair[] {
    if (mayBeEmpty) options = ['', ...options];

    return options.map((option, index) => {
        return {
            id: index,
            name: option,
        };
    });
}
