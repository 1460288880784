import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { globalStore } from '../../Redux/interfaces';
import { getRoleFullName, getUserRoles, UserRole } from '../../Models/User';
import './Role.css';
import { CREATE_OR_UPDATE } from '../../Redux/actionsTypes';
import { Loader } from '../../App';

export const Role = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: globalStore) => state.authentification);
    const roles = getUserRoles(user);

    const setRole = (role: UserRole) => {
        dispatch({
            type: CREATE_OR_UPDATE,
            payload: { ...user, current_role: role },
        });
        history.push('/main');
    };

    useEffect(() => {
        if (roles.length === 1) setRole(roles[0]);
    });

    switch (roles.length) {
        case 0:
            return <Loader />;
        case 1:
            return null;
        default:
            return (
                <div className="Role">
                    <div>En tant que quel type d'utilisateur voulez-vous vous connecter ?</div>
                    <div className="RoleElements">
                        {roles.map((role) => (
                            <Button key={role} onClick={() => setRole(role)}>
                                {getRoleFullName(role)}
                            </Button>
                        ))}
                    </div>
                </div>
            );
    }
};
