import {SeanceViewState, ViewState, SeanceState, VState} from './interfaces';
import { Seance } from '../../../Models/Seance';


export const initSeanceEditionState = (participate: boolean, isNew: boolean): SeanceViewState => {
    return {
        viewState: isNew? ViewState.CHOICE : participate? ViewState.PARTICIPATE : ViewState.VIEWING
        // viewState: participate && (!isNew) ? ViewState.PARTICIPATE : seance || id ? ViewState.VIEWING : ViewState.CHOICE,
    };
};

export const initSeanceState = (seance?: Seance): SeanceState => {
    return {
        seance: seance ?? Seance.makeBlankSeance(),
    };
};

export const getClosingErrorMessage = (seance: Seance):  string => {
    return "Impossible de clôturer la séance, certaines evaluations n'ont pas été soumises :\n" +
        seance.formatEvaluations(true);
}

export const getInitialViewState = (participate: boolean, isNew: boolean): VState => {
    if (isNew) return VState.CHOICE;
    if (participate) return VState.PARTICIPATE;
    return VState.VIEWING;
};

