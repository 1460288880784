import React, { useState } from 'react';
import { validate, validateAll } from './formValidation';
import { FormDescription, FormDictionary, StringObject } from './interfaces';

const useForm = (
    formDescription: FormDescription,
    callback: Function,
    initialValues: any,
    dict: FormDictionary
): {
    values: any;
    errors: StringObject;
    submitted: boolean;
    handleChange: any;
    handleSubmit: any;
    handleReinit: any;
} => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState(validateAll(values, formDescription, dict));
    const [submitted, setSubmitted] = useState(false);

    const handleReinit = () => {
        setValues(initialValues);
        setErrors(validateAll(initialValues, formDescription, dict));
        setSubmitted(false);
    };

    const handleChange = (event: React.ChangeEvent) => {
        event.persist();
        const element = event.target as any;
        const name = element.name;
        let value = element.value;

        if (element.multiple) {
            // case of a multiple select
            let newVal: number[] = [];
            if (element.selectedOptions) {
                const selectedOptions = Array.from(element.selectedOptions as HTMLCollection);
                newVal = selectedOptions.map((option: any) => Number(option.value));
            }
            value = newVal;
        }

        setValues((values: any) => ({
            ...values,
            [name]: value,
        }));

        setErrors((errors: any) => ({
            ...errors,
            [name]: validate(value, formDescription[name], dict),
        }));
    };

    const handleSubmit = (event?: React.ChangeEvent) => {
        if (event) event.preventDefault();
        setSubmitted(true);
        callback(values);
    };

    return {
        values,
        errors,
        submitted,
        handleChange,
        handleSubmit,
        handleReinit,
    };
};

export default useForm;
