import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, ButtonGroup, Button } from 'react-bootstrap';
import './Welcome.css';
import { useSelector } from 'react-redux';
import { globalStore } from '../../Redux/interfaces';

const Welcome = () => {
    const history = useHistory();
    const user = useSelector((state: globalStore) => state.authentification);

    if (user.logged_in) {
        if (user.current_role) history.replace('/main');
        else if (user.currentCompany) history.push('/role');
        else history.push('/company');

        return null;
    }

    const redirectToLogin = () => {
        history.push('/login');
    };

    return (
        <Container className="welcome d-flex flex-column text-center">
            <h2>Bonjour !</h2>
            <p>Bienvenue sur l'application web pour les Ateliers de Développement des Aptitudes Managériales : ADAM</p>
            <div>
                <ButtonGroup>
                    <Button onClick={redirectToLogin}>Se connecter</Button>
                </ButtonGroup>
            </div>
        </Container>
    );
};

export default Welcome;
