import React from 'react';
import { useSelector } from 'react-redux';
import { globalStore } from '../../../../Redux/interfaces';
import { Table } from '../../../Table/Table';
import CustomDropDown from '../../../CustomDropDown/CustomDropDown';
import { Container, Row, Col, Tabs, Tab, Badge } from 'react-bootstrap';
import { SeanceParticipant } from '../../../../Models/SeanceParticipant';
import { Evaluation, EvaluationInterface } from '../../../../Models/Evaluation';
import { UserRole } from '../../../../Models/User';
import SeanceReport from '../../SeanceReport/SeanceReport';
import { canAccessReport } from '../../SeanceReport/functions';
import * as interfaces from './interfaces';
import * as functions from './functions';
import './SeanceParticipantView.css';


const SeanceParticipantView = (props: interfaces.SeanceParticipantViewProps) => {

    const currentSPId = useSelector((state: globalStore) => state.currentEvaluation)?.seance_participant.id;
    const currentTabKey = props.seance.seance_participants.find((sP) => sP.id === currentSPId)?.id;

    const updateSeanceParticipant = (seanceParticipant: SeanceParticipant) => {
        props.updateSeance(props.seance.updateSeanceParticipant(seanceParticipant));
    };

    const renderTab = (sp: SeanceParticipant) => {
        const updateEvaluation = (updatedEvaluation: Evaluation) => {
            updateSeanceParticipant(sp.updateEvaluation(updatedEvaluation));
        };
        const remainingEvals = functions.remainingEvals(
            sp, props.currentUser,
            props.isParticipating, props.seance
        );
        const tabTitle = <TabTitle sP={sp} remainingEvals={remainingEvals} />
        return (
            <Tab key={sp.id} eventKey={sp.id} title={tabTitle}>
                <Container>
                    <Row>
                        <Col>{'Observateur principal : '}</Col>
                        <Col>
                            <CustomDropDown id={'obs_principal_options'}
                                title={sp.observateur_principal?.toString()}
                                show={true} editable={props.isEditable} options={props.seance.seance_observateurs}
                                onSelect={(obs) => updateSeanceParticipant(sp.setObservateurPrincipal(obs))} />
                        </Col>
                    </Row>
                    {
                        canAccessReport(props.currentUser, props.seance, sp) ?
                            <Row><Col>
                                <SeanceReport seance={props.seance}
                                    currentUser={props.currentUser} seanceParticipant={sp}
                                />
                            </Col></Row> :
                            null
                    }
                    <Row>
                        <Col>
                            <Table<EvaluationInterface>
                                columns={
                                    functions.getEvaluationColumns(
                                        props.seance, props.currentUser, props.isParticipating,
                                        props.isEditable, sp,
                                        props.evaluate, updateEvaluation)
                                }
                                title={""}
                                // data={functions.filterAutonomous(sp.evaluations)}
                                data={sp.evaluations}
                                options={{
                                    search: false, sorting: false, paging: false,
                                    showTitle: false, toolbar: false
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Tab>
        )
    }

    if (props.currentUser.current_role === UserRole.PARTICIPANT) return null;

    return (
        <Row>
            <Col id="seanceParticipantTabsContainer">
                <Tabs id="seanceParticipantTabs" defaultActiveKey={currentTabKey}>
                    {
                        props.seance.seance_participants.map((sp) => (renderTab(sp)))
                    }
                </Tabs>
            </Col>
        </Row>

    );
};



const TabTitle = (props: interfaces.TabTitleProps) => {
    return (
        <p>
            {props.sP.toString()}
            <Badge className="tab_badge"
                hidden={props.remainingEvals === 0} variant="primary">
                {props.remainingEvals}
            </Badge>
        </p>
    )
}

export default SeanceParticipantView;
