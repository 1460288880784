import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NotificationType } from '../../Redux/interfaces';
import { STATUS } from '../Fetch/interfaces';
import { EXERCICE_TYPE, ReceivedData, Row } from './interfaces';
import { Button } from 'react-bootstrap';
import './ExerciceEvaluation.css';
import { useHistory } from 'react-router';
import { useRepository } from '../../Repository/useRepository';
import { Loader } from '../../App';
import { postExerciceEvaluation } from '../../Repository/exerciceEvaluationDesc';
import { getEvaluationDesc } from '../../Repository/evaluationDesc';
import { pushNotification } from '../../Redux/actions';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ShowMemoRow } from './constants';
import { PageSkeleton } from '../PageSkeleton/PageSkeleton';
import { EvaluationWithParticipant } from '../../Models/Evaluation';

export const ExerciceEvaluation = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { evaluationId } = useParams();
    const [modified, setModified] = useState(false);
    const [editable, setEditable] = useState(false);
    const [comments, setComments] = useState('');
    const [inBasketData, setInBasketData] = useState<{ [id: number]: any }>({});
    const [rows, setRows] = useState<{ [id: string]: Row }>({});
    const [selectedSituation, setSelectedSituation] = useState<number>(0);
    const [exerciceType, setExerciceType] = useState(EXERCICE_TYPE.DEFAULT);
    const [status, evaluationData, , getEvaluation] = useRepository(getEvaluationDesc);
    const [uploadStatus, uploadedData, , uploadEvaluation] = useRepository(postExerciceEvaluation, () =>
        dispatch(
            pushNotification({
                isActive: true,
                type: NotificationType.NOTIFICATION,
                message: "L'évaluation a bien été enregistrée",
            })
        )
    );

    const evaluation: EvaluationWithParticipant = evaluationData;

    useEffect(() => {
        if (status === STATUS.INIT) {
            getEvaluation({
                id: evaluationId,
            });
        }
        if (status === STATUS.SUCCESS) {
            const {
                name,
                evaluatedItems_competences,
                evaluatedItems_comportements,
                evaluatedItems_situations,
                personalities,
            } = evaluationData.seance_exercice.exercice as ReceivedData;

            const execType = (() => {
                switch (name) {
                    case 'In Basket':
                        setExerciceType(EXERCICE_TYPE.IN_BASKET);
                        return EXERCICE_TYPE.IN_BASKET;
                    case 'Egogramme':
                        setExerciceType(EXERCICE_TYPE.EGOGRAMME);
                        return EXERCICE_TYPE.EGOGRAMME;
                    case 'Auto-Evaluation':
                        setExerciceType(EXERCICE_TYPE.AUTO_EVAL);
                        return EXERCICE_TYPE.AUTO_EVAL;
                    default:
                        return EXERCICE_TYPE.DEFAULT;
                }
            })();

            const newData = [
                ...evaluatedItems_competences,
                ...evaluatedItems_comportements,
                ...evaluatedItems_situations,
                ...personalities,
            ];

            if (name === EXERCICE_TYPE.IN_BASKET) {
                setInBasketData(convertToIBRows(newData));
            } else {
                const newRows = convertToRows(execType, newData);
                const newDictRows = newRows.reduce((dict, row) => {
                    return {
                        ...dict,
                        [row.id.toString()]: row,
                    };
                }, {});
                setRows(newDictRows);
            }
            setEditable(evaluationData.editable);
            setComments(evaluationData.comments ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (uploadStatus === STATUS.SUCCESS) {
            if (editable !== uploadedData.editable) {
                setEditable(uploadedData.editable);
                history.goBack();
            }
            setModified(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadStatus]);

    if (status === STATUS.FAILED) {
        history.goBack();
        return null;
    }

    if (!evaluation || status !== STATUS.SUCCESS) {
        return <Loader />;
    }

    const exercice = evaluation.seance_exercice.exercice;
    const participant = evaluation.participant.user;

    const updateRow = (id: string, value?: number) => {
        setRows((prevRows) => {
            const rowToUpdate = prevRows[id];
            value = value ?? 1 - rowToUpdate.score;
            return {
                ...prevRows,
                [id]: {
                    ...rowToUpdate,
                    score: value,
                },
            };
        });
        setModified(true);
    };

    const convertToIBRows = (data: ReceivedData[]): object => {
        return data.reduce((rowsDict, { standard_sentences, ...data }) => {
            return {
                ...rowsDict,
                [data.id]: {
                    ...data,
                    standardSentences: standard_sentences!!.reduce((ssDict, ss) => {
                        return {
                            ...ssDict,
                            [ss.id]: {
                                ...ss,
                                update: (_: number) => {},
                            },
                        };
                    }, {}),
                },
            };
        }, {});
    };

    const convertToRows = (execType: EXERCICE_TYPE, data: ReceivedData[]): Row[] => {
        let index = 0;
        return data.reduce((newRows, row: ReceivedData) => {
            const { id, name, description, score, full_description } = row;
            if (execType === EXERCICE_TYPE.EGOGRAMME || execType === EXERCICE_TYPE.IN_BASKET) {
                const { standard_sentences, questions } = row;
                const details = [...(standard_sentences || []), ...(questions || [])].reduce((sss, ss) => {
                    const rowId = execType === EXERCICE_TYPE.IN_BASKET ? index++ : ss.id;
                    return [
                        ...sss,
                        {
                            id: rowId,
                            name: execType === EXERCICE_TYPE.EGOGRAMME ? ss.name : name,
                            description: ss.description || ss.name,
                            score: ss.score || 0,
                            multipleValues: execType === EXERCICE_TYPE.EGOGRAMME,
                            parent: row.id,
                            ss_id: ss.id,
                            update: (value: number) => updateRow(rowId, value),
                        },
                    ];
                }, [] as Row[]);
                return [...newRows, ...details];
            }

            const autoEvalSpecificity = execType === EXERCICE_TYPE.AUTO_EVAL ? { full_description } : {};

            return [
                ...newRows,
                {
                    id: id,
                    name: name,
                    description: description,
                    score: score || 0,
                    multipleValues: execType === EXERCICE_TYPE.AUTO_EVAL,
                    update: (value: number) => updateRow(id.toString(), value),
                    ...autoEvalSpecificity,
                },
            ];
        }, [] as Row[]);
    };

    const upload = (submission?: boolean) => {
        let rowsToSend;
        if (exerciceType === EXERCICE_TYPE.IN_BASKET) {
            rowsToSend = Object.values(inBasketData).reduce((rows, situation) => {
                return [
                    ...rows,
                    ...(Object.values(situation.standardSentences).reduce((sses: any, ss: any) => {
                        return [
                            ...sses,
                            {
                                ...ss,
                                parent: situation.id,
                            },
                        ];
                    }, []) as any[]),
                ];
            }, []);
        } else {
            rowsToSend = Object.values(rows).map((row) => row);
        }
        uploadEvaluation({ evaluationId, body: { items: rowsToSend, comments }, submission });
    };

    const buttonArea = (
        <div className="ButtonArea">
            <Button
                variant="outline-primary"
                size="lg"
                disabled={uploadStatus === STATUS.PENDING}
                onClick={() => upload()}
            >
                Enregistrer
            </Button>
            <Button size="lg" disabled={uploadStatus === STATUS.PENDING} onClick={() => upload(true)}>
                Soumettre
            </Button>
        </div>
    );

    const commentsArea = (
        <div className="CommentsArea">
            <label>Commentaires :</label>
            <textarea
                disabled={!editable}
                value={comments}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setComments(e.target.value);
                    setModified(true);
                }}
            />
        </div>
    );

    const columnsName: { [key: string]: (string | string[])[] } = {
        [EXERCICE_TYPE.AUTO_EVAL]: [
            ['Description', 'no-rotation'],
            'Faible maîtrise',
            'Maîtrise à consolider',
            'Bonne maîtrise',
            'Très bonne maîtrise',
        ],
        [EXERCICE_TYPE.EGOGRAMME]: ['Jamais', 'Quelquefois', 'Souvent', 'Toujours'],
    };

    let header: JSX.Element[] = (columnsName[exerciceType] ?? ['Observé']).map((colName) => {
        let className;
        if (typeof colName !== 'string') {
            [colName, className] = colName as string[];
        }
        return (
            <th key={colName} className={className}>
                {colName}
            </th>
        );
    });

    const pending = uploadStatus === STATUS.PENDING;

    const TableComponent = () => (
        <table className={exercice.is_autonomous ? 'Autonomous' : undefined}>
            <thead>
                <tr>
                    <th className="no-rotation">Nom</th>
                    {Object.values(rows).length > 0 && header}
                </tr>
            </thead>
            <tbody>
                {Object.values(rows).map((row: Row) => (
                    <tr
                        className={editable && !row.multipleValues ? 'clickableRow' : 'notClickableRow'}
                        key={row.id}
                        onClick={() => {
                            if (!row.multipleValues && editable) updateRow(row.id.toString());
                        }}
                    >
                        <td>{row.description}</td>
                        {exerciceType === EXERCICE_TYPE.AUTO_EVAL ? <td>{row.full_description}</td> : null}
                        <ShowMemoRow row={row} disabled={!editable} />
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const showRows = (id: number) => {
        if (selectedSituation === id) {
            setSelectedSituation(0);
        } else {
            setSelectedSituation(id);
        }
    };

    const updateSSRow = (situationId: number, ssId: number) => {
        setInBasketData((prevData) => {
            const prevSituation = prevData[situationId];
            const prevSs = prevSituation.standardSentences;
            const ssToUpdate = prevSs[ssId];
            return {
                ...prevData,
                [situationId]: {
                    ...prevSituation,
                    standardSentences: {
                        ...prevSs,
                        [ssId]: {
                            ...ssToUpdate,
                            score: 1 - ssToUpdate.score,
                        },
                    },
                },
            };
        });
        setModified(true);
    };

    const TableIBComponent = () => (
        <table className="InBasket">
            <thead>
                <tr>
                    <th className="no-rotation">Nom</th>
                    {selectedSituation !== 0 && header}
                </tr>
            </thead>
            <tbody>
                {Object.values(inBasketData).map((situation: any) => (
                    <React.Fragment key={situation.id}>
                        <tr className="Situation" onClick={() => showRows(situation.id)}>
                            <td style={{ display: 'flex', flexDirection: 'row' }}>
                                {situation.id === selectedSituation ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                <div>{situation.name}</div>
                            </td>
                            <td />
                        </tr>
                        {situation.id === selectedSituation &&
                            Object.values(situation.standardSentences).map((ss: any) => (
                                <tr
                                    key={ss.id}
                                    onClick={() => {
                                        if (ss && editable) updateSSRow(situation.id, ss.id);
                                    }}
                                >
                                    <td>{ss.description}</td>
                                    <ShowMemoRow row={ss} disabled={!editable} />
                                </tr>
                            ))}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );

    const titleName: { [key: string]: string } = {
        [EXERCICE_TYPE.EGOGRAMME]: 'Égogramme',
        [EXERCICE_TYPE.AUTO_EVAL]: 'Auto-Évaluation',
    };

    const title = (
        <h3>
            {titleName[exerciceType] ??
                `Evaluation de l'exercice ${exercice.name} de ${participant.first_name} ${participant.last_name}`}
        </h3>
    );

    return (
        <PageSkeleton
            confirmBeforeRedirect={editable && modified}
            confirmMessage="Les modifications apportées à l'évaluation ne seront pas sauvegardées"
        >
            <div className={`evalpage-container ${exercice.is_autonomous ? 'autonomous' : ''}`}>
                {!exercice.is_autonomous && commentsArea}
                <div className="EvalContainer">
                    <div className="Evaluation">
                        {exercice && title}
                        <div className="ExerciceEvaluation">
                            {!pending && exerciceType === EXERCICE_TYPE.IN_BASKET ? (
                                <TableIBComponent />
                            ) : (
                                <TableComponent />
                            )}
                            {pending && <Loader />}
                        </div>
                        {editable && buttonArea}
                    </div>
                </div>
            </div>
        </PageSkeleton>
    );
};
