import { Participant } from './Participant';
import { Evaluation } from './Evaluation';
import { SeanceObservateur } from './SeanceObservateur';
import { SeanceExerciceInterface } from './SeanceExercice';

export interface SeanceParticipantInterface {
    id: number;
    seance: number;
    participant: Participant;
    observateur_principal?: SeanceObservateur;
    evaluations: Evaluation[];
    available_report?: boolean
}

export class SeanceParticipant implements SeanceParticipantInterface {
    id: number;
    seance: number;
    participant: Participant;
    observateur_principal?: SeanceObservateur;
    evaluations: Evaluation[];
    available_report: boolean;

    constructor(seanceParticipant: SeanceParticipantInterface) {
        this.id = seanceParticipant.id;
        this.seance = seanceParticipant.seance;
        this.participant = seanceParticipant.participant;
        this.observateur_principal = seanceParticipant.observateur_principal;
        this.evaluations = seanceParticipant.evaluations;
        this.available_report = seanceParticipant.available_report ?? false;
    }

    static fromJSON(data: SeanceParticipantInterface): SeanceParticipant {
        return new this({
            ...data,
            participant: Participant.fromJSON(data.participant),
            observateur_principal: data.observateur_principal
                ? SeanceObservateur.fromJSON(data.observateur_principal)
                : undefined,
            evaluations: data.evaluations.map((evaluation) => Evaluation.fromJSON(evaluation)),
        });
    }

    equals(seanceParticipant: SeanceParticipantInterface) {
        return (
            seanceParticipant.id === this.id &&
            seanceParticipant.seance === this.seance &&
            seanceParticipant.participant.user.id === this.participant.user.id
        );
    }

    toString() {
        return this.participant.toString();
    }

    setObservateurPrincipal(seanceObservateur: SeanceObservateur) {
        const seanceParticipant = new SeanceParticipant(this);
        seanceParticipant.observateur_principal = seanceObservateur;
        return seanceParticipant;
    }

    setEvaluations(evaluations: Evaluation[]) {
        const seanceParticipant = new SeanceParticipant(this);
        seanceParticipant.evaluations = evaluations;
        return seanceParticipant;
    }

    findIndexEvaluation(evaluation: Evaluation): number {
        for (let index = 0; index < this.evaluations.length; index++) {
            if (this.evaluations[index].equals(evaluation)) return index;
        }
        return -1;
    }

    existsEvaluation(evaluation: Evaluation): boolean {
        return this.findIndexEvaluation(evaluation) > -1;
    }

    updateEvaluation(evaluation: Evaluation) {
        const seanceParticipant = new SeanceParticipant(this);
        if (this.existsEvaluation(evaluation)) {
            seanceParticipant.evaluations[this.findIndexEvaluation(evaluation)] = evaluation;
        }
        return seanceParticipant;
    }

    toCreate() {
        return {
            participant: this.participant.id,
            observateur_principal: this.observateur_principal
                ? { observateur: this.observateur_principal.observateur.id }
                : undefined,
            evaluations: this.evaluations.map((element) => element.toCreate()),
        };
    }

    getEvaluationForSeanceExercice(seanceExercice: SeanceExerciceInterface){
        for (let index = 0; index < this.evaluations.length; index++) {
            const evaluation = this.evaluations[index];
            if (evaluation.seance_exercice.equals(seanceExercice)) return evaluation;
        }
        return null;
    }
}
