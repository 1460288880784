import React from 'react';
import { Seance } from '../../../Models/Seance';
import { SeanceParticipant } from '../../../Models/SeanceParticipant';
import { EvaluationExtended, EvaluationInterface } from '../../../Models/Evaluation';
import { SeanceExercice } from '../../../Models/SeanceExercice';
import { Button } from 'react-bootstrap';

interface EvaluateButtonProps {
    show?: boolean;
    seance: Seance;
    seanceParticipant?: SeanceParticipant;
    evaluation?: EvaluationInterface;
    seanceExercice: SeanceExercice;
    currentUserId: number;
    evaluate?: (evalExt: EvaluationExtended) => void;
}

const EvaluateButton = ({
    show = true,
    seance,
    seanceParticipant,
    evaluation,
    currentUserId,
    seanceExercice,
    evaluate,
}: EvaluateButtonProps) => {
    // For when the user is a participant => evaluation object undefined
    if (!seanceParticipant) {
        seanceParticipant = seance.getSeanceParticipantfromParticipantId(currentUserId) ?? undefined;
        if (!evaluation) {
            evaluation = seanceParticipant?.getEvaluationForSeanceExercice(seanceExercice) ?? undefined;
        }
    }
    if (show) {
        if (seanceParticipant && evaluation) {
            const evaluationExtended = { ...evaluation, seance_participant: seanceParticipant };
            return (
                <Button
                    variant={evaluation.editable ? 'primary' : 'secondary'}
                    onClick={() => evaluate?.(evaluationExtended)}
                >
                    {evaluation.editable ? 'Évaluer' : 'Consulter'}
                </Button>
            );
        }
    } else if (evaluation) {
        return (
            <p className={evaluation.editable ? 'text-primary-cus' : 'text-secondary-cus'}>
                {evaluation.editable ? 'En attente' : 'Terminé'}
            </p>
        );
    }
    return null;
};

export default EvaluateButton;
