import React from 'react';

export function getSvgCoords(svgElement: SVGSVGElement) {
    const matrix = getOrCreateTranslate(svgElement).matrix;

    return {
        x: matrix.e,
        y: matrix.f,
    };
}

export function setSVGCoords(svgElement: SVGSVGElement, x: number, y: number) {
    getOrCreateTranslate(svgElement).setTranslate(x, y);
}

export function getMousePosition(evt: React.MouseEvent, svgElement: SVGElement) {
    const CTM = (svgElement.closest('svg') as SVGGraphicsElement).getScreenCTM();

    if (!CTM) return { x: 0, y: 0 };

    return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d,
    };
}

export function getOrCreateTranslate(svgElement: SVGSVGElement): SVGTransform {
    const transforms = svgElement.transform.baseVal;
    if (!transforms.numberOfItems || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
        const translate = svgElement.createSVGTransform();
        translate.setTranslate(0, 0);
        transforms.insertItemBefore(translate, 0);
    }

    return transforms.getItem(0);
}

export function preventDefault(evt: WheelEvent) {
    evt.preventDefault();
    evt.stopPropagation();
}
