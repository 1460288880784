import { Seance } from '../../../Models/Seance';
import { Resource, EventObject } from '../../Timeline/interface';
import { ExerciceColors, ExtendedEvaluationInterface } from './interface';
import { colors, timelineConfig } from './constants';
import { getDateWithSpecificTime, getDayLength } from '../../Timeline/functions';
import moment, { Moment } from 'moment';
import { SeanceExercice } from '../../../Models/SeanceExercice';
import { SeanceParticipant } from '../../../Models/SeanceParticipant';
import { SeanceObservateur } from '../../../Models/SeanceObservateur';
import { Participant } from '../../../Models/Participant';
import { Evaluation } from '../../../Models/Evaluation';

export function getResources(seance: Seance): Resource[] {
    return [...seance.seance_participants, null, ...seance.seance_observateurs].map((observateurOrParticipant: any) => {
        if (observateurOrParticipant === null) return { id: '', title: '' };

        const { user } = observateurOrParticipant.observateur ?? observateurOrParticipant.participant;
        return {
            id: user.id,
            title: `${user.first_name} ${user.last_name}`,
        } as Resource;
    });
}

export function getUserName({ first_name, last_name }: { first_name?: string; last_name?: string }): string {
    return `${first_name} ${last_name}`;
}

export function getExercicesById(
    seanceParticipants: SeanceParticipant[],
    evaluationId: number | string | null
): ExtendedEvaluationInterface | null {
    for (const seanceParticipant of seanceParticipants) {
        const participant = seanceParticipant.participant;

        for (const evaluation of seanceParticipant.evaluations) {
            if (evaluation.id === evaluationId) {
                return {
                    ...evaluation,
                    participant,
                };
            }
        }
    }

    return null;
}

function getAllParticipantsId(evaluation: Evaluation, participant: Participant) {
    const participantId = participant.user.id;
    const assessorId = evaluation.assessor?.observateur?.user?.id;
    const assistantId = evaluation.assistant?.observateur?.user?.id;

    return { participantId, assessorId, assistantId };
}

export function getColors(exercices: SeanceExercice[]) {
    return exercices.reduce((acc, { exercice: { name } }, i) => {
        return {
            ...acc,
            [name]: colors[i % colors.length],
        };
    }, {} as ExerciceColors);
}

export function getOriginTime(minTime: number): Moment {
    return minTime ? moment(minTime * 1000).add(-5, 'minutes') : getDateWithSpecificTime(moment(), 8, 45);
}

export function getPlanningDayLength(minTime: number, maxTime: number): number {
    return minTime && maxTime
        ? getDayLength(minTime, maxTime + 10 * 60, timelineConfig.viewPortWidth - 15)
        : timelineConfig.dayLength;
}

export function getEvents(
    seance: Seance,
    colors: ExerciceColors
): { events: EventObject[]; minTime: number; maxTime: number } {
    let minTime = NaN;
    let maxTime = NaN;

    const events = seance.seance_participants.reduce((acc, { evaluations, participant }) => {
        return [
            ...acc,
            ...evaluations
                .filter(({ seance_exercice: { exercice } }) => exercice.action_time !== 0)
                .reduce((acc, evaluation) => {
                    const seance_exercice = evaluation.seance_exercice;
                    const exercice = seance_exercice.exercice;
                    const exerciceName = exercice.name;

                    const startTime = evaluation.start_time.unix();
                    // preparation_time and action_time are in minutes
                    const endTime = evaluation.end_time.unix();

                    if (!minTime) minTime = startTime;
                    else minTime = Math.min(startTime, minTime);

                    if (!maxTime) maxTime = endTime;
                    else maxTime = Math.max(endTime, maxTime);

                    const sharedExerciceInfo = {
                        id: evaluation.id,
                        title: exerciceName,
                        startTime,
                        endTime,
                        bgColor: colors[exerciceName] ?? 'white',
                        happened: !evaluation.editable,
                    };

                    const { participantId, assessorId, assistantId } = getAllParticipantsId(evaluation, participant);

                    const newItems = [
                        ...acc,
                        {
                            resource: participantId,
                            subtitle: 'Participant',
                            ...sharedExerciceInfo,
                        },
                    ];

                    if (assessorId) {
                        newItems.push({
                            resource: assessorId,
                            subtitle: 'Observateur',
                            ...sharedExerciceInfo,
                        });
                    }

                    if (assistantId) {
                        newItems.push({
                            resource: assistantId,
                            subtitle: 'Cascadeur',
                            ...sharedExerciceInfo,
                        });
                    }

                    return newItems;
                }, [] as EventObject[]),
        ];
    }, [] as EventObject[]);

    return { events, minTime, maxTime };
}

export function getObservateurById(observateurs: SeanceObservateur[], id: number) {
    return observateurs.find((value) => value.id === id);
}
