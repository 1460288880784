import { ApiDescription, StringObject } from './interfaces';
import { isArray } from 'util';

/**
 * Interpolates a parameterized string with the properties of params
 * For instance, with the parameters :
 * - string = /api/${foo}/id/${bar}
 * - params = {
 *      foo: foo1,
 *      bar: bar1
 *   }
 *  It will return: /api/foo1/id/bar1
 *
 * @param {string} string
 * @param {object} params
 * @returns {string}
 */
export const interpolate = (string: string, params: object): string => {
    const names = Object.keys(params);
    const values = Object.values(params);

    return new Function(...names, `return \`${string}\`;`)(...values);
};

export const extractParameters = (description: ApiDescription, parameters: StringObject, location: string) => {
    return (
        Object.keys(parameters)
            // return only the keys of `parameters` matching the condition
            .filter((param) => param in description.parameters && description.parameters[param].in === location)
            // constructs a new object with the  satisfying keys
            .reduce((obj, key) => {
                obj[key] = parameters[key];
                return obj;
            }, {} as StringObject)
    );
};

export const createQueryUrl = (params: StringObject): string => {
    let queryUrl = Object.keys(params)
        .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    if (queryUrl.length) queryUrl = '?' + queryUrl;
    return queryUrl;
};

export const addErrorDetails = (message: string, errorData: Object | Array<any>): string => {
    let detail = message;

    if (errorData) {
        try {
            detail = `${message}\n${createErrorDetails(errorData)}`;
        } catch (error) {
            console.log('Failed to create error details');
        }
    }
    return detail;
};

const createErrorDetails = (errorData: any) => {
    if (Array.isArray(errorData)) {
        return flattenArray(errorData).join('\n');
    } else if (typeof errorData == 'object') {
        return Object.keys(errorData)
            .map((k) => errorData[k])
            .join('\n');
    } else {
        return errorData;
    }
};

const flattenObject = (obj: any, parent?: any, res = {} as any): Object => {
    for (let key in obj) {
        let propName = parent ? parent + '_' + key : key;
        if (typeof obj[key] == 'object') {
            flattenObject(obj[key], propName, res);
        } else if (isArray(obj[key])) {
            res[propName] = flattenArray(obj[key]);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
};

const flattenArray = (arr: Array<any>): Array<any> => {
    let flattened = arr.flat(Infinity);
    return flattened.map((element) => {
        if (typeof element == 'object') {
            return flattenObject(element);
        } else {
            return element;
        }
    });
};

export const getFileFromBlob = (blob: Blob, contentDisposition: string | null) => {
    let fileName = 'file';
    if (contentDisposition) {
        fileName = contentDisposition.split('filename=')[1];
        fileName = fileName.split(';')[0];
    }
    return { file: URL.createObjectURL(blob), fileName };
};

export const documentContentTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
];
