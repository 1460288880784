import React from 'react';
import { Column } from 'material-table';
import { Row } from './interfaces';
import './SeanceSearch.css';

const UserCollection = ({ users }: { users: string[] }) => {
    return (
        <>
            {users.map((user, index) => (
                <div key={index}>{user}</div>
            ))}
        </>
    );
};

export const columns: Column<Row>[] = [
    { field: 'name', title: 'Séance' },
    {
        field: 'observateurs',
        title: 'Observateurs',
        sorting: false,
        render: (props) => <UserCollection users={props.observateurs} />,
    },
    {
        field: 'participants',
        title: 'Participants',
        sorting: false,
        render: (props) => <UserCollection users={props.participants} />,
    },
    { field: 'status', title: 'Statut', lookup: { 0: 'Prêt', 1: 'En cours', 2: 'Terminé' } },
    {
        field: 'created',
        title: 'Date de création',
        defaultSort: 'desc',
    },
];
