import moment, { Moment } from 'moment';
import { nbOfSecIn24h } from './constant';
import { EventObject } from './interface';

export function convertMomentInSecs(moment: number | Moment): number {
    return typeof moment === 'number' ? moment : moment.unix();
}

/**
 * Return the position where to place currentTime according to originTime and dayLength
 *
 * @param originTime moment or unix timestamp
 * @param currentTime moment or unix timestamp
 * @param dayLength number
 */
export function getXPosition(originTime: number | Moment, currentTime: number | Moment, dayLength: number): number {
    originTime = convertMomentInSecs(originTime);
    currentTime = convertMomentInSecs(currentTime);

    return ((currentTime - originTime) / nbOfSecIn24h) * dayLength;
}

export function getDayLength(originTime: number | Moment, currentTime: number | Moment, xPosition: number): number {
    originTime = convertMomentInSecs(originTime);
    currentTime = convertMomentInSecs(currentTime);

    return (xPosition * nbOfSecIn24h) / (currentTime - originTime);
}

export function momentIsBefore(m1: number | Moment, m2: number | Moment) {
    const m1Unix = convertMomentInSecs(m1);
    const m2Unix = convertMomentInSecs(m2);
    return m1Unix <= m2Unix;
}

export function getMidnightTime(time: Moment): Moment {
    return moment(time).startOf('day');
}

export function getDateWithSpecificTime(time: Moment, hous: number, minutes: number): Moment {
    return getMidnightTime(time).add(hous, 'hours').add(minutes, 'minutes');
}

export function createIntegerArrays(from: number, to: number): number[] {
    return to >= from ? Array.from(Array(to - from + 1).keys()).map((index) => index + from) : [];
}

export function getFontSize(length: number, text: string) {
    return length ? (1.8 * length) / text.length : 0;
}

export function getPosition(events: EventObject[], positions: number[], i: number): number {
    const iMin = i - 1 - (positions[i - 1] ?? 0);
    for (let j = iMin; j < i; j++) {
        if (events[j] && momentIsBefore(events[j].endTime, events[i].startTime)) {
            return positions[j];
        }
    }
    return positions[i - 1] !== undefined ? positions[i - 1] + 1 : 0;
}

export function getAllPositions(events: EventObject[]) {
    return events.reduce((acc: number[], _, index) => [...acc, getPosition(events, acc, index)], []);
}

export function sortEvents(events: EventObject[]): EventObject[] {
    return events.sort((evt1, evt2) => {
        const evt1StartTime = typeof evt1.startTime === 'number' ? evt1.startTime : evt1.startTime.unix();
        const evt2StartTime = typeof evt2.startTime === 'number' ? evt2.startTime : evt2.startTime.unix();
        return evt2StartTime > evt1StartTime ? -1 : 1;
    });
}
