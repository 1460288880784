import { Column } from 'material-table';
import { Observateur } from '../../../Models/Observateur';
import { Participant } from '../../../Models/Participant';
import { Exercice } from '../../../Models/Exercice';
import { SelectorType } from './interfaces';
import { userListDesc } from '../../../Repository/userListDesc';
import { exerciceListDesc } from '../../../Repository/exerciceListDesc';

const userSelectorColumns: Column<Observateur|Participant|Exercice>[] = [
    { field: 'user.first_name', title: 'Prénom' },
    { field: 'user.last_name', title: 'Nom' },
    { field: 'user.email', title: 'Adresse mail' },
];

const exerciceSelectorColumns: Column<Observateur|Participant|Exercice>[] = [
    { field: 'name', title: 'Nom' },
    // { field: 'is_autonomous', title: 'Autonome ?' },
];

export const getColumns = (selectorType: SelectorType) => {
    switch (selectorType) {
        case SelectorType.EXERCICE:
            return exerciceSelectorColumns;
        case SelectorType.OBSERVATEUR:
        case SelectorType.PARTICIPANT:
        default:
            return userSelectorColumns;
    }
}

export const convertToClassInstances = (selectorType: SelectorType, data : any[]) => {
    switch (selectorType) {
        case SelectorType.EXERCICE:
            return data.map((element)=>Exercice.fromJSON(element));
        case SelectorType.OBSERVATEUR:
            return data.map((element)=>Observateur.fromJSON(element));
        case SelectorType.PARTICIPANT:
        default:
            return data.map((element)=>Participant.fromJSON(element));
    }  
}

export const getEndpointDesc = (selectorType: SelectorType) => {
    switch (selectorType) {
        case SelectorType.EXERCICE:
            return exerciceListDesc;
        case SelectorType.OBSERVATEUR:
        case SelectorType.PARTICIPANT:
        default:
            return userListDesc;
    } 
}

export const getExtraFetchParams = (selectorType: SelectorType) => {
    switch (selectorType) {
        case SelectorType.EXERCICE:
            return {latest: true};
        case SelectorType.OBSERVATEUR:
            return {user_type: 'observateur'}
        case SelectorType.PARTICIPANT:
        default:
            return {user_type: 'participant'};
    } 
}

export const combineData = (selectedData: (Observateur|Participant|Exercice)[], fetchedData: (Observateur|Participant|Exercice)[]) => {
    const dataMap = new Map(fetchedData.map(i => [i.id, i]));
    selectedData.forEach(element => {
        dataMap.set(element.id, element);
    });
    return Array.from(dataMap.values());
}