import React from 'react';
import { Column } from 'material-table';
import { Exercice, ExerciceInterface } from '../../../Models/Exercice';
import { ExerciceListState } from './interfaces';
import ExerciceDocumentButtons from '../ExerciceDocumentButtons/ExerciceDocumentButtons';

export const initListState = (): ExerciceListState => {
    return {
        exercices: [],
        oldExercices: [],
    };
};

export const getColumns = (refreshExercises: () => void): Column<Exercice>[] => [
    { field: 'name', title: 'Nom' },
    { field: 'description', title: 'Description' },
    {
        field: 'is_autonomous',
        title: 'Autonome',
        editable: 'never',
        type: 'boolean',
    },
    {
        field: 'uses_assistant',
        title: 'Cascadeur',
        type: 'boolean',
    },
    {
        field: 'uses_document',
        title: 'Document',
        editable: 'never',
        type: 'boolean',
    },
    { field: 'preparation_time', title: 'Prép', type: 'numeric' },
    { field: 'action_time', title: 'Action', type: 'numeric' },
    {
        title: 'Modèle',
        render: (exercice) => {
            return <ExerciceDocumentButtons exercice={exercice} refreshExercises={refreshExercises} />;
        },
    },
];

export const convertToExercices = (data: any[]): Exercice[] => {
    return data.map((raw) => Exercice.fromJSON(raw));
};

export const convertToExercice = (data: ExerciceInterface): Exercice => {
    return new Exercice({
        ...data,
    });
};
