import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { globalStore } from '../../Redux/interfaces';
import './Company.css';
import { CREATE_OR_UPDATE } from '../../Redux/actionsTypes';
import { Loader } from '../../App';
import { Company as CompanyInterface } from '../../Models/Company';

export const Company = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: globalStore) => state.authentification);

    useEffect(() => {
        if (user.companies.length === 1) setCompany(user.companies[0]);
    });

    const setCompany = (company: CompanyInterface) => {
        dispatch({
            type: CREATE_OR_UPDATE,
            payload: { ...user, currentCompany: company },
        });
        history.push('/role');
    };

    switch (user.companies.length) {
        case 0:
            return <Loader />;
        case 1:
            return null;
        default:
            return (
                <div className="Company">
                    <div>À quelle entreprise voulez-vous vous connecter ?</div>
                    <div className="CompanyElements">
                        {user.companies.map((company) => (
                            <Button key={company.id} onClick={() => setCompany(company)}>
                                {company.name}
                            </Button>
                        ))}
                    </div>
                </div>
            );
    }
};
