import { Company, EmptyCompany } from './Company';
import { Authentification } from '../Redux/interfaces';

export interface User {
    id: number;
    email: string;
    last_login: string | null;
    is_superuser: boolean;
    is_staff: boolean;
    is_active: boolean;
    date_joined: string;
    first_name: string;
    last_name: string;
    gender: number;
    job_title: string;
    job_category: number;
    age_category: number;
    is_participant: boolean;
    is_observateur: boolean;
    is_admin: boolean;
    companies: Company[];
}

export interface UserShort {
    id: number;
    first_name: string;
    last_name: string;
}

export interface UserUpdate {
    email: string;
    first_name: string;
    last_name: string;
    companies: number[];
}

export interface UserPasswordUpdate {
    email: string;
    first_name: string;
    last_name: string;
    companies: number[];
    password: string;
}

export interface UserType {
    id: string;
    label: string;
}

export enum UserRole {
    ADMIN = 'admin',
    OBSERVATEUR = 'observateur',
    PARTICIPANT = 'participant',
}

export function getRoleFullName(user: UserRole): string {
    switch (user) {
        case UserRole.ADMIN:
            return 'administrateur';
        case UserRole.OBSERVATEUR:
            return 'observateur';
        case UserRole.PARTICIPANT:
            return 'participant';
    }
}

export function getUserRoles(user: User | Authentification): UserRole[] {
    const roles = [];
    if (user.is_admin) roles.push(UserRole.ADMIN);
    if (user.is_observateur) roles.push(UserRole.OBSERVATEUR);
    if (user.is_participant) roles.push(UserRole.PARTICIPANT);

    return roles;
}

export function getUserMainRole(user: User): UserRole | undefined {
    if (user.is_admin) return UserRole.ADMIN;
    if (user.is_observateur) return UserRole.OBSERVATEUR;
    if (user.is_participant) return UserRole.PARTICIPANT;
}

export const getUserFullName = (user: UserShort) => {
    return `${user.first_name} ${user.last_name}`;
};

export const EmptyUser: User = {
    id: 0,
    email: '',
    last_login: '',
    is_superuser: false,
    is_staff: false,
    is_active: true,
    date_joined: '',
    first_name: '',
    last_name: '',
    gender: 0,
    job_title: '',
    job_category: 0,
    age_category: 0,
    is_participant: false,
    is_observateur: false,
    is_admin: false,
    companies: [EmptyCompany],
};
