import React, { useEffect } from 'react';
import './Form.css';
import useForm from './useForm';
import { Button } from 'react-bootstrap';
import DynamicInput from '../DynamicInput/DynamicInput';
import { FormDescription, FormDictionary, InputDescription, OptionPair } from './interfaces';

export type FormProps = {
    className?: string;
    formContainerClassName?: string;
    buttonsContainerClassName?: string;
    submitLabel?: string;
    formArea?: string;
    description: FormDescription;
    initialValues: Object;
    submit: Function;
    dict: FormDictionary;
    showErrors?: boolean;
    setHandleReinit?: (handleReset: any) => void;
};

const Form: React.FunctionComponent<FormProps> = ({
    description,
    submit,
    initialValues,
    dict,
    setHandleReinit,
    className = 'DefaultForm',
    formContainerClassName = 'form-container',
    buttonsContainerClassName = 'buttons-container',
    submitLabel,
    showErrors = true,
}: FormProps) => {
    const { values, errors, submitted, handleChange, handleSubmit, handleReinit } = useForm(
        description,
        submit,
        initialValues,
        dict
    );

    useEffect(() => {
        setHandleReinit?.(handleReinit);
    });

    return (
        <form onSubmit={handleSubmit} className={className}>
            <div className={formContainerClassName}>
                {Object.keys(description).map((name) => {
                    const input = description[name];
                    const error = showErrors && submitted ? errors[name] : '';

                    return (
                        <div key={name} className={name}>
                            <div>{input.label + ' :'}</div>
                            <InputElement input={input} name={name} handleChange={handleChange} values={values} />
                            <div className="error">{error}</div>
                        </div>
                    );
                })}
            </div>
            <div className={buttonsContainerClassName}>
                <Button type="submit">{submitLabel || 'Valider'}</Button>
            </div>
        </form>
    );
};

interface InputElementInterface {
    input: InputDescription;
    name: string;
    handleChange: () => any;
    values: any;
}

const InputElement = ({ input, name, values, handleChange }: InputElementInterface) => {
    switch (input.type) {
        case 'select':
            return (
                <select
                    value={values[name]}
                    name={name}
                    onChange={handleChange}
                    required={input.required}
                    multiple={input.multiple}
                >
                    <Options options={input.options ?? []} />
                </select>
            );
        default:
            return (
                <DynamicInput
                    type={input.type}
                    name={name}
                    value={values[name] || ''}
                    onChange={handleChange}
                    required={input.required}
                    isDynamic={input.isDynamic}
                />
            );
    }
};

const Options = ({ options }: { options: OptionPair[] }) => {
    return (
        <>
            {options.map((option) => {
                return (
                    <option key={option.id} value={option.id}>
                        {option.label || option.name || ''}
                    </option>
                );
            })}
        </>
    );
};

export default Form;
