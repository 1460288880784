import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const exerciceDocumentDesc = {
    url: '/api/exercice/${exerciceId}/document',
    method: HTTP_METHODS.GET,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        exerciceId: {
            in: 'path',
            type: 'number',
            required: true,
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.DOCUMENT,
            success: true,
            message: 'Document téléchargé',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Document non trouvé',
        },
    },
} as ApiDescription;

export const downloadExerciceDocumentTemplateDesc = {
    url: '/api/exercice/${exerciceId}/document/template',
    method: HTTP_METHODS.GET,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        exerciceId: {
            in: 'path',
            type: 'number',
            required: true,
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.DOCUMENT,
            success: true,
            message: 'Document téléchargé',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Document non trouvé',
        },
    },
} as ApiDescription;

export const uploadExerciceDocumentTemplateDesc = {
    url: '/api/exercice/${exerciceId}/document/template',
    method: HTTP_METHODS.POST,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        exerciceId: {
            in: 'path',
            type: 'number',
            required: true,
        },
    },
    requestBody: {
        required: true,
        content: {
            'multipart/form-data': {},
        },
    },
    responses: {
        201: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Template chargé',
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Erreur lors du chargement',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Document non trouvé',
        },
        415: {
            content: DATA_TYPE.JSON,
            success: false,
            message: "Ce type de fichier n'est pas accepté",
        },
    },
} as ApiDescription;

export const deleteExerciceDocumentTemplateDesc = {
    url: '/api/exercice/${exerciceId}/document/template',
    method: HTTP_METHODS.DELETE,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        exerciceId: {
            in: 'path',
            type: 'number',
            required: true,
        },
    },
    responses: {
        204: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Template supprimé',
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Erreur lors du chargement',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Document non trouvé',
        },
    },
} as ApiDescription;
