import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const createUserDesc = {
    url: '/api/${userType}/',
    method: HTTP_METHODS.POST,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
        userType: {
            in: PARAM_LOCATION.PATH,
            type: 'string',
            required: true,
        }
    },
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        201: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Utilisateur créé',
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Erreur lors du chargement',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;
