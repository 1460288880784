export interface DateInterface {
    date: Date;
}

export class DateWrapper implements DateInterface{
    date: Date;

    constructor(date: DateInterface) {
        this.date = date.date;
    }

    static fromString(date: string): DateWrapper {
        return new this({
            date: new Date(date)
        });
    }

    static deepCopy(date: DateWrapper): DateWrapper {
        return new this({
            date: new Date(date.date)
        })
    }

    equals(date: DateInterface): boolean {
        return this.date === date.date;
    }

    addMinutes(minutes: number) {
        this.date.setMinutes(this.date.getMinutes() + minutes);
    }

    getDateString(): string {
        return `${this.date.getFullYear()}/${this.date.getMonth()+1}/${this.date.getDate()}`;
    }

    getHours(): string {
        let hours = this.date.getHours().toString();
        if (hours.length<2) hours = `0${hours}`
        return hours;
    }

    getMinutes(): string {
        let minutes = this.date.getHours().toString();
        if (minutes.length<2) minutes = `0${minutes}`
        return minutes;
    }

    getTimeString(): string {
        return `${this.getHours()}h${this.getMinutes()}`;
    }

    toDateTimeString(): string {
        return `${this.getDateString()} ${this.getTimeString()}`;
    }
}
