import React, { useState } from 'react';
import { getUserName, getObservateurById } from './functions';
import { EvaluationBoxProps, Position, EvaluationTime, ExtendedEvaluationInterface } from './interface';
import { DropdownArea } from './DropdownArea';
import { DateTimePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';

export const EvaluationBox = ({ editable, observateurs, evaluation, closeBox, updateEval }: EvaluationBoxProps) => {

    const [currentEval, setCurrentEval] = useState(evaluation);
    
    if (!currentEval) return null;

    const handleUpdateEval = (newEval: ExtendedEvaluationInterface) => {
        updateEval(newEval);
        setCurrentEval(newEval);
    }

    const exercice = currentEval.seance_exercice.exercice;
    const assistant = currentEval.assistant?.observateur?.user;
    const assessor = currentEval.assessor?.observateur?.user;
    const participant = currentEval.participant.user;
    const allObservateurs = observateurs.map(({ observateur: { user } }) => {
        return { id: user.id, name: getUserName(user) };
    });

    const onPositionChange = (position: Position, id: number) => {
        let newEval = { ...currentEval };
        let newPerson = getObservateurById(observateurs, id);
        if (newPerson) {
            switch (position) {
                case Position.ASSESOR:
                    newEval.assessor = newPerson;
                    break;
                case Position.ASSISTANT:
                    newEval.assistant = newPerson;
                    break;
                default:
                    break;
            }
            handleUpdateEval(newEval);
        }
    };

    const onTimeChange = (evaluationTime: EvaluationTime, newTime: Moment) => {
        const newEval = { ...currentEval };
        switch (evaluationTime) {
            case EvaluationTime.STARTTIME:
                const duration = currentEval.end_time.unix() - currentEval.start_time.unix();
                newEval.start_time = newTime;
                newEval.end_time = moment(newTime).add(duration, 'seconds')
                break;
            case EvaluationTime.ENDTIME:
                newEval.end_time = newTime;
                break;
            default:
                break;
        }
        handleUpdateEval(newEval);
    };

    return (
        <div className="EvaluationContainer" onClick={closeBox}>
            <div className="EvaluationDate" onClick={(event) => event.stopPropagation()}>
                <div className="ExerciceName">{exercice.name}</div>
                <div className="EvaluationFields">
                    <div className="EvaluationFieldName">Participant</div>
                    <div>{getUserName(participant)}</div>
                    <div className="EvaluationFieldName">Observateur</div>
                    {assessor ? (
                        <DropdownArea
                            className="DropdownArea"
                            disabled={!editable}
                            selected={getUserName(assessor)}
                            items={allObservateurs}
                            onItemSelected={(id) => onPositionChange(Position.ASSESOR, Number(id))}
                        />
                    ) : (
                            <div>Aucun</div>
                        )}
                    <div className="EvaluationFieldName">Cascadeur</div>
                    {assistant ? (
                        <DropdownArea
                            className="DropdownArea"
                            disabled={!editable}
                            selected={getUserName(assistant)}
                            items={allObservateurs}
                            onItemSelected={(id) => onPositionChange(Position.ASSISTANT, Number(id))}
                        />
                    ) : (
                            <div>Aucun</div>
                        )}
                    <div className="EvaluationFieldName">Début</div>
                    <div>
                        <DateTimePicker
                            ampm={false}
                            disabled={!editable}
                            value={currentEval.start_time}
                            onChange={(date) => onTimeChange(EvaluationTime.STARTTIME, date as Moment)}
                        />
                    </div>
                    <div className="EvaluationFieldName">Fin</div>
                    <div>
                        <DateTimePicker
                            ampm={false}
                            disabled={!editable}
                            value={currentEval.end_time}
                            onChange={(date) => onTimeChange(EvaluationTime.ENDTIME, date as Moment)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
