import { UserShort, getUserFullName } from './User';

export interface ObservateurInterface {
    user: UserShort;
    id?: number;
}

export class Observateur implements ObservateurInterface {
    user: UserShort;
    id: number;

    constructor(observateur: ObservateurInterface) {
        this.user = observateur.user;
        this.id = observateur.user.id;
    }

    toString() {
        return getUserFullName(this.user);
    }

    equals(observateur: ObservateurInterface) {
        return observateur.user.id === this.user.id;
    }

    static fromJSON(data: ObservateurInterface): Observateur {
        return new this({...data});
    }
}
