import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const authGET = {
    url: '/api/auth/',
    method: HTTP_METHODS.GET,
    parameters: {
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Successful connection',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;

export const authPOST = {
    url: '/api/auth/',
    method: HTTP_METHODS.POST,
    parameters: {},
    requestBody: {
        required: true,
        content: {
            'application/json': {},
        },
    },
    responses: {
        200: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Successful connection',
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Identifiants invalides',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
} as ApiDescription;
