import React, { ChangeEvent, useState } from 'react';

interface Props {
    type: string,
    name: string,
    value: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    required: boolean,
    isDynamic?: boolean,
}

const DynamicInput = (props: Props) => {
    const [length, setLength] = useState(props.value.length);

    const style = {
        minWidth: '100%',
        width: `${length}ch`,
        textAlign: 'center',
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            setLength(event.target.value.length);
        }
        props.onChange(event);
    }

    
    return (
        <input
            type={props.type} name={props.name} value={props.value}
            onChange={props.isDynamic ? handleChange : props.onChange} 
            style={props.isDynamic ? style as any : undefined}>
        </input>
    )
}

export default DynamicInput;