import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { Person } from '@material-ui/icons';

import { HeaderProps } from './interfaces';
import { globalStore } from '../../Redux/interfaces';
import { clearAuthData } from '../Login/functions';
import { logOut, removeAllNotifications } from '../../Redux/actions';
import { getUserRoles } from '../../Models/User';
import './Header.css';

export const Header = (props: HeaderProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: globalStore) => state.authentification);

    const handleLogOut = () => {
        dispatch(logOut());
        history.push('/');
        clearAuthData();
    };

    const handleProfile = () => {
        history.push('/main/profile');
    };

    const changeRole = () => {
        history.push('/role');
    };

    const changeCompany = () => {
        history.push('/company');
    };

    const clearNotifications = () => {
        // console.log('toto');
        dispatch(removeAllNotifications());
    };

    const DisplayUser = () => {
        if (!user.logged_in) return null;

        return (
            <div className="float-right d-flex flex-row px-4 items-are-center-aligned content-is-center-justified">
                <p className="px-2 auto-margin">
                    {`${user.first_name} ${user.last_name} - ${user.currentCompany.name}`}
                </p>
                <Dropdown alignRight onToggle={clearNotifications}>
                    <Dropdown.Toggle className="d-flex items-are-center-aligned" id="user-dropdown">
                        <Person />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleLogOut}>Se déconnecter</Dropdown.Item>
                        {user.companies.length > 1 && <Dropdown.Item onClick={changeCompany}>Entreprise</Dropdown.Item>}
                        {getUserRoles(user).length > 1 && <Dropdown.Item onClick={changeRole}>Role</Dropdown.Item>}
                        <Dropdown.Item onClick={handleProfile}>Profil</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    return (
        <div id="header" className="border d-inline-block">
            <Link to="/">
                <img id="brand_logo" height="40" alt="ADAM" src={props.logo} className="d-inline-block" />
            </Link>
            <DisplayUser />
        </div>
    );
};
