import React from 'react';
import { DropdownButton, Dropdown } from "react-bootstrap";

interface StringableObject {
    id: number,
    toString: () => string
}

interface DropDownProps {
    id: string,
    variant?: string,
    show?: boolean,
    editable?: boolean,
    title?: string,
    options: StringableObject[],
    onSelect?: (option: any) => void,
}

const CustomDropDown = ({
    id, variant = "light", show = true, editable = false,
    title = "Choisir", options, onSelect
}: DropDownProps) => {
    if (show) {
        return (
            <DropdownButton
                disabled={!editable}
                id={id}
                title={title}
                variant={variant}
            >
                {options.map((option) => (
                    <Dropdown.Item
                        key={option.id}
                        onSelect={() => { if (onSelect) onSelect(option); }}
                    >
                        {option.toString()}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        )
    } else {
        return null;
    }
}

export default CustomDropDown;