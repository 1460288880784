import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { globalStore } from '../../../Redux/interfaces';
import { generateOrderBy } from '../../Table/functions';
import { STATUS } from "../../Fetch/interfaces";
import { asyncFetch } from "../../Fetch/useFetch";
import { Table } from '../../Table/Table';
import { Observateur } from '../../../Models/Observateur';
import { Participant } from '../../../Models/Participant';
import { Exercice } from '../../../Models/Exercice';
import * as interfaces from './interfaces';
import * as functions from './functions';

const UserExerciceSelector = (props: interfaces.UserExerciceSelectorProps) => {
    const auth = useSelector((state: globalStore) => state.authentification);
    const defaultParams = { 
        Authorization: `Token ${auth.token}`,
        'X-Company': auth.currentCompany.id
    };
    const [selected, setSelected] = useState([] as any[]);

    const fetchData = (query: any) =>
        new Promise((resolve) => {
            const params = {
                page_size: query.pageSize,
                page: query.page + 1,
                search: query.search,
                ordering: generateOrderBy(query.orderBy, query.orderDirection),
                ...functions.getExtraFetchParams(props.selectorType),
                ...defaultParams
            };
            asyncFetch(functions.getEndpointDesc(props.selectorType), params).then((res) => {
                const { status, data: result } = res;
                if (status === STATUS.SUCCESS) {
                    const { page, results, count: totalCount } = result as any;
                    const data = functions.combineData(selected, functions.convertToClassInstances(props.selectorType, results));
                    resolve({ data, page: page - 1, totalCount })
                } else resolve({ data: [], page: 0, totalCount: 0 })
            });
        });

    const handleSelectionChange = (rows: (Observateur | Participant | Exercice)[]) => {
        setSelected(rows);
        props.handleSelectionChange(rows);
    }

    const options = {
        selection: true,
        pageSize: props.selectorType === interfaces.SelectorType.EXERCICE ? 10 : 5
    }

    return (
        <Col>
            <Table<Observateur | Participant | Exercice>
                columns={functions.getColumns(props.selectorType)}
                title={props.title}
                fetchData={(query) => fetchData(query)}
                options={options}
                onSelectionChange={(rows) => { handleSelectionChange(rows) }}
            />
        </Col>
    );
};

export default UserExerciceSelector;
