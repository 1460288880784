import { UserRole } from '../Models/User';
import { EvaluationExtended } from '../Models/Evaluation';
import { Company } from '../Models/Company';

export interface Action {
    type: string;
    payload: any;
}

export interface globalStore {
    authentification: Authentification;
    userNotifications: UserNotification[];
    notification: UserNotification[];
    currentEvaluation: EvaluationExtended | null;
}

export interface Authentification {
    logged_in: boolean;
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    currentCompany: Company;
    companies: Company[];
    token: string;
    last_login: null | string;
    is_participant: boolean;
    is_observateur: boolean;
    is_admin: boolean;
    is_superuser: boolean;
    user_type: string;
    current_role?: UserRole;
}

export const UserType = {
    ADMIN: 'administrateur',
    OBSERVATEUR: 'observateur',
    PARTICIPANT: 'participant',
    SUPERADMIN: 'superadmin',
    NONE: 'none',
};

export interface UserNotification {
    isActive?: boolean;
    isPersistent?: boolean;
    type: string;
    message: string | JSX.Element;
    detail?: string;
}

export const NotificationType = {
    ERROR: 'error',
    WARNING: 'warning',
    NOTIFICATION: 'notification',
};

export const GenericNotificationMessages = {
    ERROR: 'Erreur !',
    WARNING: 'Attention !',
    NOTIFICATION: 'Notification',
};
