import React, { useEffect, useState } from 'react';
import { DraggableTimelineProps } from './interface';
import { DailyTimeline } from './DailyTimeline';
import { withDraggable } from '../SvgElement/DraggableElement/withDraggable';
import { createIntegerArrays, getMidnightTime, getXPosition } from './functions';
import { EventTimeline } from './EventTimeline';
import { DraggableComponentProps } from '../SvgElement/DraggableElement/interface';
import moment from 'moment';

const DraggableTimelineComponent = (props: DraggableTimelineProps & DraggableComponentProps) => {
    const { translate, viewPortWidth, resourceEvents, headerHeight, dayLength, originTime, eventHeight } = props;
    const dailyTimelineProps = { headerHeight, dayLength, originTime };
    const daysVisible = Math.floor(viewPortWidth / props.dayLength);

    const [minDay, setMinDay] = useState(-1);
    const [maxDay, setMaxDay] = useState(1);
    const [days, setDays] = useState<number[]>([]);

    useEffect(() => {
        if (props.reset) {
            props.resetPosition();
            props.onReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reset]);

    useEffect(() => {
        setDays(createIntegerArrays(minDay, maxDay));
    }, [minDay, maxDay]);

    useEffect(() => {
        const newMinDay = Math.floor(-translate.x / dayLength);
        const newMaxDay = newMinDay + daysVisible + 1;

        if (newMinDay < minDay) setMinDay(newMinDay);
        if (newMaxDay > maxDay) setMaxDay(newMaxDay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translate]);

    const nowXposition = getXPosition(originTime, moment(), dayLength);

    return (
        <>
            {days.map((day) => {
                const dayTime = getMidnightTime(originTime).add(day, 'day');
                const xPosition = getXPosition(originTime, dayTime, dayLength);
                return <DailyTimeline key={day} xPosition={xPosition} moment={dayTime} {...dailyTimelineProps} />;
            })}
            {resourceEvents.map(({ positions, resourceEvents, yPosition }) => {
                return resourceEvents.map((event, index) => {
                    const x = getXPosition(originTime, event.startTime, dayLength);
                    const y = yPosition + positions[index] * eventHeight;
                    const width = getXPosition(event.startTime, event.endTime, dayLength);
                    const eventTimelineProps = { ...event, x, y, width, height: eventHeight };
                    return <EventTimeline key={index} onEventClick={props.onEventClick} {...eventTimelineProps} />;
                });
            })}
            <line
                x1={nowXposition}
                y1={0.75 * headerHeight}
                x2={nowXposition}
                y2="100%"
                stroke="red"
                strokeWidth=".2"
            />
        </>
    );
};

export const DraggableTimeline = withDraggable<DraggableTimelineProps>(DraggableTimelineComponent, {
    verticalDragEnabled: false,
});
