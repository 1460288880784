export const uploadFilesDesc = [
    {
        name: 'items',
        description: 'Nouveau fichier de référentiel',
        info: 'Le fichier doit être un fichier Excel',
    },
    {
        name: 'exercices',
        description: "Nouveau fichier d'exercices",
        info: 'Le fichier doit être un fichier Excel',
    },
    {
        name: 'egogramme',
        description: "Nouveau fichier d'egogramme",
        info: 'Le fichier doit être un fichier Excel',
    },
    {
        name: 'report_template',
        description: 'Nouveau modèle de rapports',
        info: 'Le fichier doit être un fichier Word',
    },
];
