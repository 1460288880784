export enum HTTP_METHODS {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export enum PARAM_LOCATION {
    PATH = 'path',
    QUERY = 'query',
    HEADER = 'header',
    COOKIE = 'cookie',
}

export enum DATA_TYPE {
    TEXT = 'text/html; charset=utf-8',
    JSON = 'application/json',
    FORM_DATA = 'multipart/form-data',
    DOCUMENT = 'application/vnd.ms-excel',
}

export enum STATUS {
    INIT = 'init',
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
}

export interface StringObject {
    [key: string]: string | number;
}

export interface HttpParameter {
    in: PARAM_LOCATION;
    type: any;
    required?: boolean;
    description?: string;
}

export interface HttpResponse {
    content?: DATA_TYPE;
    success: boolean;
    message: string;
}

export interface ApiDescription {
    url: string;
    method: HTTP_METHODS;
    parameters: {
        [name: string]: HttpParameter;
    };
    requestBody?: {
        required?: boolean;
        content: {
            [bodyType: string]: object;
        };
    };
    responses: {
        [code: number]: HttpResponse;
    };
}
