import React, { useEffect, useState } from 'react';
import { EventTimelineProps } from './interface';
import { getFontSize } from './functions';

export const EventTimeline = (props: EventTimelineProps) => {
    const { x, width, bgColor = 'white', title, subtitle, onEventClick, margin = 0.1 } = props;
    const y = props.y + margin * props.height;
    const height = (1 - margin * 2) * props.height;

    const rotate = height > width;
    const xText = x + width / 2;
    const yTitle = !rotate && subtitle ? y + height / 3 : y + height / 2;
    const ySubtitle = y + (4 * height) / 5;

    const [titleFontSize, setTitleFontSize] = useState(0);
    const [subtitleFontSize, setSubtitleFontSize] = useState(0);

    useEffect(() => {
        if (rotate) {
            setTitleFontSize(getFontSize(height, title));
        } else {
            const newTitleFontSize = Math.min(height / 3, getFontSize(width, title));
            setTitleFontSize(newTitleFontSize);
            if (subtitle) setSubtitleFontSize(Math.min(newTitleFontSize * 0.8, getFontSize(width, subtitle)));
        }
    }, [width, height, rotate, title, subtitle]);

    const dimensions = { x, y, width, height };

    const handleClick = () => onEventClick?.(props.id);

    return (
        <g className={`Event${props.happened ? " happened" : ''}`} style={{ cursor: 'pointer' }} onClick={handleClick}>
            <rect
                {...dimensions}
                fill={bgColor}
                style={{ stroke: 'black', strokeWidth: '.01em', boxSizing: 'border-box' }}
            />
            <g transform={rotate ? `rotate(-90, ${xText}, ${yTitle})` : ''}>
                <text x={xText} y={yTitle} fontWeight="bold" fontSize={titleFontSize}>
                    {title}
                </text>
            </g>
            {subtitle && !rotate && (
                <text x={xText} y={ySubtitle} fontSize={subtitleFontSize}>
                    {subtitle}
                </text>
            )}
        </g>
    );
};
