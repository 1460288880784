import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const exerciceListDesc: ApiDescription = {
    url: '/api/exercice/',
    method: HTTP_METHODS.GET,
    parameters: {
        page_size: {
            in: PARAM_LOCATION.QUERY,
            type: 'number',
            required: false,
        },
        page: {
            in: PARAM_LOCATION.QUERY,
            type: 'number',
            required: false,
        },
        search: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
        latest: {
            in: PARAM_LOCATION.QUERY,
            type: 'string',
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    responses: {
        200: {
            success: true,
            message: 'Data fetched',
            content: DATA_TYPE.JSON,
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
    },
};
