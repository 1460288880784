import React from 'react';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { Table } from '../../Table/Table';
import { STATUS } from "../../Fetch/interfaces";
import { asyncFetch } from "../../Fetch/useFetch";
import { globalStore } from "../../../Redux/interfaces";
import { Row } from "./interfaces";
import { seanceListDesc, seanceDeleteDesc } from "../../../Repository/seanceDesc";
import { UserShort } from "../../../Models/User";
import { Seance } from "../../../Models/Seance";
import { columns } from "./constants";
import { DateWrapper } from '../../../Models/Date';
import { generateOrderBy } from '../../Table/functions';
import { useRepository } from '../../../Repository/useRepository';


export const SeanceSearch = () => {
    const history = useHistory();
    const auth = useSelector((state: globalStore) => state.authentification);
    const [, , , deleteSeance] = useRepository(seanceDeleteDesc, (_data) => { },
        { notify: true, notifyOnSuccess: true }
    );
    const defaultParams = {
        Authorization: `Token ${auth.token}`,
        'X-Company': auth.currentCompany.id
    };

    const convertUsersToString = (users: UserShort[]): string[] => {
        return users.map(user => `${user.first_name} ${user.last_name}`);
    };

    const convertUsersToRows = (data: Seance[]): Row[] => {

        return data.map(seance => {

            const name = `Séance n°${seance.id}`;
            const observateursShort = seance.seance_observateurs.map(seance_obs => seance_obs.observateur.user) as UserShort[]
            const participantsShort = seance.seance_participants.map(seance_participant => seance_participant.participant.user)
            const observateurs = convertUsersToString(observateursShort);
            const participants = convertUsersToString(participantsShort);
            const created = DateWrapper.fromString(seance.created).toDateTimeString();

            return {
                ...seance,
                name,
                participants,
                observateurs,
                created,
            }
        });
    };

    const fetchData = (query: any) =>
        new Promise((resolve) => {
            const params = {
                page_size: query.pageSize,
                page: query.page + 1,
                search: query.search,
                ordering: generateOrderBy(query.orderBy, query.orderDirection),
                ...defaultParams
            };
            asyncFetch(seanceListDesc, params).then((res) => {
                const { status, data: result } = res;
                if (status === STATUS.SUCCESS) {
                    const { page, results, count: totalCount } = result as any;
                    const data = convertUsersToRows(results);
                    resolve({ data, page: page - 1, totalCount })
                } else resolve({ data: [], page: 0, totalCount: 0 })
            });
        });

    const onSeanceSelected = (_evt?: any, rowData?: Row) => {
        if (rowData) {
            if (rowData.status === 1) history.push(`/main/seance/${rowData.id}/participate`)
            else history.push(`/main/seance/${rowData.id}`)
        }
    }

    const deleteRow = (oldData: Row) =>
        new Promise(async (resolve) => {
            const params = {
                ...defaultParams,
                seanceId: oldData.id.toString()
            };
            deleteSeance(params);
            resolve();
        })

    return (
        <div className="UserSearch">
            <Table<Row>
                columns={columns}
                title="Séances"
                fetchData={fetchData}
                onRowClick={onSeanceSelected}
                onRowDelete={deleteRow}
                options={{
                    actionsColumnIndex: -1
                }}
            />
        </div>
    );
};
