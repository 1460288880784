import React from 'react';
import { Column, DetailPanel } from "material-table";
import { EvaluationInterface, Evaluation } from "../../../../Models/Evaluation";
import EvaluateButton from "../EvaluateButton";
import { Seance, SeanceStatus } from '../../../../Models/Seance';
import { Authentification } from '../../../../Redux/interfaces';
import { UserRole } from '../../../../Models/User';
import CustomDropDown from '../../../CustomDropDown/CustomDropDown';
import { SeanceExercice, SeanceExerciceInterface } from '../../../../Models/SeanceExercice';
import { DateTimePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import SeanceExerciceDetail from '../SeanceExerciceDetail/SeanceExerciceDetail';

const canEvaluate = (
    currentUser: Authentification,
    isParticipating: boolean,
    seanceExercice: SeanceExerciceInterface
) => {
    const isParticipant = currentUser.current_role === UserRole.PARTICIPANT;
    const isAutonomous = seanceExercice.exercice.is_autonomous;
    return isParticipating && isParticipant && isAutonomous;
}

export const getSeanceExoColumns = (
    seance: Seance, currentUser: Authentification, isParticipating: boolean,
    isEditable: boolean,
    evaluate: (evaluation: EvaluationInterface) => void,
    updateSeance: (seance: Seance) => void
): Column<SeanceExercice>[] => [
        { field: 'exercice.name', title: 'Exercice' },
        {
            field: 'observateur_incharge', title: 'Observateur en charge',
            render: (seanceExo) => <CustomDropDown id={'obs_incharge_options'}
                title={seanceExo.observateur_incharge?.toString()}
                show={!seanceExo.exercice.is_autonomous}
                editable={isEditable} options={seance.seance_observateurs}
                onSelect={(obs) => updateSeance(seance.updateSeanceExercice(seanceExo.setObservateurIncharge(obs)))} />
        },
        { field: 'exercice.preparation_time', title: 'Préparation', type: 'numeric' },
        { field: 'exercice.action_time', title: 'Action', type: 'numeric' },
        {
            field: '', title: 'Début', defaultSort: 'asc', hidden: seance.status!==SeanceStatus.CREATION,
            render: (seanceExo) => <DateTimePicker
                ampm={false}
                disabled={!isEditable}
                value={seance.getSeanceExerciceCommonTimes(seanceExo).startTime}
                onChange={(date) => {
                    updateSeance(seance.setSeanceExerciceCommonTimes(seanceExo, date as Moment, null))
                }}
            />,
            customSort: (SE1, SE2) => {
                return seance.getSeanceExerciceCommonTimes(SE1).startTime.diff(seance.getSeanceExerciceCommonTimes(SE2).startTime);
            }
        },
        {
            field: '', title: 'Fin', hidden: seance.status!==SeanceStatus.CREATION,
            render: (seanceExo) => <DateTimePicker
                ampm={false}
                disabled={!isEditable}
                value={seance.getSeanceExerciceCommonTimes(seanceExo).endTime}
                onChange={(date) => {
                    updateSeance(seance.setSeanceExerciceCommonTimes(seanceExo, null, date as Moment))
                }}
            />,
            customSort: (SE1, SE2) => {
                return seance.getSeanceExerciceCommonTimes(SE1).endTime.diff(seance.getSeanceExerciceCommonTimes(SE2).endTime);
            }
        },
        {
            field: 'evaluation', title: 'Évaluation', hidden: !isParticipating, sorting: false,
            render: (seanceExo) => <EvaluateButton
                show={canEvaluate(currentUser, isParticipating, seanceExo)}
                seance={seance} seanceExercice={seanceExo}
                currentUserId={currentUser.id}
                evaluate={(evaluation: EvaluationInterface) => { evaluate(evaluation) }}
            />
        }
    ];

export const getSeanceExoDetailPanels = (seance: Seance): DetailPanel<SeanceExercice>[] => [
    {
        render: (seanceExercice) => {
            return <SeanceExerciceDetail seanceExercice={seanceExercice} seance={seance}/>;
        }
    }
]

export const filterAutonomous = (evals: Evaluation[], keepAutonomous: boolean = false) => {
    return evals.filter((value) => value.seance_exercice.exercice.is_autonomous === keepAutonomous)
}