import React from 'react';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Props, UserInfoNotificationMessageProps } from './interfaces';
import { globalStore, Authentification, NotificationType } from '../../Redux/interfaces';
import Form from '../Form/Form';
import { FormType } from '../Form/type';
import { FORM_DICT } from '../Form/constant';
import { useHistory } from 'react-router-dom';
import { ApiDescription } from '../Fetch/interfaces';
import { useRepository } from '../../Repository/useRepository';
import { authPOST } from '../../Repository/loginDesc';
import { persistAuthData } from './functions';
import { FormDescription, StringObject } from '../Form/interfaces';
import { pushNotification, removeAllNotifications } from '../../Redux/actions';
import { CREATE_OR_UPDATE } from '../../Redux/actionsTypes';

const UserInfoNotificationMessage = ({ user, goToMyProfile }: UserInfoNotificationMessageProps) => {
    return (
        <>
            Bonjour {user.first_name} {user.last_name}, <br />
            Rendez-vous sur l'onglet <a onClick={goToMyProfile}>Mon Profil</a> pour mettre à jours vos informations.
        </>
    );
};

const Login = (props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [, , , authenticate] = useRepository(authPOST as ApiDescription, (user: Authentification) => {
        if (user.last_login == null) {
            dispatch(
                pushNotification({
                    isActive: true,
                    type: NotificationType.NOTIFICATION,
                    message: <UserInfoNotificationMessage user={user} goToMyProfile={goToMyProfile} />,
                })
            );
            dispatch({
                type: CREATE_OR_UPDATE,
                payload: { ...user, last_login: '' },
            });
        }

        user.currentCompany = user.companies[0] ?? { id: 0, name: '' };
        props.logIn(user);
        persistAuthData(user);
        history.replace('/company');
    });

    const goToMyProfile = () => {
        history.push('/main/profile');
        dispatch(removeAllNotifications());
    };

    const loginFormDescription: FormDescription = {
        username: {
            label: 'Adresse mail',
            type: 'text',
            required: true,
            validator: FormType.EMAIL,
            isDynamic: true,
        },
        password: {
            label: 'Mot de passe',
            type: 'password',
            required: true,
            validator: FormType.PASSWORD,
            lengthMin: 1,
        },
    };

    const initialValues: StringObject = {
        username: '',
        password: '',
    };

    return (
        <div className="d-flex flex-column text-center border rounded p-4">
            <p>Connectez-vous pour commencer</p>
            <Form
                description={loginFormDescription}
                initialValues={initialValues}
                dict={FORM_DICT}
                submitLabel="Se connecter"
                submit={(values: StringObject) => authenticate({ body: values })}
            />
            <a href="/api/accounts/password_reset/">Mot de passe oublié</a>
        </div>
    );
};

const mapStateToProps = (state: globalStore) => {
    return {
        authentification: state.authentification,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logIn: (value: Authentification) => {
            dispatch({
                type: CREATE_OR_UPDATE,
                payload: value,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
