import { Authentification } from "../../../Redux/interfaces";
import { SeanceParticipant } from "../../../Models/SeanceParticipant";
import { UserRole } from "../../../Models/User";
import { Seance, SeanceStatus } from "../../../Models/Seance";
import { STATUS } from "../../Fetch/interfaces";

export const canAccessReport = (
    currentUser: Authentification,
    seance: Seance,
    sP?: SeanceParticipant
) => {
    if (!sP) {
        sP = seance.getSeanceParticipantfromParticipantId(currentUser.id) ?? undefined;
    }
    if (sP) {
        return (
            (seance.status===SeanceStatus.FINISHED) &&
            (
                (
                    (currentUser.current_role === UserRole.OBSERVATEUR) &&
                    (currentUser.id === sP.observateur_principal?.observateur.id)
                )
                ||
                (
                    (currentUser.current_role === UserRole.PARTICIPANT) &&
                    (currentUser.id === sP.participant.id) &&
                    (sP.available_report)
                )
            )
        );
    }
    return false;
}

export const canUploadReport = (
    currentUser: Authentification,
    seance: Seance,
    sP?: SeanceParticipant
) => {
    if (!sP) {
        sP = seance.getSeanceParticipantfromParticipantId(currentUser.id) ?? undefined;
    }
    return (
        (currentUser.current_role === UserRole.OBSERVATEUR) &&
        (currentUser.id === sP?.observateur_principal?.observateur.id)
    );
}

export const canDownloadReport = (
    currentUser: Authentification,
    seance: Seance,
    sP?: SeanceParticipant
) => {
    if (!sP) {
        sP = seance.getSeanceParticipantfromParticipantId(currentUser.id) ?? undefined;
    }
    if (sP) {
        return (
            (sP.available_report) &&
            (
                (
                    (currentUser.current_role === UserRole.OBSERVATEUR) &&
                    (currentUser.id === sP.observateur_principal?.observateur.id)
                )
                ||
                (
                    (currentUser.current_role === UserRole.PARTICIPANT) &&
                    (currentUser.id === sP.participant.id)
                )
            )
        );
    }
    return false;

}

export const downloadFile = (file: { file?: string, fileName?: string }, status: STATUS) => {
    if (status === STATUS.SUCCESS && file.file && file.fileName) {
        const a = document.createElement("a");
        a.href = file.file;
        a.setAttribute("download", file.fileName);
        a.click();
    }
}

export const getDownloadParams = (seance: Seance, currentUser: Authentification, sP?: SeanceParticipant) => {
    if (!sP) {
        sP = seance.getSeanceParticipantfromParticipantId(currentUser.id) ?? undefined;
    }
    if (sP) {
        return { seanceParticipantId: sP.id };
    }
    return undefined;
}