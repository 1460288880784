import { Exercice } from './Exercice';
import { SeanceObservateur } from './SeanceObservateur';

export interface SeanceExerciceInterface {
    id: number;
    seance: number;
    exercice: Exercice;
    observateur_incharge?: SeanceObservateur;
    available_report: boolean;
}

export class SeanceExercice implements SeanceExerciceInterface {
    id: number;
    seance: number;
    exercice: Exercice;
    observateur_incharge?: SeanceObservateur;
    available_report: boolean;

    constructor(seanceExercice: SeanceExerciceInterface) {
        this.id = seanceExercice.id;
        this.seance = seanceExercice.seance;
        this.exercice = seanceExercice.exercice;
        this.observateur_incharge = seanceExercice.observateur_incharge;
        this.available_report = seanceExercice.available_report;
    }

    static fromJSON(data: SeanceExerciceInterface): SeanceExercice {
        return new this({
            ...data,
            exercice: Exercice.fromJSON(data.exercice),
            observateur_incharge: data.observateur_incharge
                ? SeanceObservateur.fromJSON(data.observateur_incharge)
                : undefined,
        });
    }

    equals(seanceExercice: SeanceExerciceInterface) {
        return (
            seanceExercice.id === this.id &&
            seanceExercice.exercice.id === this.exercice.id &&
            seanceExercice.seance === this.seance
        );
    }

    setObservateurIncharge(observateur_incharge: SeanceObservateur) {
        const seanceExercice = new SeanceExercice(this);
        seanceExercice.observateur_incharge = observateur_incharge;
        return seanceExercice;
    }

    toString() {
        return this.exercice.toString();
    }

    toCreate() {
        return {
            observateur_incharge: this.observateur_incharge
                ? { observateur: this.observateur_incharge.observateur.id }
                : undefined,
            exercice: this.exercice.id
        };
    }
}
