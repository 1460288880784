import { UserShort, getUserFullName } from './User';

export interface ParticipantInterface {
    user: UserShort;
    id?: number;
}

export class Participant implements ParticipantInterface {
    user: UserShort;
    id: number;

    constructor(participant: ParticipantInterface) {
        this.user = participant.user;
        this.id = participant.user.id;
    }

    toString() {
        return getUserFullName(this.user);
    }

    equals(participant: ParticipantInterface) {
        return participant.user.id === this.user.id;
    }

    static fromJSON(data: ParticipantInterface): Participant {
        return new this({...data});
    }
}
