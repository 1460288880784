import moment from 'moment';
import { Resource } from '../../Timeline/interface';

export const timelineConfig = {
    viewPortWidth: 100,
    dayLength: 250,
    headerHeight: 10,
    eventHeight: 6,
    // originTime: getDateWithSpecificTime(moment(), 8, 45),
};

export const evts = [
    {
        id: 1,
        resource: 1,
        title: 'In Basket',
        subtitle: 'Sadsitha',
        startTime: moment(),
        endTime: moment().add(90, 'minutes'),
        bgColor: '#27ae60',
    },
    {
        id: 2,
        resource: 1,
        title: 'CR Ecrit',
        startTime: moment().add(95, 'minutes'),
        endTime: moment().add(115, 'minutes'),
        bgColor: '#2980b9',
    },
    {
        id: 3,
        resource: 1,
        title: 'Entretien IB',
        startTime: moment().add(-20, 'minutes'),
        endTime: moment().add(-5, 'minutes'),
        bgColor: '#8e44ad',
    },
    {
        id: 4,
        resource: 2,
        title: 'In Basket',
        subtitle: 'Sadsitha',
        startTime: moment(),
        endTime: moment().add(90, 'minutes'),
        bgColor: '#27ae60',
    },
    {
        id: 5,
        resource: 2,
        title: 'CR Ecrit',
        startTime: moment().add(0, 'minutes'),
        endTime: moment().add(115, 'minutes'),
        bgColor: '#2980b9',
    },
    {
        id: 6,
        resource: 2,
        title: 'Entretien IB',
        startTime: moment().add(-20, 'minutes'),
        endTime: moment().add(5, 'minutes'),
        bgColor: '#8e44ad',
    },
    {
        id: 7,
        resource: 3,
        title: 'In Basket',
        startTime: moment(),
        endTime: moment().add(90, 'minutes'),
        bgColor: '#27ae60',
    },
    {
        id: 8,
        resource: 3,
        title: 'CR Ecrit',
        startTime: moment().add(70, 'minutes'),
        endTime: moment().add(115, 'minutes'),
        bgColor: '#2980b9',
    },
    {
        id: 9,
        resource: 3,
        title: 'Entretien IB',
        startTime: moment().add(-20, 'minutes'),
        endTime: moment().add(5, 'minutes'),
        bgColor: '#8e44ad',
    },
];

export const res: Resource[] = [
    { id: 1, title: 'Sadsitha' },
    { id: 2, title: 'Laurent' },
    { id: 3, title: 'Axel' },
];

export const colors = [
    '#27ae60',
    '#8e44ad',
    '#2980b9',
    '#e67e22',
    '#c0392b',
    '#f1c40f',
    '#d35400',
    '#16a085',
    '#3498db',
    '#bdc3c7',
    '#c0392b',
    '#9b59b6',
];
