import React, { useState, ReactElement } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SideBar from '../SideBar/SideBar';
import { useHistory } from 'react-router';

interface PageSkeletonProps {
    confirmMessage?: string;
    confirmBeforeRedirect?: boolean;
    children: ReactElement | ReactElement[];
}

export const PageSkeleton = (props: PageSkeletonProps) => {
    const history = useHistory();
    const [showConfirm, setShowConfirm] = useState(false);
    const [redirect, setRedirect] = useState<() => any>();

    const onClick = (redirect: () => () => any) => {
        if (!props.confirmBeforeRedirect) redirect()();
        else {
            setShowConfirm(true);
            setRedirect(redirect);
        }
    };

    const closeModal = () => {
        setShowConfirm(false);
    };

    const goBack = () => {
        return () => history.goBack();
    };

    const onConfirm = () => {
        closeModal();
        redirect?.();
    };

    return (
        <div className="fullWidth fullHeight d-flex">
            <SideBar onClick={onClick} />
            <div id="main-container">
                <Button id="BackButton" onClick={() => onClick(goBack)} variant="secondary">
                    <ArrowBackIcon />
                    <div>Retour</div>
                </Button>
                <Modal show={showConfirm} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Etes-vous sûr de vouloir quitter la page ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{props.confirmMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Annuler
                        </Button>
                        <Button onClick={onConfirm}>Confirmer</Button>
                    </Modal.Footer>
                </Modal>
                {props.children}
            </div>
        </div>
    );
};
