import { UploadState } from './interfaces';
import { StringObject } from '../../Form/interfaces';

export const initUploadState = () => {
    return {
        isUploading: false,
    } as UploadState;
};

export const UploadFileType: StringObject = {
    REFERENTIEL: 'referentiel',
    EXERCICES: 'exercices',
    EGOGRAMME: 'egogramme',
};

export const UploadFileTypeKeyMapping: StringObject = {
    referentiel: 'items',
    exercices: 'exercices',
    egogramme: 'egogramme',
};

export const UploadFileTypeUrlMapping: StringObject = {
    referentiel: '/api/evaluateditem/import',
    exercices: '/api/exercice/import',
    egogramme: '/api/exercice/egogramme/import',
};

export const uploadFile = (
    authToken: string,
    handleSuccess: () => void,
    handleErrors: (message: string, detail: string) => void,
    url: string,
    formData: FormData
) => {
    fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Token ${authToken}`,
        },
        body: formData,
    })
        .then((res) => {
            if (res.ok) {
                res.json().then(() => {
                    handleSuccess();
                });
            } else {
                if (res) {
                    res.json()
                        .then((result) => {
                            handleErrors('Erreur lors du chargement', JSON.stringify(result, null, 1));
                        })
                        .catch(() => {
                            handleErrors('Erreur lors du chargement', 'Erreur non gérée');
                        });
                }
            }
        })
        .catch(() => {
            handleErrors('Erreur lors du chargement', 'Erreur non gérée');
        });
};
