import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { SeanceStatus } from '../../../../Models/Seance';
import * as interfaces from './interfaces';
import * as functions from './functions';


const SeanceExerciceDetail = (props: interfaces.SeanceExerciceDetailProps) => {

    return (
        <Container className="border"><Row>
            <Col>
                <p><strong>Description : </strong></p>
                <p>{props.seanceExercice.exercice.description}</p>
            </Col>
            {((props.seance.status === SeanceStatus.ONGOING) && (props.seanceExercice.exercice.uses_document)) ?
                <functions.ExerciseDocument seance={props.seance}
                    seanceExercice={props.seanceExercice} />
                :
                null}
        </Row></Container>
    );
};



export default SeanceExerciceDetail;
