import React, { useEffect, ReactElement } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { globalStore, NotificationType, UserNotification } from '../../Redux/interfaces';
import { removeNotification } from '../../Redux/actions';
import './Notification.css';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green, red, orange } from '@material-ui/core/colors';

interface NotificationProps {
    notification: UserNotification;
    close: () => void;
}

const Notification = ({ notification, close }: NotificationProps) => {
    const { type, message } = notification;
    const DISMISS_DURATION = 5000;

    const getTypeDetails = (type: string): { title: string; icon: ReactElement } => {
        switch (type) {
            case NotificationType.ERROR:
                return {
                    title: 'Erreur',
                    icon: <ErrorIcon style={{ color: red[800] }} fontSize="small" />,
                };
            case NotificationType.WARNING:
                return {
                    title: 'Attention',
                    icon: <WarningIcon style={{ color: orange[700] }} fontSize="small" />,
                };
            default:
                return {
                    title: 'Notifications',
                    icon: <CheckCircleIcon style={{ color: green[500] }} fontSize="small" />,
                };
        }
    };

    const typeDetails = getTypeDetails(type);

    useEffect(() => {
        if (!notification.isPersistent) {
            const timeout = setTimeout(close, DISMISS_DURATION);
            return () => {
                clearInterval(timeout);
            };
        }
    });

    return (
        <Toast onClose={close}>
            <Toast.Header>
                {typeDetails.icon}
                <strong className="mr-auto">{typeDetails.title}</strong>
                <small>À l'instant</small>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
};

export const Notifications = () => {
    const notifications = useSelector((state: globalStore) => state.notification);
    const dispatch = useDispatch();

    const notificationToasts = notifications.map((notification, index) => {
        return (
            <Notification notification={notification} key={index} close={() => dispatch(removeNotification(index))} />
        );
    });

    return <div className="NotificationArea">{notificationToasts}</div>;
};
