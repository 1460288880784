import React from 'react';
import { DayliTimelineProps } from './interface';

export const DailyTimeline = (props: DayliTimelineProps) => {
    const { moment, xPosition, yPosition = 0, headerHeight, dayLength } = props;
    const hoursNb = 24;

    const day = moment.format('dddd MMMM Do YYYY');
    const hours = Array.from(Array(hoursNb).keys());
    const fontSize = headerHeight / 5;
    const hourLength = dayLength / hoursNb;

    return (
        <g transform={`translate(${xPosition}, ${yPosition})`}>
            <rect x="0" y="0" width={dayLength} height="100%" fill="white" />
            <line x1="0" y1="0" x2="0" y2={`${0.65 * headerHeight}`} strokeWidth=".3" stroke="black" />
            <line x1="0" y1={headerHeight} x2="0" y2="98%" strokeWidth=".3" stroke="black" />
            <text x={dayLength / 2} y={`${0.4 * headerHeight}`} fontSize={fontSize}>
                {day}
            </text>
            {hours.map((index) => {
                const x = index * hourLength;
                const hour = (index * 24) / hoursNb;

                return (
                    <React.Fragment key={index}>
                        <text x={x} y={`${0.9 * headerHeight}`} fontSize={fontSize}>
                            {hour}h
                        </text>
                        <line x1={x} y1={headerHeight} x2={x} y2="100%" strokeWidth=".1" stroke="black" />
                        <line
                            x1={x + hourLength / 2}
                            y1={headerHeight}
                            x2={x + hourLength / 2}
                            y2="100%"
                            strokeWidth=".05"
                            stroke="black"
                        />
                    </React.Fragment>
                );
            })}
        </g>
    );
};
