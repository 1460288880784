import React from 'react';
import { Column } from 'material-table';
import { EvaluationInterface, Evaluation } from '../../../../Models/Evaluation';
import EvaluateButton from '../EvaluateButton';
import { Seance } from '../../../../Models/Seance';
import { Authentification } from '../../../../Redux/interfaces';
import { UserRole } from '../../../../Models/User';
import CustomDropDown from '../../../CustomDropDown/CustomDropDown';
import { SeanceObservateur } from '../../../../Models/SeanceObservateur';
import { SeanceParticipant } from '../../../../Models/SeanceParticipant';

const canEvaluate = (
    evaluation: EvaluationInterface,
    currentUser: Authentification,
    isParticipating: boolean,
    seance: Seance
) => {
    const isObservateur = currentUser.current_role === UserRole.OBSERVATEUR;
    if (isObservateur && isParticipating) {
        const seanceObservateur = seance.getSeanceObservateurfromObservateurId(currentUser.id);
        if (evaluation.assessor && seanceObservateur && evaluation.assessor.equals(seanceObservateur)) return true;
    }
    return false;
};

const canConsultAsObsPrincipal = (
    evaluation: EvaluationInterface,
    currentUser: Authentification,
    isParticipating: boolean,
    seanceParticipant: SeanceParticipant
) =>
    currentUser.current_role === UserRole.OBSERVATEUR &&
    isParticipating &&
    !evaluation.editable &&
    currentUser.id === seanceParticipant.observateur_principal?.observateur.id;

const getUpdateEval = (obs: SeanceObservateur, isCascadeur: boolean = false, evaluation: EvaluationInterface) => {
    const evalObject = new Evaluation(evaluation);
    return isCascadeur ? evalObject.setAssistant(obs) : evalObject.setAssessor(obs);
};

export const getEvaluationColumns = (
    seance: Seance,
    currentUser: Authentification,
    isParticipating: boolean,
    isEditable: boolean,
    seanceParticipant: SeanceParticipant,
    evaluate: (evaluation: EvaluationInterface, seancePar: SeanceParticipant) => void,
    updateEvaluation: (evaluation: Evaluation) => void
): Column<EvaluationInterface>[] => [
    { field: 'seance_exercice.exercice.name', title: 'Exercice' },
    {
        field: 'assessor',
        title: 'Observateur',
        render: (evaluation) => (
            <CustomDropDown
                id={`exercice_plan_obs_${evaluation.id}`}
                show={!evaluation.seance_exercice.exercice.is_autonomous}
                title={evaluation.assessor ? evaluation.assessor.toString() : 'Choisir'}
                editable={isEditable}
                options={seance.seance_observateurs}
                onSelect={(obs) => updateEvaluation(getUpdateEval(obs, false, evaluation))}
            />
        ),
    },
    {
        field: 'assistant',
        title: 'Cascadeur',
        render: (evaluation) => (
            <CustomDropDown
                id={`exercice_plan_ass_${evaluation.id}`}
                title={evaluation.assistant ? evaluation.assistant.toString() : 'Choisir'}
                show={evaluation.seance_exercice.exercice.uses_assistant}
                editable={isEditable}
                options={seance.seance_observateurs}
                onSelect={(obs) => updateEvaluation(getUpdateEval(obs, true, evaluation))}
            />
        ),
    },
    {
        field: 'evaluation',
        title: 'Évaluation',
        hidden: !isParticipating,
        render: (evaluation) => (
            <EvaluateButton
                show={
                    canEvaluate(evaluation, currentUser, isParticipating, seance) ||
                    canConsultAsObsPrincipal(evaluation, currentUser, isParticipating, seanceParticipant)
                }
                seance={seance}
                seanceExercice={evaluation.seance_exercice}
                evaluation={evaluation}
                seanceParticipant={seanceParticipant}
                currentUserId={currentUser.id}
                evaluate={(evaluation: EvaluationInterface) => {
                    evaluate(evaluation, seanceParticipant);
                }}
            />
        ),
    },
];

export const filterAutonomous = (evals: Evaluation[], keepAutonomous: boolean = false) => {
    return evals.filter((value) => value.seance_exercice.exercice.is_autonomous === keepAutonomous);
};

export const remainingEvals = (
    sP: SeanceParticipant,
    currentUser: Authentification,
    isParticipating: boolean,
    seance: Seance
) => {
    return sP.evaluations.reduce(
        (acc, current) =>
            acc + (current.editable && canEvaluate(current, currentUser, isParticipating, seance) ? 1 : 0),
        0
    );
};
