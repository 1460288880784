import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import { Header } from './Components/Header/Header';
import Welcome from './Components/Welcome/Welcome';
import Login from './Components/Login/Login';
import LogoHori from './Images/Logo_Hori.png';
import { Notifications } from './Components/Notification/Notification';
import { Role } from './Components/Role/Role';
import { Company } from './Components/Company/Company';
import { useDispatch } from 'react-redux';
import { useRepository } from './Repository/useRepository';
import { authGET } from './Repository/loginDesc';
import { ApiDescription, STATUS } from './Components/Fetch/interfaces';
import { getAuthFromStorage } from './Components/Login/functions';
import Spinner from 'react-bootstrap/Spinner';
import { MainBody } from './Components/MainBody/MainBody';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';
import { CREATE_OR_UPDATE } from './Redux/actionsTypes';
import DataConsent from './Components/DataConsent/DataConsent';

export const Loader = () => (
    <Spinner className="Loader" animation="border" variant="primary" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
);

const Routes = () => (
    <>
        <Route path="/" exact component={Welcome} />
        <Route path="/login" exact component={Login} />
        <Route path="/main" component={MainBody} />
        <Route path="/company" component={Company} />
        <Route path="/role" component={Role} />
    </>
);

const BaseLayout = () => {
    const cachedUser = getAuthFromStorage();
    const history = useHistory();
    const dispatch = useDispatch();
    const [status, user, , checkTokenValidity] = useRepository(authGET as ApiDescription, () => { }, {
        token: cachedUser?.token,
        notify: false,
    });

    // handles user previous connection
    useEffect(() => {
        switch (status) {
            case STATUS.INIT:
                // asks the server is the token is still valid
                checkTokenValidity();
                break;
            case STATUS.FAILED:
                return history.push('/');
            case STATUS.SUCCESS:
                dispatch({
                    type: CREATE_OR_UPDATE,
                    payload: {
                        ...user,
                        currentCompany: cachedUser?.currentCompany ?? user.currentCompany,
                        current_role: cachedUser?.current_role ?? user.current_role,
                    },
                });
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <div id="frame" className="container-fluid p-0 overflow-hidden">
            <Header brandName="ADAM" logo={LogoHori} />
            <div
                id="body"
                className="fullWidth fullHeight d-flex items-are-center-aligned content-is-center-justified overflow-hidden"
            >
                {status === STATUS.INIT || status === STATUS.PENDING ? <Loader /> : <Routes />}
            </div>
            <DataConsent />
            <Notifications />
        </div>
    );
};

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                <BaseLayout />
            </MuiPickersUtilsProvider>
        </BrowserRouter>
    );
};

export default App;
