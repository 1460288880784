import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { globalStore } from '../../../Redux/interfaces';
import { Table } from '../../Table/Table';
import { deleteUserDesc, updateUserDesc } from '../../../Repository/genericUserOneDesc';
import { getUsersDesc } from '../../../Repository/userAllDesc';
import { STATUS } from '../../Fetch/interfaces';
import { asyncFetch } from '../../Fetch/useFetch';
import { User, UserUpdate } from '../../../Models/User';
import { generateOrderBy } from '../../Table/functions';
import { useRepository } from '../../../Repository/useRepository';
import { companyListDesc } from '../../../Repository/companyDesc';
import { transformUserToAuth } from '../UserProfile/functions';
import { CREATE_OR_UPDATE, LOG_OUT } from '../../../Redux/actionsTypes';
import { persistAuthData } from '../../Login/functions';
import { Loader } from '../../../App';
import { Row } from './interfaces';
import { columns, getUserRoleNumber } from './constants';
import './UserSearch.css';

export const UserSearch = () => {
    const auth = useSelector((state: globalStore) => state.authentification);
    const dispatch = useDispatch();
    const defaultParams = {
        Authorization: `Token ${auth.token}`,
        'X-Company': auth.currentCompany.id,
    };
    const [fetchCompaniesStatus, allCompanies, , fetchCompanies] = useRepository(companyListDesc);
    const [, , , updateUser] = useRepository(
        updateUserDesc,
        (data) => {
            if (data.id === auth.id) updateAuth(data); // If logged-in user is updated, update store
        },
        { notify: true, notifyOnSuccess: true }
    );
    const [, , , deleteUser] = useRepository(
        deleteUserDesc,
        (data) => {
            if (data.id === auth.id) logOut(); // If logged-in user is deleted, log out
        },
        { notify: true, notifyOnSuccess: true }
    );

    useEffect(() => {
        if (auth.token) fetchCompanies({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const updateRow = (newData: Row) => {
        return new Promise((resolve) => {
            const params = {
                ...defaultParams,
                id: newData.id,
                body: convertRowToUser(newData),
            };
            updateUser(params);
            resolve();
        });
    };

    const deleteRow = (oldData: Row) => {
        return new Promise((resolve) => {
            const params = {
                ...defaultParams,
                id: oldData.id,
            };
            deleteUser(params);
            resolve();
        });
    };

    const convertUsersToRows = (data: User[]): Row[] => {
        return data.map((user) => {
            return {
                ...user,
                role: getUserRoleNumber(user),
                userCompanies:
                    auth.is_superuser && fetchCompaniesStatus === STATUS.SUCCESS ? allCompanies : auth.companies,
            };
        });
    };

    const convertRowToUser = (row: Row): UserUpdate => {
        return {
            ...row,
            companies: row.companies.map((c) => c.id),
        };
    };

    const rowIsEditable = (row: Row): boolean => {
        const currentUserRole = getUserRoleNumber(auth);
        return (
            row.id === auth.id || // Row is current user
            currentUserRole === 1 || // Current user is super admin
            (currentUserRole > 0 && currentUserRole < row.role)
        ); // Current user has greater rights that the user in the row
    };

    const updateAuth = (data: UserUpdate) => {
        const transformedUser = transformUserToAuth(data, auth, allCompanies);
        dispatch({
            type: CREATE_OR_UPDATE,
            payload: transformedUser,
        });
        persistAuthData(transformedUser);
    };

    const logOut = () => {
        dispatch({
            type: LOG_OUT,
        });
    };

    const fetchData = (query: any) =>
        new Promise((resolve) => {
            const params = {
                page_size: query.pageSize,
                page: query.page + 1,
                search: query.search,
                ordering: generateOrderBy(query.orderBy, query.orderDirection),
                ...defaultParams,
            };
            asyncFetch(getUsersDesc, params).then((res) => {
                const { status, data: result } = res;
                if (status === STATUS.SUCCESS) {
                    const { page, results, count: totalCount } = result as any;
                    const data = convertUsersToRows(results);
                    resolve({ data, page: page - 1, totalCount });
                } else resolve({ data: [], page: 0, totalCount: 0 });
            });
        });

    if (fetchCompaniesStatus === STATUS.PENDING || fetchCompaniesStatus === STATUS.INIT) return <Loader />;
    return (
        <div className="UserSearch">
            <Table<Row>
                columns={columns}
                title="Utilisateurs"
                onRowUpdate={updateRow}
                onRowDelete={deleteRow}
                fetchData={fetchData}
                isEditable={rowIsEditable}
                isDeletable={rowIsEditable}
            />
        </div>
    );
};
