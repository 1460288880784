import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { globalStore } from '../../Redux/interfaces';
import UserProfile from '../UserManagement/UserProfile/UserProfile';
import { UserSearch } from '../UserManagement/UserSearch/UserSearch';
import ReferentialUpload from '../Referential/Upload/ReferentialUpload';
import { SeanceSearch } from '../Seance/SeanceSearch/SeanceSearch';
import ExerciceList from '../Exercice/ExerciceList/ExerciceList';
import { UserRole } from '../../Models/User';
import SeanceView from '../Seance/SeanceView/SeanceView';
import { ExerciceEvaluation } from '../ExerciceEvaluation/ExerciceEvaluation';
import { PageSkeleton } from '../PageSkeleton/PageSkeleton';

export const MainBody = () => {
    const user = useSelector((state: globalStore) => state.authentification);

    const UserWelcomeWrapper = () => {
        switch (user.current_role) {
            case UserRole.ADMIN:
                return <UserSearch />;
            case UserRole.OBSERVATEUR:
                return <SeanceSearch />;
            case UserRole.PARTICIPANT:
                return <SeanceSearch />;
            default:
                return null;
        }
    };

    return (
        <Switch>
            <Route exact path={'/main'}>
                <PageSkeleton>
                    <UserWelcomeWrapper />
                </PageSkeleton>
            </Route>
            <Route path={'/main/profile'}>
                <PageSkeleton>
                    <UserProfile isNew={false} />
                </PageSkeleton>
            </Route>
            <Route path={'/main/user/new'}>
                <PageSkeleton>
                    <UserProfile isNew={true} />
                </PageSkeleton>
            </Route>
            <Route path={'/main/user'}>
                <PageSkeleton>
                    <UserSearch />
                </PageSkeleton>
            </Route>
            <Route path={'/main/referential/upload'}>
                <PageSkeleton>
                    <ReferentialUpload />
                </PageSkeleton>
            </Route>
            <Route path={'/main/seance/new'}>
                <SeanceView participate={false} isNew={true} />
            </Route>
            <Route path={'/main/seance/:seanceId/participate'}>
                <SeanceView participate={true} isNew={false} />
            </Route>
            <Route path={'/main/evaluation/:evaluationId/evaluate'}>
                <ExerciceEvaluation />
            </Route>
            <Route path={'/main/seance/:seanceId'}>
                <SeanceView participate={false} isNew={false} />
            </Route>
            <Route path={'/main/seance'}>
                <PageSkeleton>
                    <SeanceSearch />
                </PageSkeleton>
            </Route>
            <Route path={'/main/exercice'}>
                <PageSkeleton>
                    <ExerciceList />
                </PageSkeleton>
            </Route>
        </Switch>
    );
};
