import { FormType } from './type';
import { FormDescription, FormDictionary, InputDescription, StringObject } from './interfaces';

export const validate = (value: any, description: InputDescription | undefined, dict: FormDictionary) => {
    if (description) {
        const lengthMin = description.lengthMin ? description.lengthMin : 0;

        if (description.required && !value) return dict.EMPTY_FIELD;

        switch (description.validator) {
            case FormType.PASSWORD:
                if (lengthMin > value.length) return dict.TOO_SHORT_PASSWORD;
                break;

            case FormType.EMAIL:
                if (!/\S+@\S+\.\S+/.test(value)) return dict.INVALID_EMAIL;
                break;

            case FormType.ANY:
                break;
        }
    }
    return dict.VALID;
};

export const validateAll = (values: any, description: FormDescription, dict: FormDictionary) => {
    return Object.keys(values).reduce((errors, name) => {
        const value = validate(values[name], description[name], dict);
        if (value) errors[name] = value;
        return errors;
    }, {} as StringObject);
};
