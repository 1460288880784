import { SeanceObservateur } from './SeanceObservateur';
import { SeanceExercice } from './SeanceExercice';
import { SeanceParticipant } from './SeanceParticipant';
import moment, { Moment } from 'moment';
import { Participant } from './Participant';

export interface EvaluationInterface {
    id: number;
    assessor?: SeanceObservateur;
    assistant?: SeanceObservateur;
    seance_exercice: SeanceExercice;
    editable: boolean;
    start_time: Moment;
    end_time: Moment;
    comments: string;
}

export interface EvaluationExtended extends EvaluationInterface {
    seance_participant: SeanceParticipant;
}

export interface EvaluationWithParticipant extends EvaluationInterface {
    participant: Participant;
}

export class Evaluation implements EvaluationInterface {
    id: number;
    assessor?: SeanceObservateur;
    assistant?: SeanceObservateur;
    seance_exercice: SeanceExercice;
    editable: boolean;
    start_time: Moment;
    end_time: Moment;
    comments: string;

    constructor(evaluation: EvaluationInterface) {
        this.id = evaluation.id;
        this.assessor = evaluation.assessor;
        this.assistant = evaluation.assistant;
        this.seance_exercice = evaluation.seance_exercice;
        this.editable = evaluation.editable;
        this.start_time = evaluation.start_time;
        this.end_time = evaluation.end_time;
        this.comments = evaluation.comments;
    }

    static fromJSON(data: EvaluationInterface): Evaluation {
        return new this({
            ...data,
            seance_exercice: SeanceExercice.fromJSON(data.seance_exercice),
            assessor: data.assessor ? SeanceObservateur.fromJSON(data.assessor) : undefined,
            assistant: data.assistant ? SeanceObservateur.fromJSON(data.assistant) : undefined,
            start_time: moment(data.start_time),
            end_time: moment(data.end_time),
        });
    }

    setAssessor(assessor: SeanceObservateur) {
        const evaluation = new Evaluation(this);
        evaluation.assessor = assessor;
        return evaluation;
    }

    setAssistant(assistant: SeanceObservateur) {
        const evaluation = new Evaluation(this);
        evaluation.assistant = assistant;
        return evaluation;
    }

    setStartTime(start_time: Moment) {
        const evaluation = new Evaluation(this);
        evaluation.start_time = start_time;
        return evaluation;
    }

    setEndTime(end_time: Moment) {
        const evaluation = new Evaluation(this);
        evaluation.end_time = end_time;
        return evaluation;
    }

    setTimes(start_time: Moment, end_time: Moment) {
        const evaluation = new Evaluation(this);
        evaluation.start_time = start_time;
        evaluation.end_time = end_time;
        return evaluation;
    }

    equals(evaluation: EvaluationInterface) {
        return this.id === evaluation.id && this.seance_exercice === evaluation.seance_exercice;
    }

    toCreate() {
        return {
            seance_exercice: this.seance_exercice.toCreate(),
            assessor: this.assessor?.toCreate(),
            assistant: this.assistant?.toCreate(),
            start_time: this.start_time.toISOString(),
            end_time: this.end_time.toISOString(),
            comments: this.comments,
        };
    }
}
