import React, { useState } from 'react';
import { ExtendedEvaluationInterface, SeancePlanningProps } from './interface';
import { Timeline } from '../../Timeline/Timeline';
import { timelineConfig } from './constants';
import { getColors, getEvents, getExercicesById, getOriginTime, getPlanningDayLength, getResources } from './functions';
import { EvaluationBox } from './EvaluationBox';
import './SeancePlanning.css';
import '../../Timeline/Timeline.css';

export const SeancePlanning = ({ seance, editable = false, updateEval }: SeancePlanningProps) => {
    const dayLengthMin = 150;
    const exerciceColors = getColors(seance.seance_exercices);
    const resources = getResources(seance);
    const { events, minTime, maxTime } = getEvents(seance, exerciceColors);
    const originTime = getOriginTime(minTime);
    const dayLength = Math.max(getPlanningDayLength(minTime, maxTime), dayLengthMin);
    const [evaluation, setEvaluation] = useState<ExtendedEvaluationInterface | null>();

    const onEventClick = (id: number | string) => setEvaluation(getExercicesById(seance.seance_participants, id));

    return (
        <>
            <Timeline
                {...timelineConfig}
                resources={resources}
                events={events}
                dayLength={dayLength}
                dayLengthMin={dayLengthMin}
                originTime={originTime}
                onEventClick={onEventClick}
            />
            {evaluation && (
                <EvaluationBox
                    editable={editable}
                    observateurs={seance.seance_observateurs}
                    evaluation={evaluation}
                    closeBox={() => setEvaluation(null)}
                    updateEval={(evaluation) => updateEval(evaluation)}
                />
            )}
        </>
    );
};
