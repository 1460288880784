import { Seance } from '../../../Models/Seance';

export type SeanceViewProps = SeanceViewOwnProps;

/* Start SeanceView */

export interface SeanceViewOwnProps {
    isNew: boolean;
    participate: boolean;
}

export interface SeanceState {
    seance: Seance;
}

export interface SeanceViewState {
    viewState: string;
}

export enum VState {
    CHOICE = 'choice',
    PLANNING_PAR = 'planningParticipant',
    PLANNING_EXO = 'planningExercice',
    PLANNNING_SUMMARY = 'planningSummary',
    VIEWING = 'viewing',
    PARTICIPATE = 'participating',
}

export const ViewState = {
    CHOICE: 'choice',
    PLANNING_PAR: 'planningParticipant',
    PLANNING_EXO: 'planningExercice',
    PLANNING_SUMMARY: 'planningSummary',
    VIEWING: 'viewing',
    PARTICIPATE: 'participating',
};

export const SeanceCreationNavigation: VState[] = [
    VState.CHOICE,
    VState.PLANNING_PAR,
    VState.PLANNING_EXO,
    VState.PLANNNING_SUMMARY,
];

/* End SeanceView */

/* Start UserExerciceSelector */

/* End UserExerciceSelector */
