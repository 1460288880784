import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

import { globalStore } from '../../../Redux/interfaces';
import { CREATE_OR_UPDATE } from '../../../Redux/actionsTypes';
import Form from '../../Form/Form';
import { FORM_DICT } from '../../Form/constant';
import { persistAuthData } from '../../Login/functions';
import { useRepository } from '../../../Repository/useRepository';
import { createUserDesc } from '../../../Repository/userOneDesc';
import { companyListDesc } from '../../../Repository/companyDesc';
import { updateUserDesc } from '../../../Repository/genericUserOneDesc';
import * as functions from './functions';
import * as interfaces from './interfaces';
import './UserProfile.css';
import { STATUS } from '../../Fetch/interfaces';

const UserProfile = (props: interfaces.Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: globalStore) => state.authentification);
    const [userProfileState, setUserProfileState] = useState(functions.initUserProfileState(user));
    const [fetchStatus, allCompanies, , fetchCompanies] = useRepository(companyListDesc);
    const [, , , createUser] = useRepository(createUserDesc, () => handleReinit?.(), {
        notify: true,
        notifyOnSuccess: true,
    });
    const [, , , updateUser] = useRepository(
        updateUserDesc,
        (data: any) => {
            // Update the redux authentification object on update success
            if (data !== {}) {
                const transformedUser = functions.transformUserToAuth(data, user, companies);
                dispatch({
                    type: CREATE_OR_UPDATE,
                    payload: transformedUser,
                });
                persistAuthData(transformedUser);
                // handleReinit && handleReinit();
            }
        },
        { notify: true, notifyOnSuccess: true }
    );

    useEffect(() => {
        if (user.token) fetchCompanies({});
    }, [user]);

    useEffect(() => {
        setUserProfileState(functions.initUserProfileState(user));
    }, [props.isNew, user]);

    useEffect(() => {
        handleReinit && handleReinit();
    }, [userProfileState.user]);

    let handleReinit: undefined | Function = undefined;
    useEffect(() => {
        // Reinit form values on url change
        handleReinit && handleReinit();
    }, [history.location]);

    const toggleShowPassword = () => {
        setUserProfileState({
            ...userProfileState,
            showPassword: !userProfileState.showPassword,
        });
    };

    const handleSubmitUser = (values: any) => {
        let isValid = true;
        if (values.password && values.passwordConfirmation) {
            isValid = values.password === values.passwordConfirmation;
            if (!isValid) {
                dispatch(functions.notifications.mismatchedPasswords);
                return;
            }
        }
        values.companies = values.companies ?? user.companies.map((c) => c.id);

        if (props.isNew) {
            createUser({
                userType: values.user_type,
                body: values,
            });
        } else {
            updateUser({
                id: user.id,
                body: values,
            });
        }
    };

    const companies = user.is_superuser && fetchStatus === STATUS.SUCCESS ? allCompanies : user.companies;

    return (
        <Container>
            <div className="ProfileContainer">
                <h3>{props.isNew ? "Création d'un nouvel utilisateur" : 'Modification du Profil'}</h3>
                <Form
                    className="form-area"
                    formContainerClassName="form-container"
                    buttonsContainerClassName="buttons-container"
                    description={functions.profileFormDescription(
                        props.isNew,
                        userProfileState.showPassword,
                        userProfileState.userTypes,
                        props.isNew || user.is_superuser,
                        companies
                    )}
                    initialValues={functions.initialValuesProfile(
                        props.isNew,
                        userProfileState.showPassword,
                        userProfileState.user,
                        props.isNew || user.is_superuser
                    )}
                    dict={FORM_DICT}
                    submitLabel={'Valider'}
                    submit={handleSubmitUser}
                    setHandleReinit={(formHandleReset) => {
                        handleReinit = formHandleReset;
                    }}
                />
                <Button onClick={toggleShowPassword} variant="outline-primary">
                    {!userProfileState.showPassword ? 'Modifier mot de passe' : 'Masquer mot de passe'}
                </Button>
            </div>
            {!props.isNew && (
                <div className="account-management">
                    <h3>Suppression du compte</h3>
                    <div>
                        Il vous est possible de supprimer l'ensemble de vos données personnelles en contactant
                        l'administrateur de ce site.
                    </div>
                </div>
            )}
        </Container>
    );
};

export default UserProfile;
