import { ApiDescription, DATA_TYPE, HTTP_METHODS, PARAM_LOCATION } from '../Components/Fetch/interfaces';

export const uploadDesc = {
    url: '/api/import/',
    method: HTTP_METHODS.POST,
    parameters: {
        override: {
            in: PARAM_LOCATION.QUERY,
            type: 'boolean',
            required: false,
        },
        Authorization: {
            in: PARAM_LOCATION.HEADER,
            type: 'string',
            required: true,
        },
        'X-Company': {
            in: PARAM_LOCATION.HEADER,
            type: 'number',
            required: true,
        },
    },
    requestBody: {
        required: true,
        content: {
            'multipart/form-data': {},
        },
    },
    responses: {
        204: {
            content: DATA_TYPE.JSON,
            success: true,
            message: 'Les fichiers ont été chargés avec succès',
        },
        400: {
            content: DATA_TYPE.TEXT,
            success: false,
            message: 'Erreur lors du chargement',
        },
        401: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non autorisé',
        },
        403: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Accès non authorisé',
        },
        404: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Ressource non trouvée',
        },
        422: {
            content: DATA_TYPE.JSON,
            success: false,
            message: 'Document(s) malformé(s)',
        },
        415: {
            content: DATA_TYPE.JSON,
            success: false,
            message: "Ce type de fichier n'est pas accepté",
        },
    },
} as ApiDescription;
