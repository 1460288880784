import React from 'react';
import { globalStore } from '../../Redux/interfaces';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { menuOptions } from './constants';
import { SidebarItemProps } from './interfaces';
import { UserRole } from '../../Models/User';

import './SideBar.css';

const SideBarItemComponent = ({ item, parent, keyValue, onClick }: SidebarItemProps) => {
    const { label, target, icon } = item;
    const history = useHistory();

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClick(() => {
            return () => {
                if (target) history.push(target);
            };
        });
    };

    const children = item.children?.map((item, index) => {
        const key = `${keyValue}_${index}`;
        return <SideBarItemComponent key={key} keyValue={key} item={item} onClick={onClick} />;
    });

    return (
        <div className={`sidebar_item${parent ? ' parent' : ' child'}`} onClick={handleClick}>
            {children && <div className={'sidebar_item children_container'}>{children}</div>}
            {icon && icon()}
            <div className="label">{label}</div>
        </div>
    );
};

interface SideBarProps {
    onClick: (callback: () => any) => any;
}

const SideBar = (props: SideBarProps) => {
    const userRole = useSelector((state: globalStore) => state.authentification.current_role) || UserRole.PARTICIPANT;

    const items = menuOptions[userRole].map((item, index) => (
        <SideBarItemComponent item={item} parent key={index} keyValue={index} onClick={props.onClick} />
    ));

    return <div className="sidebar">{items}</div>;
};

export default SideBar;
