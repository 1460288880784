import React, { useRef, useState } from 'react';
import './DraggableElement.css';
import { getMousePosition, getSvgCoords, preventDefault, setSVGCoords } from './functions';
import { DraggableComponentProps, MovementEnabled } from './interface';

const originCoords = {
    x: 0,
    y: 0,
};

export function withDraggable<T>(
    Component: React.FunctionComponent<T & DraggableComponentProps>,
    scrollEnabled?: MovementEnabled
) {
    return (injectedProps: T) => {
        const { horizontalDragEnabled = true, verticalDragEnabled = true } = scrollEnabled ?? {};
        // const { horizontalScrollEnabled = true, verticalScrollEnabled = true, step = 10 } = scrollEnabled ?? {};
        const { step = 10 } = scrollEnabled ?? {};

        const [isBeingDragged, setIsBeingDragged] = useState(false);
        const [originalTranslate, setOriginalTranslate] = useState(originCoords);
        const [currentTranslate, setCurrentTranslate] = useState(originCoords);
        const [mouseOffset, setMouseOffset] = useState(originCoords);
        const draggableGroup = useRef<SVGSVGElement>(null);

        const onKeyDown = (evt: React.KeyboardEvent<SVGElement>) => {
            if (evt.shiftKey) window.addEventListener('wheel', preventDefault, { passive: false });
        };

        const onKeyUp = (evt: React.KeyboardEvent<SVGElement>) => {
            if (evt.shiftKey) onMouseLeave();
        };

        const onMouseLeave = () => {
            window.removeEventListener('wheel', preventDefault);
            stopDrag();
        };

        const setPosition = (x: number, y: number) => {
            if (draggableGroup.current) {
                setSVGCoords(draggableGroup.current, x, y);
                setCurrentTranslate({ x, y });
            }
        };

        const translate = (dx: number, dy: number) => {
            if (draggableGroup.current) {
                const { x, y } = getSvgCoords(draggableGroup.current);
                setPosition(x + dx, y + dy);
            }
        };

        const resetPosition = () => {
            setIsBeingDragged(false);
            if (draggableGroup.current) {
                setSVGCoords(draggableGroup.current, originCoords.x, originCoords.y);
                setCurrentTranslate(originCoords);
            }
        };

        const onMouseDown = (evt: React.MouseEvent<SVGElement>) => {
            if (draggableGroup.current) {
                setIsBeingDragged(true);
                setOriginalTranslate(getSvgCoords(draggableGroup.current));
                setMouseOffset(getMousePosition(evt, draggableGroup.current));
            }
        };

        const onMouseMove = (evt: React.MouseEvent<SVGElement>) => {
            if (isBeingDragged && draggableGroup.current) {
                const { x: mouseX, y: mouseY } = getMousePosition(evt, draggableGroup.current as SVGGraphicsElement);
                const newTranslate = { ...originalTranslate };
                if (horizontalDragEnabled) newTranslate.x += mouseX - mouseOffset.x;
                if (verticalDragEnabled) newTranslate.y += mouseY - mouseOffset.y;

                setPosition(newTranslate.x, newTranslate.y);
            }
        };

        const onWheel = (evt: React.WheelEvent) => {
            if (!evt.shiftKey) return;

            evt.preventDefault();
            const deltaY = evt.deltaY;

            if (deltaY && draggableGroup.current) {
                translate(deltaY < 0 ? step : -step, 0);
            }
        };

        const stopDrag = () => {
            setIsBeingDragged(false);
        };

        const mouseFunction = {
            onMouseDown,
            onMouseMove,
            onWheel,
            onMouseUp: stopDrag,
            onMouseLeave,
            onKeyDown,
            onKeyUp,
        };

        return (
            <g ref={draggableGroup} className="Draggable" transform="translate(0, 0)" {...mouseFunction}>
                <rect x="0%" y="0%" width="100%" height="100%" fill="none" />
                <Component translate={currentTranslate} resetPosition={resetPosition} {...injectedProps} />
            </g>
        );
    };
}
