import { Action, UserType, Authentification, NotificationType, UserNotification } from './interfaces';
import {
    CREATE_OR_UPDATE,
    LOG_OUT,
    PUSH_NOTIFICATION,
    REMOVE_NOTIFICATION,
    SET_ROLE,
    SET_TYPE,
    SET_CURRENT_EVALUATION,
    SET_COMPANY,
    REMOVE_ALL_NOTIFICATIONS,
} from './actionsTypes';
import { persistAuthData } from '../Components/Login/functions';

const reducers = {
    authentificationReducer: (state = initialStates.authentificationInitialState, action: Action) => {
        switch (action.type) {
            case LOG_OUT:
                state = {
                    ...initialStates.authentificationInitialState,
                };
                break;
            case SET_TYPE:
                state = {
                    ...state,
                    user_type: action.payload,
                };
                break;
            case SET_ROLE:
                state = {
                    ...state,
                    current_role: action.payload,
                };
                break;
            case SET_COMPANY:
                state = {
                    ...state,
                    currentCompany: action.payload,
                };
                break;
            case CREATE_OR_UPDATE:
                state = {
                    ...state,
                    ...action.payload,
                    logged_in: true,
                };
                persistAuthData(state);
                break;
        }
        return state;
    },
    notificationReducer: (state: UserNotification[] = [], action: Action) => {
        switch (action.type) {
            case PUSH_NOTIFICATION:
                state = [...state, action.payload];
                break;
            case REMOVE_NOTIFICATION:
                state.splice(action.payload.index, 1);
                state = [...state];
                break;
            case REMOVE_ALL_NOTIFICATIONS:
                state = [];
                break;
        }

        return state;
    },
    currentEvaluationReducer: (state = null, action: Action) => {
        switch (action.type) {
            case SET_CURRENT_EVALUATION:
                state = action.payload;
                break;
        }
        return state;
    },
};

const initialStates = {
    authentificationInitialState: {
        logged_in: false,
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
        currentCompany: {
            id: 0,
            name: '',
        },
        companies: [],
        is_participant: false,
        is_observateur: false,
        is_admin: false,
        is_superuser: false,
        token: '',
        last_login: null,
        user_type: UserType.NONE,
    } as Authentification,
    userNotificationInitialState: {
        isActive: false,
        type: NotificationType.ERROR,
        message: '',
    } as UserNotification,
};

export default reducers;
