import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { exerciceDocumentDesc } from '../../../../Repository/exerciceDocumentDesc';
import { useRepository } from '../../../../Repository/useRepository';
import { STATUS } from '../../../Fetch/interfaces';
import * as interfaces from './interfaces';
import { Loader } from '../../../../App';

export const ExerciseDocument = (props: interfaces.DocumentProps) => {
    const [fetchStatus, fetchData, , fetchDocument] = useRepository(exerciceDocumentDesc);
    useEffect(() => {
        fetchDocument({ exerciceId: props.seanceExercice.exercice.id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Col>
            <p>
                <strong>Document d'analyse : </strong>
            </p>
            {fetchStatus === STATUS.PENDING && <Loader />}
            {fetchStatus === STATUS.SUCCESS && fetchData !== {} ? (
                <a href={fetchData.file} download={fetchData.fileName}>
                    {fetchData.fileName}
                </a>
            ) : null}
        </Col>
    );
};
