import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { globalStore } from '../../../Redux/interfaces';
import { STATUS } from "../../Fetch/interfaces";
import { useRepository } from '../../../Repository/useRepository';
import { exerciceListDesc } from '../../../Repository/exerciceListDesc';
import { patchExerciceDesc, deleteExerciceDesc } from '../../../Repository/exerciceDesc';
import { Exercice, ExerciceInterface } from '../../../Models/Exercice';
import { Table } from '../../Table/Table';
import * as functions from './functions';


const ExerciceList = () => {
    const user = useSelector((state: globalStore) => state.authentification);
    const [listState, setListState] = useState(functions.initListState());
    const [exercicesListStatus, exercicesData, , fetchExercices] = useRepository(exerciceListDesc);
    const [deleteStatus, , , deleteExercice] = useRepository(deleteExerciceDesc, undefined,
        { notify: true, notifyOnSuccess: true });
    const [patchStatus, , , patchExercice] = useRepository(patchExerciceDesc, undefined,
        { notify: true, notifyOnSuccess: true });

    const refreshExercises = () => {
        if (user.logged_in)
            fetchExercices({
                latest: true,
                page_size: 50, //Setting a high number as a hack : try to improve
            })
    }



    useEffect(() => { refreshExercises() },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    )

    useEffect(() => {
        if (deleteStatus === STATUS.FAILED) {
            setListState((previous) => ({
                ...previous,
                exercices: previous.oldExercices
            }))
        }
    }, [deleteStatus])

    useEffect(() => {
        if (patchStatus === STATUS.FAILED) {
            setListState((previous) => ({
                ...previous,
                exercices: previous.oldExercices
            }))
        }
    }, [patchStatus])

    useEffect(() => {
        if (exercicesListStatus === STATUS.SUCCESS && exercicesData !== {}) {
            setListState((previous) => ({
                ...previous,
                oldExercices: previous.exercices,
                exercices: functions.convertToExercices(exercicesData.results)
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercicesListStatus])

    const updateRow = (newData: ExerciceInterface) => {
        return new Promise((resolve) => {
            let data = [...listState.exercices];
            const index = data.findIndex((exo) => exo.id === newData.id);
            if (index !== -1) {
                const newExo = functions.convertToExercice(newData);
                data[index] = newExo;
                setListState((previous) => ({
                    ...previous,
                    oldExercices: previous.exercices,
                    exercices: data
                }))
                patchExercice({
                    id: newExo.id,
                    body: newExo.toUpdate(),
                })
            }
            resolve()
        });
    };

    const deleteRow = (oldData: Exercice) => {
        return new Promise((resolve) => {
            let data = [...listState.exercices];
            const index = data.indexOf(oldData);
            data.splice(index, 1);
            setListState((previous) => ({
                ...previous,
                oldExercices: previous.exercices,
                exercices: data
            }))
            deleteExercice({ id: oldData.id });
            resolve();
        });
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h3>Exercices</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table<Exercice>
                        columns={functions.getColumns(refreshExercises)}
                        title="Exercices"
                        onRowUpdate={updateRow}
                        onRowDelete={deleteRow}
                        data={listState.exercices}
                        options={{
                            pageSize: 10, pageSizeOptions: [5, 10, 20],
                            showTitle: false
                        }}
                    />
                </Col>
            </Row>
        </Container>

    );
};

export default ExerciceList;