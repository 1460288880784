import React, { useEffect } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useRepository } from '../../../Repository/useRepository';
import {
    uploadExerciceDocumentTemplateDesc,
    downloadExerciceDocumentTemplateDesc,
    deleteExerciceDocumentTemplateDesc,
} from '../../../Repository/exerciceDocumentDesc';
import { Backdrop, Tooltip, IconButton } from '@material-ui/core';
import { Loader } from '../../../App';
import { downloadFile } from '../../Seance/SeanceReport/functions';
import { STATUS } from '../../Fetch/interfaces';
import * as interfaces from './interfaces';
import * as functions from './functions';


const ExerciceDocumentButtons = (props: interfaces.ExerciceDetailProps) => {
    const [downloadStatus, downloadedData, , downloadTemplate] = useRepository(
        downloadExerciceDocumentTemplateDesc, () => {
            downloadFile(downloadedData, downloadStatus);
        }, { notify: true, notifyOnSuccess: true });
    const [uploadStatus, , , uploadTemplate] = useRepository(
        uploadExerciceDocumentTemplateDesc,
        undefined, { notify: true, notifyOnSuccess: true }
    );
    const [deleteStatus, , , deleteTemplate] = useRepository(
        deleteExerciceDocumentTemplateDesc,
        undefined, { notify: true, notifyOnSuccess: true }
    );

    useEffect(() => {
        if (uploadStatus === STATUS.SUCCESS) {
            props.refreshExercises();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadStatus])

    useEffect(() => {
        if (deleteStatus === STATUS.SUCCESS) {
            props.refreshExercises();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus])

    return (
        <div className="d-flex">
            <Tooltip title="Charger template">
                <IconButton aria-label="charger"
                    onClick={() => functions.selectFile(props.exercice, uploadTemplate)}>
                    <PublishIcon />
                </IconButton>
            </Tooltip>
            {props.exercice.uses_document ?
                <Tooltip title="Télécharger template">
                    <IconButton aria-label="télécharger"
                        onClick={() => {
                            downloadTemplate({
                                exerciceId: props.exercice.id
                            });
                        }}>
                        <GetAppIcon />
                    </IconButton>
                </Tooltip>
                : null
            }
            {props.exercice.uses_document ?
                <Tooltip title="Supprimer template">
                    <IconButton aria-label="supprimer"
                        onClick={() => {
                            deleteTemplate({
                                exerciceId: props.exercice.id
                            });
                        }}>
                        <DeleteForeverIcon color="secondary"/>
                    </IconButton>
                </Tooltip>
                : null
            }
            <Backdrop
                style={{ zIndex: 100, backgroundColor: 'fff' }}
                open={
                    downloadStatus === STATUS.PENDING ||
                    uploadStatus === STATUS.PENDING
                }
            >
                <Loader />
            </Backdrop>
        </div>
    );
};



export default ExerciceDocumentButtons;
