import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { DropdownAreaProps } from './interface';

export const DropdownArea = ({ className, disabled, items, selected, onItemSelected }: DropdownAreaProps) => {
    return (
        <DropdownButton disabled={disabled} id={selected} title={selected} variant="light" className={className}>
            {items.map(({ id, name }) => (
                <Dropdown.Item key={id} onSelect={() => onItemSelected(id)}>
                    {name}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};
